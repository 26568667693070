import { Link } from "react-router-dom";

export default function SectionFooter() {
  return (
    <div className="section-footer bg-black">
      <div className="container md:px-5 px-3 md:py-10 py-8 flex flex-col md:flex-row md:text-left text-center">
        <div className="footer-copy mb-4 md:mb-0">©Copyright Palenque TV</div>
        <div className="footer-social-media mb-4 md:mb-0">
          <ul className="flex">
            <li className="mr-3">
              <a href="#" title="Facebook">
                <img src={"/images/icons/icon-facebook.svg"} alt="facebook" />
              </a>
            </li>
            <li className="mr-3">
              <a href="#" title="Instagram">
                <img src={"/images/icons/icon-instagram.png"} alt="instagram" />
              </a>
            </li>
            <li>
              <a href="#" title="Tiktok">
                <img src={"/images/icons/icon-tiktok.png"} alt="tiktok" />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-links text-sm md:text-xs">
          <ul className="flex flex-col md:flex-row">
            <li>
              <Link
                to="/sobre-nosotros"
                className="uppercase md:mr-2 mr-0 hover:text-[#ff0000] duration-500"
              >
                Sobre Nosotros
              </Link>
              <span className="mr-2 md:inline hidden">|</span>
            </li><li>
              <Link
                to="/politica-privacidad"
                className="uppercase md:mr-2 mr-0 hover:text-[#ff0000] duration-500"
              >
                Política de Privacidad
              </Link>
              <span className="mr-2 md:inline hidden">|</span>
            </li>
            <li>
              <Link
                to="/terminos-condiciones"
                className="uppercase md:mr-2 mr-0 hover:text-[#ff0000] duration-500"
              >
                Términos y condiciones
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
