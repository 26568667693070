import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchCommentFailure, fetchCommentSuccess } from "./actions";
import { FETCH_COMMENT_REQUEST } from "./actionTypes";

import { FetchCommentRequest } from "./types";

import { getComment } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

/*
  Worker Saga: Fired on FETCH_COMMENT_REQUEST action
*/
export function* fetchCommentSaga(action: any): any {
  try {
    const response = yield call(getComment, action.query);

    yield put(
      fetchCommentSuccess({
        result: response,
      })
    );
  } catch (e) {
    let errorMessage = "Failed to do something exceptional";
    if (e instanceof Error) {
      errorMessage = e.message;
    }
    yield put(fetchCommentFailure({ error: errorMessage }));
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_COMMENT_REQUEST` action.
  Allows concurrent increments.
*/
function* CommentSaga() {
  yield all([takeLatest(FETCH_COMMENT_REQUEST, fetchCommentSaga)]);
}

export default CommentSaga;
