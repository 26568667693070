import {all, call, put, takeLatest, select} from 'redux-saga/effects';
import {FETCH_SOCIAL_NETWORK_REQUEST} from './actionTypes';
import {FetchSocialNetworkRequest} from './types';
import {fetchSocialNetworkFailure} from './actions';
import { getUserDetail } from '../userLogin/api';
import { createUser } from './api';
import { fetchUserLoginSaga } from '../userLogin/sagas';

export function* fetchSocialNetworkSaga(
  action: FetchSocialNetworkRequest,
): any {
  try {
    const {
      query: {uid, email},
    } = action;    

      let data = yield call(getUserDetail, uid);      

      if (Array.isArray(data) && data.length === 0) {
        yield call(createUser, action.query);
      }      
    
      yield call(fetchUserLoginSaga, {
        query: { uid: uid, email: email },
      });
  } catch (error) {    
    yield put(fetchSocialNetworkFailure({error: '' + error}));
  }
}

function* SocialNetworkSaga() {
  yield all([takeLatest(FETCH_SOCIAL_NETWORK_REQUEST, fetchSocialNetworkSaga)]);
}

export default SocialNetworkSaga;
