import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getResultUserLoginSelector } from "../_store/userLogin/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoadingUserEditSelector,
  getResultUserEditSelector,
  getErrorUserEditSelector,
} from "../_store/userEdit/selectors";
import { fetchUserEditRequest } from "../_store/userEdit/actions";

import SpinningLoading from "../components/Spinning/SpinningLoading";

import { toast } from "react-toastify";
import { isEmail, isPassword, isPhone } from "../util";

export function RegisterPage() {
  const dispatch = useDispatch();

  const isDev = process.env.NODE_ENV === "development";

  const [name, setName] = useState(isDev ? "sdfsdffsdf" : "");
  const [email, setEmail] = useState(isDev ? "test@test.com" : "");
  const [phone, setPhone] = useState(isDev ? "1231231221" : "");
  const [password, setPassword] = useState(isDev ? "123com123" : "");
  const [passwordReply, setPasswordReply] = useState(isDev ? "123com123" : "");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordReplyVisible, setPasswordReplyVisible] = useState(false);
  const [validate, setValidate] = useState(false);

  const navigate = useNavigate();
  const user = useSelector(getResultUserLoginSelector);
  const loading = useSelector(getLoadingUserEditSelector);
  const error = useSelector(getErrorUserEditSelector);
  const result = useSelector(getResultUserEditSelector);

  useEffect(() => {
    setValidate(false);
  }, []);

  useEffect(() => {
    if (user?.login && !validate) navigate("/");
  }, [user, validate, navigate]);

  useEffect(() => {
    if (!loading && validate && result && error == null) {
      toast.info("Bienvenido " + name);
      setValidate(false);
    }
  }, [result, validate, loading, error, name]);

  const send = (event) => {
    event.preventDefault();

    const isValidEmail = isEmail(email)
    const isValidPhoneNumber = isPhone(phone)
    const isValidPassword = isPassword(password)

    if (!isValidPassword) {
      toast.error("La contraseña debe tener al menos 6 caracteres");
      return;
    }
    if (email === "" || password === "" || passwordReply === "") {
      toast.error("Complete el formulario");
      return;
    } 
    if (password !== passwordReply) {
      toast.error("Las contraseñas deben ser iguales");
      return;
    } 
    if (!isValidEmail){
      toast.error("La dirección de correo electrónico es incorrecta");
      return;
    } 
    if (phone !== "" && !isValidPhoneNumber){
      toast.error("El número de teléfono es incorrecto");
      return;
    } 
    
    setValidate(true);
    dispatch(
      fetchUserEditRequest({
        type: "insert",
        email: email,
        name: name,
        password: password,
        phone: phone,
      })
    );
    
  };

  return (
    <div className="default-page container md:py-10 py-8 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl text-center">Registrarse</h1>
      <div className="container-login w-full max-w-md m-auto px-4 sm:px-6">
        {error && validate && <div className="mv-4">{error}</div>}

        {loading ? (
          <div className="h-15 m-5">
            <SpinningLoading />
          </div>
        ) : (
          <form className="pt-6 pb-8" onSubmit={send}>
            <div className="mb-4">
              <input
                type="text"
                name="name"
                className="bg-black rounded w-full py-2 px-3"
                placeholder="Nombre *"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required={true}
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                className="bg-black rounded w-full py-2 px-3"
                placeholder="Correo electrónico *"
                onChange={(e) => setEmail(e.target.value.replace(/\s/g, "").toLowerCase())}
                onKeyDown={(e) => {
                  if (e.code === 'Space') e.preventDefault()
                }}
                value={email}
                required={true}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="phone"
                className="bg-black rounded w-full py-2 px-3"
                placeholder="Teléfono"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </div>

            {/* <div className="mb-4">
              <input
                type="password"
                name="password"
                className="bg-black rounded w-full py-2 px-3"
                placeholder="Contraseña *"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required={true}
              />
            </div> */}

            <div className="mb-4">
              <div className="relative flex items-center text-white">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  className="w-full rounded bg-black py-2 px-3 text-white"
                  placeholder="Contraseña *"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required={true}
                />
                <button
                  type="button"
                  className="absolute right-0 h-full rounded px-2 hover:bg-neutral-700 flex items-center"
                  onClick={() => {
                    setPasswordVisible((prev) => !prev);
                  }}
                >
                  {!passwordVisible ? (
                    <ion-icon name="eye-off-outline"></ion-icon>
                  ) : (
                    <ion-icon name="eye-outline"></ion-icon>
                  )}
                </button>
              </div>
            </div>

            {/* <div className="mb-4">
              <input
                type="password"
                name="passwordReply"
                className="bg-black rounded w-full py-2 px-3"
                placeholder="Confirmar contraseña *"
                onChange={(e) => setPasswordReply(e.target.value)}
                value={passwordReply}
                required={true}
              />
            </div> */}

            <div className="mb-4">
              <div className="relative flex items-center text-white">
                <input
                  type={passwordReplyVisible ? "text" : "password"}
                  name="passwordReply"
                  className="w-full rounded bg-black py-2 px-3 text-white"
                  placeholder="Confirmar Contraseña *"
                  onChange={(e) => setPasswordReply(e.target.value)}
                  value={passwordReply}
                  required={true}
                />
                <button
                  type="button"
                  className="absolute right-0 h-full rounded px-2 hover:bg-neutral-700 flex items-center"
                  onClick={() => {
                    setPasswordReplyVisible((prev) => !prev);
                  }}
                >
                  {!passwordReplyVisible ? (
                    <ion-icon name="eye-off-outline"></ion-icon>
                  ) : (
                    <ion-icon name="eye-outline"></ion-icon>
                  )}
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="border uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
                type="submit"
              >
                Registrarse
              </button>
            </div>
          </form>
        )}

        <div className="relative pb-8">
          <hr></hr>
          <span className="absolute text-other-social">o bien</span>
        </div>

        <button className="facebook flex items-center justify-center border border-blue-700 uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full mb-4">
          <ion-icon name="logo-facebook"></ion-icon>
          Ingresar con Facebook
        </button>

        <button className="google flex items-center justify-center border border-blue-700 uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full">
          <ion-icon name="logo-google"></ion-icon>
          Ingresar con Google
        </button>

        <p className="my-4 text-sm flex justify-between px-3">
          ¿Ya tienes una cuenta?
          <Link to="/login" className="text-blue-700 hover:text-blue-900">
            Iniciar Sesión
          </Link>
        </p>
      </div>
    </div>
  );
}
