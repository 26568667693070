/* eslint-disable import/no-anonymous-default-export */
import {
    FETCH_USER_EDIT_INITIAL,
    FETCH_USER_EDIT_REQUEST,
    FETCH_USER_EDIT_SUCCESS,
    FETCH_USER_EDIT_FAILURE,
  } from "./actionTypes";
  
  import { UserEditActions, UserEditState } from "./types";
  
  const initialState: UserEditState = {
    loading: false,
    result: null,
    error: null,
  };
  
  export default (state = initialState, action: UserEditActions) => {
    switch (action.type) {
      case FETCH_USER_EDIT_INITIAL: 
        return {
          loading: false,
          result: null,
          error: null,
        };
      case FETCH_USER_EDIT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_USER_EDIT_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload.result,
          error: null,
        };
      case FETCH_USER_EDIT_FAILURE:
        return {
          ...state,
          loading: false,
          result: [],
          error: action.payload.error,
        };
      default:
        return {
          ...state,
        };
    }
  };