import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ThumbVideo from "../components/ThumbVideo";
import { useSelector } from "react-redux";
import { getResultSerieChapterSelector } from "../_store/serieChapter/selectors";
import { getResultUserLoginSelector } from "../_store/userLogin/selectors";
import { getResultUserInfoSelector } from "../_store/userInfo/selectors";
import { getLoadingUserInfoEditSelector } from "../_store/userInfoEdit/selectors";
import SpinningLoading from "../components/Spinning/SpinningLoading";

export function ContinueViewPage() {
  const [data, setData] = useState([]);

  const serieChapter = useSelector(getResultSerieChapterSelector);
  const user = useSelector(getResultUserLoginSelector);
  const userInfo = useSelector(getResultUserInfoSelector);
  const loading = useSelector(getLoadingUserInfoEditSelector);

  const getProgress = (timeView, duration) => {
    return (parseInt(timeView) * 100) / parseInt(duration);
  };

  const findUserMember = (selectedSerie) => {
    const selectedMember = userInfo?.find((e) => e.serie === selectedSerie?.id);
    if (selectedMember) return selectedMember.membership;
    return "none";
  };

  serieChapter.forEach((itemSerie) => {
    itemSerie.memberUserSerie = findUserMember(itemSerie);
  });

  const fetchData = () => {
    let elem = [];

    userInfo.forEach((item) => {
      /*COMPROBAR QUE SEA UN CAPITULO VISTO*/
      if (item.tracking_time >= 1) {
        serieChapter.forEach((itemSerie) => {
          itemSerie.chapter.forEach((itemChapter) => {
            if (itemChapter.id === item.chapter) {
              elem.push({
                ...itemChapter,
                serieTitle: itemSerie.title,
                serieLogo: itemSerie.img_logo,
                serieSlug: itemSerie.slug,
                timeView: item.tracking_time,
                memberUserSerie: itemSerie.memberUserSerie,
              });
            }
          });
        });
      }
    });
    setData(elem);
  };

  useEffect(() => {
    if (user) fetchData();
  }, [loading]);

  return (
    <div className="default-page container md:py-10 py-5 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl">Continuar Viendo</h1>
      <div className="container-view">
        {user ? (
          loading ? (
            <div className="my-32">
              <SpinningLoading />
            </div>
          ) : (
            <div className="grid md:grid-cols-3 grid-cols-2">
              {data && data.length === 0 && (
                <p>No tiene ningún video pendiente de observar.</p>
              )}
              {data.map((item, index) => (
                <div
                  className="wrap-estrenos pt-4 md:pt-5 pr-2 md:pr-4"
                  key={index}
                >
                  <ThumbVideo
                    background={item.img_web}
                    logo={item.serieLogo}
                    link={`/series/${item.serieSlug}/${item.type}/${item.chapter}?time=${item.timeView}`}
                    chapterMembership={item.membership}
                    userMembership={item.memberUserSerie}
                  />
                  <div className="w-full bg-gray-200 h-1 mt-3">
                    <div
                      className="bg-[#ff0000] h-1"
                      style={{
                        width: getProgress(item.timeView, item.duration) + "%",
                      }}
                    ></div>
                  </div>
                  <div className="slide-title md:text-base text-xs">
                    <span className="hidden title-play">
                      <ion-icon name="play"></ion-icon>
                    </span>
                    <div>
                      <div>{item.serieTitle}</div>
                      <div>{item.title}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        ) : (
          <div className="md:py-10 py-8 flex items-center flex-col md:flex-row">
            <div className="md:w-3/5 text-center md:text-left">
              Debe autenticarse para ver el progreso de los videos que ha
              observado.
            </div>
            <div className="actions-buttons flex md:flex-row flex-col mt-5 md:mt-0">
              <Link
                to={"/login"}
                className="btn-default-b-red md:mr-5 mb-4 md:mb-0"
              >
                <span>Iniciar Sesión</span>
              </Link>
              <Link to={"/registrar"} className="btn-default-b-red">
                <span>Registrarse</span>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
