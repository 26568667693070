import React, { useRef } from "react";
import { useSelector } from "react-redux";

import { getResultSlideSelector } from "../_store/slide/selectors";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const PromoSlide = () => {
  SwiperCore.use([Autoplay]);
  const slideInfo = useSelector(getResultSlideSelector);
  const swiperPromo = useRef();

  return (
    <Swiper
      ref={swiperPromo}
      loop={true}
        autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      {slideInfo != null &&
        slideInfo.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="flex bg-gradient-to-t from-slate-700 my-auto w-full">
                <div
                  //href={item.url}
                  key={index}
                  className="flex sm:flex-row flex-col from-white justify-center mx-auto"
                >
                  <div className="flex justify-center">
                    <p className="my-auto color-grey text-xs text-slate-200">
                      Sponsored
                    </p>
                    <img
                      src={item.img}
                      className="h-10 mx-2 md:h-16 md:mx-6"
                      alt="promotion movile"
                    ></img>
                  </div>
                  <p className="text-promo my-auto">{item.text}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default PromoSlide;
