/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from "react";
import {useParams, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import {getResultSerieChapterSelector} from "../_store/serieChapter/selectors";
import {getResultUserInfoSelector} from "../_store/userInfo/selectors";
import {getResultUserLoginSelector} from "../_store/userLogin/selectors";
import {getMuxUrlErrorSelector, getMuxUrlLoadingSelector, getMuxUrlSelector} from "../_store/muxPlayer/selectors";

import {createMuxUrl} from "../_store/muxPlayer/actions";

import MuxPlayer from "../components/MuxPlayer";
import LikeButton from "../components/Buttons/LikeButton";
import OnlyFansButton from "../components/Buttons/OnlyFans";
import FavButton from "../components/Buttons/FavButton";
import NextButtons from "../components/Buttons/NextButton";
import ChaptersButtons from "../components/Buttons/ChaptersButtons";
import ShareButton from "../components/Buttons/ShareButton";
import MembershipInfo from "../components/MemebershipInfo";

import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";

import {useMediaQuery} from "react-responsive";
import ThumbVideo from "../components/ThumbVideo";
import Comments from "../components/Comments";
import {membershipAccess} from "../util";
import ModalMembership from "../components/Modals/ModalMembership";

import {toast} from "react-toastify";

export function PlayerPage({setModalCoinsOn}) {
	const isMobile = useMediaQuery({query: "(max-width: 767px)"});
	const {slugSerie, slugType, slugChapter} = useParams();
	const location = useLocation();
	const serieChapter = useSelector(getResultSerieChapterSelector);
	const userInfo = useSelector(getResultUserInfoSelector);
	const user = useSelector(getResultUserLoginSelector);
	const videoUrl = useSelector(getMuxUrlSelector);
	const loadingVideoUrl = useSelector(getMuxUrlLoadingSelector);
	const errorVideoUrl = useSelector(getMuxUrlErrorSelector);
	const dispatch = useDispatch();

	const [modalMembershipOn, setModalMembershipOn] = useState(false);
	const [userMembership, setUserMembership] = useState("");
	const [access, setAccess] = useState(false);
	const [loadingInfo, setLoadingInfo] = useState(true);

	const [selectedChapter, setSelectedChapter] = useState();
	const [selectedSerie, setSelectedSerie] = useState();
	const [elemSerie, setElementSerie] = useState();

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const time = params?.get("time") || 0;
	const isLoading = loadingVideoUrl || loadingInfo;

	const breakPoints = {
		640: {
			slidesPerView: 2.5,
			spaceBetween: 15,
		},
		768: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 25,
		},
		1366: {
			slidesPerView: 4,
			spaceBetween: 25,
		},
		1920: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
	};

	useEffect(() => {
		if (selectedChapter) dispatch(createMuxUrl(selectedChapter));
	}, [selectedChapter]);

	useEffect(() => {
		const selectedSerieSlug = serieChapter?.find(e => e.slug === slugSerie);
		setSelectedSerie(selectedSerieSlug);

		const ListAll = selectedSerieSlug?.chapter;

		const selectedChapterSlug = ListAll?.find(e => (e.chapter === parseInt(slugChapter) || e.chapter === slugChapter) && e.type === slugType);
		setSelectedChapter(selectedChapterSlug);

		let membershipSlug = "";
		if (user?.login && selectedSerieSlug) {
			const selectedMember = userInfo?.find(e => e.serie === selectedSerieSlug?.id);
			if (selectedMember) {
				membershipSlug = selectedMember.membership;
			}
		} else setLoadingInfo(false);
		setUserMembership(membershipSlug);

		let accesSlug = membershipAccess(membershipSlug, selectedChapterSlug?.membership);
		if (accesSlug && accesSlug !== 1) {
			setModalMembershipOn(true);
		} else setModalMembershipOn(false);
		setAccess(accesSlug);

		setElementSerie({
			serieId: selectedSerieSlug?.id,
			serieTitle: selectedSerieSlug?.title,
			serieThumbs: selectedSerieSlug?.img_thumbsnail,
			serieSlug: selectedSerieSlug?.slug,
			serieLogo: selectedSerieSlug?.img_logo,
			serieMemberTitle: selectedSerieSlug?.membership_title,
			serieMemberUser: userMembership,
			chapterMembership: selectedChapterSlug?.membership,
		});

		if (serieChapter && access !== undefined) {
			setLoadingInfo(false);
		}
	}, [location, user, serieChapter, slugChapter, slugType, slugSerie, userInfo, access]);

	useEffect(() => {
		if (!isLoading && !loadingVideoUrl && errorVideoUrl) {
			toast.error(errorVideoUrl);
		}
	}, [loadingVideoUrl, errorVideoUrl]);

	const nextLink = selectedSerie?.chapterFree[parseInt(slugChapter)];
	const prevLink = parseInt(slugChapter) > 1;

	/*Crear link a compartir*/
	const shareLink = `series/${slugSerie}/${slugChapter}`;
	const shareTitle = `${selectedSerie?.title}-${selectedChapter?.title}`;

	return (
		<>
			{selectedSerie === undefined || selectedChapter === undefined ? (
				<>
					<div className="md:flex max-w-screen-2xl mx-auto md:py-6 py-3 ">
						<div className="container md:px-5 px-3">
							<div className="text-center">Este contenido no se encuentra disponible</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="md:flex max-w-screen-2xl mx-auto md:pb-6">
						<div className="container md:px-5 px-3">
							<div className="container-player relative max-w-6xl mx-auto">
								{!isLoading && !errorVideoUrl ? (
									<MuxPlayer
										className="w-full mt-2"
										source={videoUrl}
										chapterId={selectedChapter?.id}
										autoPlay={true}
										controls={true}
										time={time}
										hasAds={userMembership === '' && selectedChapter?.hasAdVideo}
										ads={selectedChapter?.ads}
									/>
								) : (
									<MuxPlayer className="w-full mt-2 bg-black" source={null} />
								)}
								<MembershipInfo SelectedSerie={selectedSerie} membership={userMembership} />
								<div className="md:flex block bg-black px-3">
									<h1 className="text-l md:text-xl w-full md-w-1/2 p-2">{selectedSerie?.title + " / " + selectedChapter?.title}</h1>
									<div className="flex justify-between items-center w-full md:w-1/2 p-2">
										<div className="mr-3">
											<ChaptersButtons link={slugSerie} />
										</div>
										{selectedChapter?.type === "chapter" && (
											<div className="next-prev flex">
												{prevLink && (
													<div className="mr-3">
														<NextButtons
															action={() => setLoadingInfo(true)}
															link={`/series/${slugSerie}/${slugType}/${parseInt(slugChapter) - 1}`}
															title="Anterior"
														/>
													</div>
												)}
												{nextLink && (
													<div>
														<NextButtons
															action={() => setLoadingInfo(true)}
															link={`/series/${slugSerie}/${slugType}/${parseInt(slugChapter) + 1}`}
															title="Siguiente"
														/>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
								{access === 1 && (
									<div className="actions-buttons flex flex-col-reverse lg:flex-row pt-3 lg:pt-4 text-center">
										<div className="flex relative justify-center items-center md:justify-start">
											<div className="mr-3">
												<OnlyFansButton
													serieId={selectedSerie?.id}
													chapterId={selectedChapter?.id}
													elem={"chapter"}
													setModalCoinsOn={setModalCoinsOn}
												/>
											</div>
											<div className="mr-3 loading-button-section">
												<LikeButton itemId={selectedChapter?.id} elem={"chapter"} />
											</div>
											<div className="mr-3 loading-button-section">
												<FavButton itemId={selectedChapter?.id} elem={"chapter"} />
											</div>
											<div className="mr-3">
												<ShareButton shareLink={shareLink} shareTitle={shareTitle} />
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="default-page container pb-5 md:px-5 px-3">
						<div className="container-series md:pt-5">
							<div className="container-capitulo mb-4">
								<h3 className="pt-4 text-xl md:text-2xl uppercase font-bold border-title">Capítulos</h3>
								<Swiper
									slidesPerView={1.5}
									spaceBetween={10}
									navigation={!isMobile}
									pagination={{clickable: true}}
									breakpoints={breakPoints}
									modules={[Navigation]}
								>
									{selectedSerie?.chapterFree.map((item, index) => (
										<SwiperSlide key={index}>
											<div className="w-full pt-4 md:pt-5 pr-0 sm:pr-3 md:pr-4">
												<ThumbVideo
													background={item.img_web}
													logo={selectedSerie.img_logo}
													link={`/series/${slugSerie}/${item.type}/${item.chapter}`}
													chapterMembership={item.membership}
													userMembership={userMembership}
													action={() => setLoadingInfo(true)}
												/>
												<div className="slide-title">
													<span className="hidden title-play">
														<ion-icon name="play"></ion-icon>
													</span>
													<div className="text-left">
														{/* <span className="mr-2">{item.title}</span> */}
														<span className="font-bold">{item.title}</span>
														<p className="text-xs mt-2">{item.long_description}</p>
													</div>
												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</div>
						{!loadingInfo && access === 1 && (
							<Comments serieId={selectedChapter?.serieId} chapterId={selectedChapter?.id} setModalCoinsOn={setModalCoinsOn} />
						)}
						{modalMembershipOn && <ModalMembership setModalOn={setModalMembershipOn} serie={elemSerie} />}
					</div>
				</>
			)}
		</>
	);
}
