import { createSelector } from "reselect";

import { AppState } from "../_root/rootReducer";

const getLoading = (state: AppState) => state.coinValue.loading;

const getResult = (state: AppState) => state.coinValue.result;

const getError = (state: AppState) => state.coinValue.error;

export const getResultCoinValueSelector = createSelector(
  getResult,
  (result) => result
);

export const getLoadingCoinValueSelector = createSelector(
  getLoading,
  (loading) => loading
);

export const getErrorCoinValueSelector = createSelector(
  getError,
  (error) => error
);
