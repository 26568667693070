import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";

const SwipperHome = ({ series, isMobile }) => {
  function printKeywords (keywords) {
    return keywords.map((keyword, index) => (
      <div className="flex items-center" key={index}>
        <li className="uppercase text-[8px] lg:text-[12px] mr-1">{keyword}</li>
        {index !== ( keywords.length - 1 ) ? <span className="ml-1">&middot;</span> : null}
      </div>
    ))
  }

  function getPremiere(serie) {
    let resultPremiere = [];
  
    serie.chapter.map((item) => {
      if (item.premiere) {
        resultPremiere.push(item);
      }
    });
  
    return resultPremiere.length ? resultPremiere[0] : null;  
  }


  return (
    <div className="home-banner">
      <Swiper
        pagination={true}
        navigation={true}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        modules={[Autoplay, Navigation, Pagination]}
      >
        {series.map((serie) => {
          let premiere = getPremiere(serie);

          return (
            <SwiperSlide key={serie.id}>
              <img
                src={
                  isMobile
                    ? serie.img_banner
                    : serie.img_banner_web_desktop
                }
                alt={"Banner " + serie.title}
                className="img-banner"  
              />
              <div className="wrap-info-serie container">
                <div className="info-serie md:pl-[60px] px-3">
                  <img
                    className="md:pb-3 pb-2 lg:w-full w-full md:w-24"
                    src={serie.img_logo}
                    alt={"Banner " + serie.title}
                  />
                  <div className="flex lg:pb-3 pb-1 flex-wrap justify-center md:hidden info-serie-categ items-center">
                    <ul className="md:mr-4 md:ml-8 flex space-x-2">{printKeywords(serie.keyword)}</ul>
                    {
                      premiere && premiere.id ? 
                        <Link
                          className="px-2"
                          to={"/series/" + serie.slug + "/chapter/" + premiere.chapter}
                        >
                          <span className="bg-black bg-opacity-60 rounded-lg py-0 px-2 text-[8px] leading-6 lg:text-[12px] mr-2 border-[1px] border-[#ff0000]">Estreno Capítulo {premiere.chapter}</span> 
                      </Link> :
                      null
                    }
                  </div>
                  <p className="text-xs lg:text-sm md:pb-3 pb-1">{serie.description}</p>
                  <div className="info-serie-buttons">
                    <div className="text-left pb-3 hidden md:flex items-center justify-start">
                      <ul className="py-1 flex space-x-2">{printKeywords(serie.keyword)}</ul>
                      {
                        premiere && premiere.id ? 
                        <Link
                          to={"/series/" + serie.slug + "/chapter/" + premiere.chapter}
                        >
                          <span className="bg-black bg-opacity-60 rounded-lg py-1 px-2 text-[8px] leading-6 lg:text-[12px] mr-2 border-[1px] border-[#ff0000]">Estreno Capítulo {premiere.chapter}</span>
                        </Link> : 
                        null
                      }
                    </div> 
                    <div className="actions-buttons actions-buttons-desk hidden md:block">
                      <div className="flex pb-3">
                        <Link
                          to={"/series/" + serie.slug}
                          className="btn-default-b-red"
                        >
                          <ion-icon name="information-circle-outline"></ion-icon>
                          <span>Ver Detalles</span>
                        </Link>
                      </div>
                    </div>
                    <div className="actions-buttons flex md:hidden pb-4">
                      <Link
                        to={"/series/" + serie.slug}
                        className="btn-default-b-red mx-auto"
                      >
                        <ion-icon name="information-circle-outline"></ion-icon>
                        <span>Ver Detalles</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  );
};

export default SwipperHome;
