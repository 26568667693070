import { useEffect, useState } from "react";
import ThumbVideo from "../components/ThumbVideo";
import { useSelector } from "react-redux";
import { getResultSerieChapterSelector } from "../_store/serieChapter/selectors";
import { getResultUserInfoSelector } from "../_store/userInfo/selectors";

export function PopularPage() {
  const [data, setData] = useState([]);
  const serieChapter = useSelector(getResultSerieChapterSelector);

  const userInfo = useSelector(getResultUserInfoSelector);

  const findUserMember = (selectedSerie) => {
    const selectedMember = userInfo?.find((e) => e.serie === selectedSerie?.id);
    if (selectedMember) return selectedMember.membership;
    return "none";
  };

  serieChapter.forEach((itemSerie) => {
    itemSerie.memberUserSerie = findUserMember(itemSerie);
  });

  useEffect(() => {
    const fetchDataOrder = () => {
      let elem;
      elem = [];
      serieChapter.forEach((itemSerie) => {
        itemSerie.chapter.forEach((itemChapter) => {
          if (itemChapter.premiere === false) {
            elem.push({
              ...itemChapter,
              serieTitle: itemSerie.title,
              serieLogo: itemSerie.img_logo,
              serieSlug: itemSerie.slug,
              memberUserSerie: itemSerie.memberUserSerie,
            });
          }
        });
      });
      console.log("EE>>>", elem);
      //Ordenar capitulos por mayor cantidad de likes
      elem.sort((a, b) => b.total_likes - a.total_likes);
      setData(elem);
    };
    fetchDataOrder();
  }, []);

  return (
    <div className="default-page container md:py-10 py-5 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl">Los Más Populares</h1>
      <div className="container-populares">
        <div className="grid md:grid-cols-3 grid-cols-2">
          {data.map((item, index) => (
            <div
              className="wrap-estrenos pt-4 md:pt-5 pr-2 md:pr-4"
              key={index}
            >
              <ThumbVideo
                background={item.img_web}
                logo={item.serieLogo}
                link={`/series/${item.serieSlug}/${item.type}/${item.chapter}`}
                chapterMembership={item.membership}
                userMembership={item.memberUserSerie}
              />
              <div className="slide-title md:text-base text-xs">
                <span className="hidden title-play">
                  <ion-icon name="play"></ion-icon>
                </span>
                <div>
                  <div>{item.serieTitle}</div>
                  <div>{item.title}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
