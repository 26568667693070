import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { auth } from "./_store/firebase";
import SwiperCore, { Autoplay } from "swiper";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { HomePage } from "./pages/HomePage";
import { PremierePage } from "./pages/PremierePage";
import { PopularPage } from "./pages/PopularPage";
import { FavoritesPage } from "./pages/FavoritesPage";
import { SponsorsPage } from "./pages/SponsorsPage";
import { ContinueViewPage } from "./pages/ContinueViewPage";
import { SeriesPage } from "./pages/SeriesPage";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { DonationPage } from "./pages/DonationPage";
import { ContactPage } from "./pages/ContactPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { PlayerPage } from "./pages/PlayerPage";
import { MembershipPage } from "./pages/MembershipPage";
import { LogoutPage } from "./pages/LogoutPage";
import { RecoveryPassword } from "./pages/RecoveryPassword";
import { PolicyPage } from "./pages/PolicyPage";
import { TermPage } from "./pages/TermPage";
import { EditUser } from "./pages/Account/EditUser";
import { EditPassword } from "./pages/Account/EditPassword";
import { EmailPasswordReset } from "./pages/EmailPasswordReset";

import Navbar from "./components/Navbar";
import SectionFooter from "./components/SectionFooter";
import SectionDownload from "./components/SectionDownload";
import SectionMembership from "./components/SectionMembership";

import ScrollToTop from "./components/ScrollToTop";
import SpinningLoading from "./components/Spinning/SpinningLoading";

import { getResultUserLoginSelector } from "./_store/userLogin/selectors";
import { getLoadingUserInfoSelector } from "./_store/userInfo/selectors";
import {
  getLoadingSerieChapterSelector,
  getErrorSerieChapterSelector,
} from "./_store/serieChapter/selectors";

import { fetchSerieChapterRequest } from "./_store/serieChapter/actions";
import { fetchUserInfoRequest } from "./_store/userInfo/actions";
import { fetchUserLoginRequest } from "./_store/userLogin/actions";
import { fetchUserInfoEditRequest } from "./_store/userInfoEdit/actions";
import { fetchSlideRequest } from "./_store/slide/actions";
import { AboutUs } from "./pages/AboutUsPage";
import ModalCoins from "./components/Modals/ModalCoins";

import CookieConsent from "react-cookie-consent";
import ProtectedRoute from "./components/ProtectedRoute";
import { fetchSocialNetworkRequest } from "./_store/social/actions";

const App = () => {
  const dispatch = useDispatch();

  SwiperCore.use([Autoplay]);

  const loading = useSelector(getLoadingSerieChapterSelector);
  const loadingUserInfo = useSelector(getLoadingUserInfoSelector);
  const error = useSelector(getErrorSerieChapterSelector);
  const user = useSelector(getResultUserLoginSelector);
  const [item, setItem] = useState();
  const [modalCoinsOn, setModalCoinsOn] = useState(false);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("timeTrack"));
    toast.error("sdfds");
    //adjust minimous time for track
    if (items != null && items.lastTimeTrack != 0) {
      //guardar en la base de datos
      dispatch(
        fetchUserInfoEditRequest({
          id: items.lastChapterTrack,
          type: "chapter",
          field: { tracking_time: items.lastTimeTrack },
        })
      );
      //clear values
      var timeTrack = { chapterTrack: "", lastTimeTrack: 0 };
      localStorage.setItem("timeTrack", JSON.stringify(timeTrack));
    }
    dispatch(fetchSerieChapterRequest());
    dispatch(fetchSlideRequest());
    auth.onAuthStateChanged((userRestore) => {      
      if (!userRestore) {
        auth.signInAnonymously()
      } else if (!userRestore?.isAnonymous) {
        let social = false;
        if (userRestore?.providerData && userRestore.providerData.length > 0) {
          let providers = userRestore.providerData.filter((provider) => provider.providerId !== 'password');
          
          social = providers.length > 0;
    
          if (social) {      
            dispatch(fetchSocialNetworkRequest({
              uid: userRestore.uid,
              name: userRestore.providerData[0].displayName,
              email: userRestore.email,
              providerId: userRestore.providerData[0].providerId
            }))
          }

          if (!social) {
            dispatch(
              fetchUserLoginRequest({
                email: userRestore.email,
                uid: userRestore.uid,
              })
            );
          }          
      }}
    
    });
  }, []);

  useEffect(() => {
    dispatch(fetchUserInfoRequest());
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const payment = params?.get("payment") || null;
    if (payment !== null)
      payment === "true"
        ? toast.info("El pago se ha completado con exito")
        : toast.error("El pago no ha sido completado");
  }, [user]);

  return (
    <div className="App">
      {loading || loadingUserInfo ? (
        <div className="absolute w-full h-full pt-[45vh]">
          <SpinningLoading />
        </div>
      ) : error ? (
        <div>Por favor revisar la conexión</div>
      ) : (
        <Router>
          <Navbar setModalCoinsOn={setModalCoinsOn} />
          <CookieConsent
            location="bottom"
            buttonText="Entendido!"
            debug={false}
            style={{}}
            buttonStyle={{}}
            expires={150}
            buttonClasses="btn bg-sky-400 uppercase font-bold text-white py-2 md:py-2 px-5 !mt-0 rounded"
            containerClasses="cookies-consents"
            contentClasses=""
          >
            Este website utiliza cookies y herramientas de terceros para
            personalizar la experiencia del usuario, proveer facilidades en la
            redes sociales y para analizar el trafico en nuestro servico. Acá
            puede leer nuestra{" "}
            <span className="font-bold">
              <Link to="/politica-privacidad"> Política de Privacidad</Link>
            </span>
          </CookieConsent>
          <div className="main">
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/registrar" element={<RegisterPage />} />
                <Route path="/estrenos" element={<PremierePage />} />
                <Route path="/popular" element={<PopularPage />} />
                <Route path="/favoritos" element={<FavoritesPage />} />
                <Route path="/sponsors" element={<SponsorsPage />} />
                <Route path="/donaciones" element={<DonationPage />} />
                <Route path="/contacto" element={<ContactPage />} />
                <Route path="/membresias" element={<MembershipPage />} />
                <Route path="/sobre-nosotros" element={<AboutUs />} />
                <Route path="/terminos-condiciones" element={<TermPage />} />
                <Route path="/politica-privacidad" element={<PolicyPage />} />
                <Route path="/cerrar-sesion" element={<LogoutPage />} />
                <Route path="/password-reset" element={<EmailPasswordReset />} />
                <Route path="/recuperar-contrasenya" element={<RecoveryPassword />} />
                <Route path="/continuar-viendo" element={<ContinueViewPage />} />
                <Route path="/series/:slug" element={<SeriesPage />} />
                <Route element={<ProtectedRoute /> }>
                  <Route path="/cuenta/editar-usuario" element={<EditUser />} />
                  <Route path="/cuenta/cambiar-password" element={<EditPassword />} />
                </Route>
                <Route
                  path="/series/:slugSerie/:slugType/:slugChapter"
                  element={<PlayerPage setModalCoinsOn={setModalCoinsOn} />}
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </ScrollToTop>
            <SectionDownload />
            <SectionMembership />
          </div>
          <SectionFooter />
          {modalCoinsOn && (
            <ModalCoins setModalCoinsOn={setModalCoinsOn} user={user} />
          )}
          
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </Router>
      )}
    </div>
  );
};

export default App;
