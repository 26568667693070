import { useState } from "react";

export function DonationPage() {
  const [selectDonation, setSelectDonation] = useState({ value: "" });

  const handleChange = (e) => {
    setSelectDonation({ value: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectDonation.value);
    let selectedProduct = products.find(
      (a) => a.id === parseInt(selectDonation.value)
    );
    if (selectedProduct == null || selectedProduct === undefined) {
      alert("Complete los datos correctamente");
    } else {
      /* window.location = selectedProduct.link; */
      console.log("Ir a compra");
    }
  };

  const products = [
    {
      id: 1,
      price: 5 /* link: "https://buy.stripe.com/test_4gw28UaOb61k9WM005" */,
    },
    {
      id: 2,
      price: 10,
      /* link: "https://buy.stripe.com/test_eVa6pag8v4Xgd8Y6ou", */
    },
    {
      id: 3,
      price: 25,
      /* link: "https://buy.stripe.com/test_28odRC5tR0H06KA8wD", */
    },
    {
      id: 4,
      price: 50,
      /* link: "https://buy.stripe.com/test_28oeVG2hF2P8ed23ck", */
    },
  ];

  return (
    <div className="default-page container md:py-10 py-5 md:px-5 px-3">
      <div className="container-donation">
        <form className="wrap-donation" onSubmit={handleSubmit}>
          <div className="up-sec flex items-center">
            <div className="donation-desc w-1/2 text-center hidden md:block">
              <div className="text-xl md:text-2xl font-bold">
                TU DONACIÓN NOS HACE LLEGAR MÁS LEJOS
              </div>
              <p>
                Con tu aporte podremos seguir creando los contenidos que más te
                gustan y de la mejor calidad. ¡Apoya el talento latino y hagamos
                crecer este sueño juntos!
              </p>
            </div>
          </div>
          <div className="options p-6 mt-[-80px] md:mt-0">
            <div className="donacion-desc text-center md:hidden mb-3">
              <div className="text-xl md:text-2xl font-bold">
                TU DONACIÓN NOS HACE LLEGAR MÁS LEJOS
              </div>
              <p>
                Con tu aporte podremos seguir creando los contenidos que más te
                gustan y de la mejor calidad. ¡Apoya el talento latino y hagamos
                crecer este sueño juntos!
              </p>
            </div>
            <div className="wrap-options flex flex-col md:flex-row">
              <div className="option-default w-full md:w-1/2 text-center mb-3 md:mb-0">
                <div className="text-sm md:text-base">
                  Seleccione la serie a la que desea donar
                </div>
                <div className="wrap-select relative">
                  <select
                    id="select-cap-modal"
                    className="form-select bg-black rounded w-full py-2 px-3"
                  >
                    <option value="" disabled selected>
                      Elegir Serie
                    </option>
                    <option value="1">Serie 1</option>
                    <option value="2">Serie 2</option>
                  </select>
                </div>
              </div>
              <div className="option-default w-full md:w-1/2 text-center">
                <div className="text-sm md:text-base">
                  Seleccione la cantidad que desea donar
                </div>
                <div className="wrap-select relative">
                  <select
                    value={selectDonation.value}
                    onChange={handleChange}
                    className="form-select bg-black rounded w-full py-2 px-3"
                  >
                    <option value="" disabled selected>
                      Elegir cantidad
                    </option>
                    <option value="1">5</option>
                    <option value="2">10</option>
                    <option value="3">25</option>
                    <option value="4">50</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="button-donation mt-6 text-center">
              <button className="bg-blue-600 hover:bg-sky-400 uppercase font-bold text-white py-2 md:py-2 px-5 rounded duration-500">
                Donar Ahora
              </button>
            </div>
            {/*  <div class="button_donacion">
              <button id="myBtnDon">Donar Ahora</button>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}
