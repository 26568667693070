import { Swiper, SwiperSlide } from "swiper/react";
import ThumbVideo from "../components/ThumbVideo";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

const getProgress = (timeView, duration) => {
  return (parseInt(timeView) * 100) / parseInt(duration);
};

const SwipperSlides = ({ data, link, type }) => {
  if (type === "serie") {
    return (
      <div className="container default-page slide-series md:pt-10 pt-8">
        <div className="title flex pb-5 justify-between md:justify-start">
          <h3 className="text-xl md:text-3xl md:mr-4">{data.title}</h3>
          <Link
            to={"/series/" + link}
            className="btn-default-b-white"
            title="Detalles"
          >
            <ion-icon name="information-circle-outline"></ion-icon>
            <span>Detalles</span>
          </Link>
        </div>
        <Swiper
          slidesPerView={1.5}
          spaceBetween={10}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2.5,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            1366: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            1920: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
          modules={[Navigation]}
        >
          {data.chapterFree.map((item, index) => (
            <div key={index}>
              <SwiperSlide key={index}>
                <div className="slide-container">
                  <ThumbVideo
                    background={item.img_web}
                    logo={data.img_logo}
                    link={`series/${data.slug}/${item.type}/${item.chapter}`}
                    chapterMembership={item.membership}
                    userMembership={data.memberUserSerie}
                    premiere={item.premiere}
                  />
                  <div className="slide-title">
                    <span className="hidden title-play">
                      <ion-icon name="play"></ion-icon>
                    </span>
                    <div>
                      <div>{item.title}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
        <div className="separator hidden">
          <hr></hr>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container default-page slide-series md:pt-10 pt-8">
        <div className="title flex pb-5 justify-between md:justify-start">
          <h3 className="text-xl md:text-3xl md:mr-4">{data.sectionTitle}</h3>
          {link !== "" && (
            <Link to={link} className="btn-default-b-white" title="Ver Todos">
              <span>Ver Todos</span>
            </Link>
          )}
        </div>
        <Swiper
          slidesPerView={1.5}
          spaceBetween={10}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2.5,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            1366: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            1920: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
          modules={[Navigation]}
        >
          {data.chapters.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="slide-container">
                <ThumbVideo
                  background={item.img_web}
                  logo={item.logoSerie}
                  link={`series/${item.slugSerie}/${item.type}/${item.chapter}${
                    type === "chapters-view" ? `?time=${item.timeView}` : ""
                  }`}
                  chapterMembership={item.membership}
                  userMembership={item.memberUserSerie}
                  premiere={item.premiere}
                />
                {type === "chapters-view" && (
                  <div className="w-full bg-gray-200 h-1 mt-3">
                    <div
                      className="bg-[#ff0000] h-1"
                      style={{
                        width: getProgress(item.timeView, item.duration) + "%",
                      }}
                    ></div>
                  </div>
                )}
                <div className="slide-title">
                  <span className="hidden title-play">
                    <ion-icon name="play"></ion-icon>
                  </span>
                  <div className="text-left">
                    <div>{item.titleSerie}</div>
                    <div>{item.title}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="separator hidden">
          <hr></hr>
        </div>
      </div>
    );
  }
};

export default SwipperSlides;
