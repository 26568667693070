import {
  FETCH_SOCIAL_NETWORK_FAILURE,
  FETCH_SOCIAL_NETWORK_REQUEST,
  FETCH_SOCIAL_NETWORK_SUCCESS,
} from './actionTypes';
import {
  FetchSocialNetworkFailure,
  FetchSocialNetworkFailurePayload,
  FetchSocialNetworkSuccess,
  FetchSocialNetworkRequest,
  FetchSocialNetworkRequestQuery,
} from './types';

export const fetchSocialNetworkFailure = (
  payload: FetchSocialNetworkFailurePayload,
): FetchSocialNetworkFailure => ({
  type: FETCH_SOCIAL_NETWORK_FAILURE,
  payload,
});

export const fetchSocialNetworkSuccess = (): FetchSocialNetworkSuccess => ({
  type: FETCH_SOCIAL_NETWORK_SUCCESS,
});

export const fetchSocialNetworkRequest = (
  query: FetchSocialNetworkRequestQuery,
): FetchSocialNetworkRequest => ({
  type: FETCH_SOCIAL_NETWORK_REQUEST,
  query,
});
