import { auth } from "../../_store/firebase";
import { generateToken, isValidToken, restoreTokenMux, saveTokenMux } from '../../util/muxSecure';

export const generateUrl = async (item: any) => {
  try {
    let currentUser = auth.currentUser;
    let token = await restoreTokenMux(currentUser?.uid, item?.id);
    const authToken = await currentUser?.getIdToken();      
    
    if (token) {
      const validToken = await isValidToken(token, item.video_mux_id);

      if (!validToken) {
        token = await generateToken(item.id, currentUser?.uid, authToken);
        saveTokenMux(token, currentUser?.uid, item.id);
      }
    } else {
      token = await generateToken(item.id, currentUser?.uid, authToken);
      saveTokenMux(token, currentUser?.uid, item.id);
    }
    
    if(token === undefined){throw token}

    return `${process.env.REACT_APP_MUX_STREAM_BASE_URL}/${item.video_mux_id}.${process.env.REACT_APP_VIDEO_EXT}?token=${token}`;
  } catch (error) {
    throw error;        
  }
}