import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getResultEmojiSelector,
  getLoadingEmojiSelector,
  getErrorEmojiSelector,
} from "../../_store/emoji/selectors";
import { fetchEmojiRequest } from "../../_store/emoji/actions";
import { getResultUserLoginSelector } from "../../_store/userLogin/selectors";
import { toast } from "react-toastify";
import SpinningLoading from "../Spinning/SpinningLoading";

const baseURL = process.env.REACT_APP_API_URL + "api/coins/use-coins/";
const urlPayKey = process.env.REACT_APP_STRIPE_URL_KEY;

const ModalSticker = (props) => {
  const {
    setModalStickerOn,
    serieId,
    chapterId,
    paymentContents = "sticker",
    setUpdateSticker,
    setModalCoinsOn,
  } = props;
  const [activeButton, setActiveButton] = useState();
  const [showRec, setShowRec] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const user = useSelector(getResultUserLoginSelector);

  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingEmojiSelector);
  const emojis = useSelector(getResultEmojiSelector);
  //const error = useSelector(getErrorEmojiSelector);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  useEffect(() => {
    dispatch(fetchEmojiRequest({ serieId }));
  }, []);

  useEffect(() => {
    if (activeButton) {
      if (user.coin < activeButton.value) {
        setShowRec(true);
      } else {
        setShowRec(false);
      }
    }
  }, [activeButton]);

  const handleOKClick = () => {
    setModalStickerOn(false);
  };

  function buyCoins() {
    setModalCoinsOn(true);
  }

  function buySticker() {
    setLoadingPayment(true);
    createPayment();
  }

  function createPayment() {
    if (!user?.login) {
      toast.warning("Debe iniciar sesión para interactuar.");
      return false;
    }
    const requestsOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key-Pay": urlPayKey,
      },
      body: JSON.stringify({
        UserId: user.uid,
        StickerId: activeButton.id,
        SerieId: serieId,
        ChapterId: chapterId,
        Coins: activeButton.value,
        PaymentContents: paymentContents,
      }),
    };
    fetch(baseURL, requestsOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not OK");
        }
        return response.json();
      })
      .then((data) => {
        console.log("PAGADO");
        setLoadingPayment(false);
        setUpdateSticker(true);
        user.coin = user.coin - activeButton.value;
        handleOKClick();
      })
      .catch((error) => {
        setLoadingPayment(false);
        toast.error("Ha ocurrido un error con el proceso de pago.");
        console.error("There was an error!", error);
      });
  }

  return (
    <div
      className="modal-coins fixed flex items-center justify-center inset-0 bg-black bg-opacity-75 transition-opacity z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      onClick={handleOKClick}
    >
      <div className="flex justify-center items-center mt-4 mb-4">
        <div
          className="relative flex-col justify-center bg-black md:mx-0 mx-3 border-4 rounded-xl w-[90vw] max-w-[460px]"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="header-modal">
            <div
              className="modal-close cursor-pointer z-50"
              onClick={handleOKClick}
            >
              <svg
                className="fill-current absolute top-2 right-2"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>
          <div className="container-modal py-4 md:px-6 px-2 ">
            <div className="flex md:text-xl text-lg font-bold uppercase md:mb-5 mb-4 justify-center border-b-2">
              Enviar Sticker
            </div>
            <div className="mb-4 md:px-2 px-0">
              <div className="flex w-full justify-between items-center">
                <div className="flex items-center text-sm">
                  TIENES{" "}
                  <img
                    className="mx-1 w-5"
                    src="/images/icons/coin-bag.svg"
                    alt="coins"
                  />{" "}
                  {user.coin} COINS
                </div>
                <div>
                  <button
                    onClick={buyCoins}
                    className="bg-blue-600 uppercase font-bold text-white py-1 px-4 rounded hover:bg-sky-400 duration-500"
                  >
                    Recargar
                  </button>
                </div>
              </div>
              {showRec && (
                <div className="flex items-center text-sm mt-2 italic">
                  Necesitas{" "}
                  <img
                    className="mx-1 w-5"
                    src="/images/icons/coin.svg"
                    alt="coins"
                  />{" "}
                  {activeButton.value} para enviar tu ayuda.
                </div>
              )}
            </div>
            {isLoading ? (
              <SpinningLoading className="h-6" />
            ) : (
              <div>
                <div className="flex flex-wrap justify-evenly">
                  {emojis?.map((item, index) => (
                    <div className="basis-1/3 p-1 md:p-2" key={index}>
                      <button
                        onClick={() => setActiveButton(item)}
                        className={`w-full border-2 border-gray-400 rounded p-1 ${
                          activeButton?.id === item.id && "border-red-400"
                        }`}
                      >
                        <div className="flex items-center mb-2 md:h-32 sm:h-32 h-28">
                        <img
                          className=""
                          src={item.img}
                          alt={item.value}
                        />
                        </div>
                        <div className="flex justify-center">
                          <img
                            className="mx-1 w-5"
                            src="/images/icons/coin.svg"
                            alt="coins"
                          />
                          <span>{item.value}</span>
                        </div>
                      </button>
                    </div>
                  ))}
                </div>
                <div className="button-donation w-full flex justify-center mt-4">
                  <button
                    disabled={!activeButton || showRec}
                    className="bg-blue-600 hover:bg-sky-400 uppercase font-bold text-white py-2 md:py-2 px-5 rounded duration-500"
                    onClick={() =>
                      activeButton
                        ? buySticker()
                        : toast.warning("Debe selecionar una cantidad.")
                    }
                  >
                    {loadingPayment ? (
                      <SpinningLoading className="h-6 !mr-0" />
                    ) : (
                      "Donar Ahora"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSticker;
