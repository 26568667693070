import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getResultSerieChapterSelector} from "../_store/serieChapter/selectors";
import {getResultUserInfoSelector} from "../_store/userInfo/selectors";
import {getResultUserLoginSelector} from "../_store/userLogin/selectors";
import {useMediaQuery} from "react-responsive";

import LikeButton from "../components/Buttons/LikeButton";
import FavButton from "../components/Buttons/FavButton";
import PlayNowButton from "../components/Buttons/PlayNowButton";
import ShareButton from "../components/Buttons/ShareButton";

import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";

import "swiper/css";
import "swiper/css/navigation";

import {NotFoundPage} from "./NotFoundPage";
import ThumbVideo from "../components/ThumbVideo";
import MuxPlayer from "../components/MuxPlayer";
import PromoSlide from "../components/PromoSlide";
import MembershipInfo from "../components/MemebershipInfo";
//import MemberButtonSerie from "../components/Buttons/MemberButtonSerie";

export function SeriesPage() {
	//const [modalOn, setModalOn] = useState(false);
	const {slug} = useParams();
	const isMobile = useMediaQuery({query: "(max-width: 767px)"});

	const serieChapter = useSelector(getResultSerieChapterSelector);
	const userInfo = useSelector(getResultUserInfoSelector);
	const user = useSelector(getResultUserLoginSelector);
	const [membership, setMembership] = useState("none");

	let selectedSerie = serieChapter.find(a => a.slug === slug);

	useEffect(() => {
		user?.login && selectedSerie && findUserMember();
	}, [userInfo]);

	const findUserMember = () => {
		const selectedMember = userInfo?.find(e => e.serie === selectedSerie?.id);
		if (selectedMember) setMembership(selectedMember.membership);
		else setMembership("none");
	};

	if (selectedSerie === null || selectedSerie === undefined) {
		return <NotFoundPage />;
	}

	/*Crear link a compartir*/
	const shareLink = `series/${slug}`;
	const shareTitle = selectedSerie?.title;

	const cast = JSON.parse(selectedSerie?.info_cast);
	const credit = JSON.parse(selectedSerie?.info_credit);

	const chaptersFree = selectedSerie?.chapterFree;
	const chapterExclusive = selectedSerie?.chapterExclusive.filter(ch => ch.type !== "preview");

	const breakPoints = {
		640: {
			slidesPerView: 2.5,
			spaceBetween: 15,
			navigation: " ",
		},
		768: {
			slidesPerView: 3,
			spaceBetween: 20,
			navigation: " ",
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 25,
		},
		1366: {
			slidesPerView: 4,
			spaceBetween: 25,
		},
		1920: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
	};

	/*const childToParent = () => {
    setModalOn(true);
  };*/
	/*const elemSerie = {
    serieId: selectedSerie.id,
    serieTitle: selectedSerie.title,
    serieThumbs: selectedSerie.img_thumbsnail,
    serieSlug: selectedSerie.slug,
    serieLogo: selectedSerie.img_logo,
    serieMemberTitle: selectedSerie.membership_title,
    serieMemberUser: membership,
  };*/
	//console.log(selectedSerie.chapter, membership)
	//console.log(">>>", selectedSerie);	
  
	return (
		<>
			<div className="mx-auto max-w-screen-2xl pb-5 md:flex md:pb-10">
				<div className="container px-3 md:px-5 md:pt-2">
					<div className="container-series relative mx-auto max-w-6xl">
						<h1 className="py-4 text-xl font-bold uppercase md:hidden md:text-3xl">{selectedSerie.title}</h1>
						<div className="container-video relative">
							<MuxPlayer
								className="w-full"
								source={"https://stream.mux.com/" + selectedSerie.video_mux_id + ".m3u8"}
								autoPlay={true}
								controls={true}
							/>
							<MembershipInfo SelectedSerie={selectedSerie} membership={membership} className="hidden md:flex" />
						</div>
						<div className="actions-buttons flex flex-col justify-between pt-2 lg:flex-row lg:pt-5">
							<h1 className="mb-2 hidden text-xl font-bold uppercase md:block md:text-2xl lg:mb-0 xl:text-3xl">
								{selectedSerie.title}
							</h1>
							<div className="block md:flex">
								<PlayNowButton link={`/series/${selectedSerie.slug}/chapter/1`} />
								<div className="flex justify-center">
									<div className="loading-button-section md:ml-3">
										<LikeButton itemId={selectedSerie.id} elem={"serie"} />
									</div>
									<div className="loading-button-section ml-3">
										<FavButton itemId={selectedSerie.id} elem={"serie"} />
									</div>
									<div className="ml-3">
										<ShareButton shareLink={shareLink} shareTitle={shareTitle} />
									</div>
									{/* <div className="ml-3 md:hidden flex items-center">
                    <MemberButtonSerie
                      selectedSerie={selectedSerie}
                      childToParent={childToParent}
                      className="px-2 font-sm-buttons"
                    />
                  </div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="default-page container px-3 pb-5 md:px-5">
				<div className="container-series md:pt-5">
					<div className="container-capitulo mb-4">
						<h3 className="border-title pt-4 text-xl font-bold uppercase md:text-2xl">Capítulos</h3>
						<Swiper
							slidesPerView={1.5}
							spaceBetween={10}
							navigation={!isMobile}
							pagination={{clickable: true}}
							breakpoints={breakPoints}
							modules={[Navigation]}
						>
							{chaptersFree?.map((item, index) => (
								<SwiperSlide key={index}>
									<div className="w-full pt-4 pr-0 sm:pr-3 md:pt-5 md:pr-4">
										<ThumbVideo
											background={item.img_web}
											logo={selectedSerie.img_logo}
											link={`/series/${selectedSerie.slug}/${item.type}/${item.chapter}`}
											chapterMembership={item.membership}
											userMembership={membership}
										/>
										<div className="slide-title">
											<span className="title-play hidden">
												<ion-icon name="play"></ion-icon>
											</span>
											<div className="text-left">
												{/* <span className="mr-2">{item.title}</span> */}
												<span className="font-bold">{item.title}</span>
												<p className="mt-2 text-xs">{item.long_description}</p>
											</div>
										</div>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					{chapterExclusive?.length > 0 && (
						<div className="container-capitulos mb-8">
							<h3 className="border-title pt-4 text-xl font-bold uppercase md:text-2xl">Exclusivos</h3>
							<Swiper slidesPerView={1.5} spaceBetween={10} navigation={!isMobile} breakpoints={breakPoints} modules={[Navigation]}>
								{chapterExclusive?.map((item, index) => (
									<SwiperSlide key={index}>
										<div className="w-full pt-4 pr-0 sm:pr-3 md:pt-5 md:pr-4">
											<ThumbVideo
												background={item.img_web}
												logo={selectedSerie.img_logo}
												link={`/series/${selectedSerie.slug}/content/${item.chapter}`}
												chapterMembership={item.membership}
												userMembership={membership}
											/>
											<div className="slide-title">
												<span className="title-play hidden">
													<ion-icon name="play"></ion-icon>
												</span>
												<div className="w-full text-left">
													<span className="font-bold">{item.title}</span>
													<div className="mt-2 text-xs">{item.long_description}</div>
												</div>
											</div>
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					)}
				</div>
			</div>
			<PromoSlide />
			<div className="default-page container px-3 pb-5 md:px-5">
				<div>
					<div className="container-creditos flex flex-col pt-5 text-center md:flex-row md:pt-8 md:text-left">
						<div className="order-1 mb-4 w-full md:mb-0 md:w-1/3">
							<div className="max-w-full md:max-w-[260px]">
								<h3 className="border-title mx-auto mb-3 text-xl font-bold uppercase md:mx-0">Sinopsis</h3>
								<p>{selectedSerie.description}</p>
								<div className="play-button mt-6 hidden w-full md:mr-3 md:block md:w-auto">
									<PlayNowButton link={`/series/${selectedSerie.slug}/chapter/1`} />
								</div>
							</div>
						</div>
						<div className="order-3 flex w-full flex-col md:order-2 md:w-1/3">
							<h3 className="border-title mx-auto mb-3 text-xl font-bold uppercase md:mx-0">Créditos</h3>
							<div className="wrap-section-cred flex flex-col">
								{credit.credit.map((elem, key) => {
									if (
										elem.categ === "Dirección" ||
										elem.categ === "Producción General" ||
										elem.categ === "Productores Ejecutivos" ||
										elem.categ === "Guión" ||
										elem.categ === "Una producción de"
									)
										return (
											<div className="mb-2" key={key}>
												<div className="font-bold">{elem.categ}</div>
												{elem.pers.map((item, index) => {
													return <div key={index}>{item.name}</div>;
												})}
											</div>
										);
								})}
							</div>
						</div>
						<div className="creditos-images order-2 mb-4 w-full md:order-3 md:mb-0 md:w-1/3">
							<h3 className="border-title mx-auto mb-3 text-xl font-bold uppercase md:mx-0">Elenco Principal</h3>
							{cast.cast.map((item, index) => (
								<div className="mb-2" key={index}>
									<div className="flex md:flex-row flex-col items-start">
										<div className="md:w-1/3 w-full">
											<img className="mx-auto mb-1 md:mx-0" src={item.img} alt="elenco" />
											<div className="mb-2 font-bold">{item.name}</div>
										</div>
										<div className="md:w-2/3 w-full md:ml-3">
											<div>{item.descrip}</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
