import { auth } from "../firebase";

export async function getUserPhotoEditInsert(
  uid: string,
  query: any
): Promise<any> {
  const { file } = query;

  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("uid", uid);

    try {
      auth.onAuthStateChanged((user) => {
        if (user) {
          user
            .getIdToken()
            .then(function (idToken) {
              // Default options are marked with *
              fetch(process.env.REACT_APP_FIREBASE_FUNCTIONS + "/userUploadPhoto", {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  //"content-type": "multipart/form-data",
                  accept: "application/json",
                },
                body: formData,
              })
                .then((response) => response.json())
                .then((response) => {
                  //console.log("RESP:", response);
                  resolve(response);
                })
                .catch((error) => reject(error));
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    } catch (error) {
      console.log({ error, where: "api" });

      reject(error);
    }
  });
}
