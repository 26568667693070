import { combineReducers } from "redux";

import serieChapterReducer from "../serieChapter/reducer";
import userLoginReducer from "../userLogin/reducer";
import userEditReducer from "../userEdit/reducer";
import userInfoReducer from "../userInfo/reducer";
import contactReducer from "../contact/reducer";
import userInfoEditReducer from "../userInfoEdit/reducer";
import userPhotoEditReducer from "../userPhotoEdit/reducer"
import slideReducer from "../slide/reducer";
import coinValueReducer from "../coinValue/reducer";
import commentReducer from "../comment/reducer";
import commentEditReducer from "../commentEdit/reducer";
import emojiReducer from "../emoji/reducer";
import socialReducer from "../social/reducer";
import muxReducer from "../muxPlayer/reducer";

const rootReducer = combineReducers({
  serieChapter: serieChapterReducer,
  userLogin: userLoginReducer,
  userEdit: userEditReducer,
  userInfo: userInfoReducer,
  contact: contactReducer,
  userInfoEdit: userInfoEditReducer,
  userPhotoEdit: userPhotoEditReducer,
  slide: slideReducer,
  coinValue: coinValueReducer,
  comment: commentReducer,
  commentEdit: commentEditReducer,
  emoji: emojiReducer,
  social: socialReducer,
  mux: muxReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
