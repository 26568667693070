import { createSelector } from "reselect";

import { AppState } from "../_root/rootReducer";

const getLoading = (state: AppState) => state.serieChapter.loading;

const getResult = (state: AppState) => state.serieChapter.result;

const getError = (state: AppState) => state.serieChapter.error;

export const getResultSerieChapterSelector = createSelector(getResult, (result) => result);

export const getLoadingSerieChapterSelector = createSelector(
  getLoading,
  (loading) => loading
);

export const getErrorSerieChapterSelector = createSelector(getError, (error) => error);