const ThumbSerie = ({ background, logo, title, label = true }) => {
  return (
    <div className="thumb-serie">
      <div className="relative">
        <img className="thumb-background" src={background} alt={title} />
        {/* {label && <div className="label-free">Gratis</div>} */}
        <div className="slide-logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default ThumbSerie;
