import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ThumbVideo from "../components/ThumbVideo";
import ThumbSerie from "../components/ThumbSerie";
import { useSelector } from "react-redux";
import { getResultSerieChapterSelector } from "../_store/serieChapter/selectors";
import { getResultUserLoginSelector } from "../_store/userLogin/selectors";
import { getResultUserInfoSelector } from "../_store/userInfo/selectors";

export function FavoritesPage() {
  const [dataSeries, setDataSeries] = useState([]);
  const [dataChapters, setDataChapter] = useState([]);

  const serieChapter = useSelector(getResultSerieChapterSelector);
  const user = useSelector(getResultUserLoginSelector);
  const userInfo = useSelector(getResultUserInfoSelector);

  const findUserMember = (selectedSerie) => {
    const selectedMember = userInfo?.find((e) => e.serie === selectedSerie?.id);
    if (selectedMember) return selectedMember.membership;
    return "none";
  };

  serieChapter.forEach((itemSerie) => {
    itemSerie.memberUserSerie = findUserMember(itemSerie);
  });

  const fetchData = () => {
    let elemSeries;
    elemSeries = [];
    let elemChapters;
    elemChapters = [];

    userInfo.forEach((item, i) => {
      if (item.favorite === true) {
        if (item.serie !== undefined) {
          //SELECT SERIE
          const selectedSerie = serieChapter.find((a) => a.id === item.serie);
          elemSeries.push(selectedSerie);
        } else if (item.chapter !== undefined) {
          //SELECT CHAPTER
          for (let serie of serieChapter) {
            const selectedChapter = serie.chapter.find(
              (a) => a.id === item.chapter
            );
            if (selectedChapter) {
              elemChapters.push({
                ...selectedChapter,
                serieTitle: serie.title,
                serieLogo: serie.img_logo,
                serieSlug: serie.slug,
                memberUserSerie: serie.memberUserSerie,
              });
              break;
            }
          }
        }
      }
    });

    setDataSeries(elemSeries);
    setDataChapter(elemChapters);
  };

  useEffect(() => {
    if (user && userInfo && serieChapter) fetchData();
  }, [userInfo]);

  return (
    <div className="default-page container md:py-10 py-5 md:px-5 px-3">
      {user && userInfo && serieChapter ? (
        <>
          <h1 className="text-xl md:text-3xl">Tus Series Favoritas</h1>
          <div className="container-fav-series border-b pb-4 md:pb-8">
            <div className="grid md:grid-cols-3 grid-cols-2">
              {dataSeries.length > 0 ? (
                <>
                  {dataSeries.map((item) => (
                    <div
                      className="wrap-estrenos pt-4 md:pt-5 pr-2 md:pr-4"
                      key={item.id}
                    >
                      <Link to={"/series/" + item.slug}>
                        <ThumbSerie
                          background={item.img_thumbsnail}
                          logo={item.img_logo}
                          title={item.title}
                        />
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <div>No existen series seleccionadas.</div>
              )}
            </div>
          </div>
          <h2 className="text-xl md:text-3xl pt-3 md:pt-3">
            Tus Capítulos Favoritos
          </h2>
          <div className="container-fav-capitulos">
            <div className="grid md:grid-cols-3 grid-cols-2">
              {dataChapters.length > 0 ? (
                <>
                  {dataChapters.map((item, index) => (
                    <div
                      className="wrap-estrenos pt-4 md:pt-5 pr-2 md:pr-4"
                      key={index}
                    >
                      <ThumbVideo
                        background={item.img_web}
                        logo={item.serieLogo}
                        link={`/series/${item.serieSlug}/${item.type}/${item.chapter}`}
                        chapterMembership={item.membership}
                        userMembership={item.memberUserSerie}
                        premiere={item.premiere}
                      />
                      <div className="slide-title md:text-base text-xs">
                        <span className="hidden title-play">
                          <ion-icon name="play"></ion-icon>
                        </span>
                        <div>
                          <div>{item.serieTitle}</div>
                          <div>{item.title}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div>No existen capítulos seleccionados.</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-xl md:text-3xl">Favoritos</h1>
          <div className="md:py-10 py-8 flex items-center flex-col md:flex-row">
            <div className="md:w-1/2 text-center md:text-left">
              Debe autenticarse para adicionar sus videos favoritos.
            </div>
            <div className="actions-buttons flex md:flex-row flex-col mt-5 md:mt-0">
              <Link
                to={"/login"}
                className="btn-default-b-red md:mr-5 mb-4 md:mb-0"
              >
                <span>Iniciar Sesión</span>
              </Link>
              <Link to={"/registrar"} className="btn-default-b-red">
                <span>Registrarse</span>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
