import {
  MUX_CREATE_URL_FAILURE,
  MUX_CREATE_URL_REQUEST,
  MUX_CREATE_URL_SUCCESS,
} from './actionTypes';
import {
  MuxCreateUrlFailure,
  MuxCreateUrlRequest,
  MuxCreateUrlSuccess,
} from './types';

export const createMuxUrl = (item: any): MuxCreateUrlRequest => ({
  item,
  type: MUX_CREATE_URL_REQUEST
})

export const createMuxUrlSuccess = (url: string): MuxCreateUrlSuccess => ({
  type: MUX_CREATE_URL_SUCCESS,
  url
})

export const createMuxUrlError = (error: string): MuxCreateUrlFailure => ({
  error,
  type: MUX_CREATE_URL_FAILURE
})