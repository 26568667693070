import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getResultSerieChapterSelector } from "../_store/serieChapter/selectors";
import { getResultUserInfoSelector } from "../_store/userInfo/selectors";
import { useSelector } from "react-redux";
import ModalMembership from "../components/Modals/ModalMembership";
import ThumbSerie from "../components/ThumbSerie";
import UnirseButtons from "../components/Buttons/UnirseButtons";

export function MembershipPage() {
  const serieChapter = useSelector(getResultSerieChapterSelector);
  const userInfo = useSelector(getResultUserInfoSelector);

  const [modalOn, setModalOn] = useState(false);
  const [dataSeries, setDataSeries] = useState([]);
  const [dataMember, setDataMember] = useState([]);
  const [pos, setPos] = useState(0);

  useEffect(() => {
    const fetchData = () => {
      let elemSeries;
      elemSeries = [];
      let elemMember;
      elemMember = [];
      serieChapter.forEach((serie, i) => {
        const selectedSerie = userInfo.find((a) => a.serie === serie.id);
        if (
          selectedSerie === undefined || selectedSerie.membership === undefined ||
          selectedSerie.membership === ""
        ) {
          elemSeries.push({
            serieId: serie.id,
            serieTitle: serie.title,
            serieThumbs: serie.img_thumbsnail,
            serieThumbsCuadrada: serie.img_thumbsnail_cuadrada,
            serieSlug: serie.slug,
            serieLogo: serie.img_logo,   
            serieMemberTitle: serie.membership_title,
            serieMemberUser : "",                
            //chapterMembership: selectedChapter?.membership,
          });
        } else {
          elemMember.push({
            serieId: serie.id,
            serieTitle: serie.title,
            serieThumbs: serie.img_thumbsnail,
            serieSlug: serie.slug,
            serieLogo: serie.img_logo,          
            serieMember: selectedSerie.membership,
            serieMemberTitle: serie.membership_title
          });
        }
      });
  
      setDataSeries(elemSeries);
      setDataMember(elemMember);
    };
    if (userInfo) fetchData();
  }, [userInfo]);

  const clicked = ({ index }) => {
    setPos(index);
    setModalOn(true);
  };

  return (
    <>
      <div className="default-page container md:py-10 py-5 md:px-5 px-3 flex md:flex-row flex-col">
        <div className="md:w-1/3 w-full">
          {dataMember.length > 0 ? (
            <>
              <h1 className="text-xl md:text-3xl">Mis Membresías</h1>
              {dataMember.map((serie, index) => (
                <div className="flex flex-col justify-evenly text-sm membership-content pb-3 border-b-2" key={index}>
                  {serie.serieMember === "simple" && (
                    <div className="p-3 pb-0">
                      <div className="section-title flex justify-between mb-2">
                        <div className="text-lg leading-6 flex items-center">
                          <div className="flex mr-3 text-sky-700 text-2xl">
                            <ion-icon name="checkbox"></ion-icon>
                          </div>
                          <div>
                            <div>Miembro</div>
                            <div>{serie.serieTitle}</div>
                          </div>
                        </div>
                      </div>
                      <ul className="leading-6">
                        <li>
                          <span className="relative top-0.5">
                            <ion-icon name="ellipse"></ion-icon>
                          </span>{" "}
                          Mira todos los videos sin publicidad.
                        </li>
                        <li>
                          <span className="relative top-0.5">
                            <ion-icon name="ellipse"></ion-icon>
                          </span>{" "}
                          Tendrás acceso a contenidos exclusivos de la serie.
                        </li>
                        <li>
                          <span className="relative top-0.5">
                            <ion-icon name="ellipse"></ion-icon>
                          </span>{" "}
                          Tendrás acceso a stickers personalizados de la serie.
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="p-3 bg-black mt-3">
                    {serie.serieMember === "vip" ? (
                      <div className="section-title flex justify-between mb-2">
                        <div className="text-lg leading-6 flex items-center">
                          <div className="flex mr-3 text-sky-700 text-2xl">
                            <ion-icon name="checkbox"></ion-icon>
                          </div>
                          <div>
                            <div>Miembro VIP</div>
                            <div>{serie.serieTitle}</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="section-title flex justify-between mb-2">
                        <div className="text-lg leading-6">
                          <p>
                            Puedes convertirte en miembro VIP de{" "}
                            {serie.serieTitle}
                          </p>
                        </div>
                        <div className="w-28">
                          <div className="text-center font-bold w-28 mb-1 text-lg">
                            $5.00 USD
                          </div>
                          <UnirseButtons 
                            text="Upgrade"
                            MembershipType="vip"
                            SerieId = {serie.serieId}
                            className ="w-full"
                          /> 
                        </div>
                      </div>
                    )}
                    <ul className="leading-6">
                      <li>
                        <span className="relative top-0.5">
                          <ion-icon name="ellipse"></ion-icon>
                        </span>{" "}
                        Mira todos los videos sin publicidad.
                      </li>
                      <li>
                        <span className="relative top-0.5">
                          <ion-icon name="ellipse"></ion-icon>
                        </span>{" "}
                        Tendrás acceso a un grupo privado con el elenco de la
                        serie.
                      </li>
                      <li>
                        <span className="relative top-0.5">
                          <ion-icon name="ellipse"></ion-icon>
                        </span>{" "}
                        Tendrás acceso a contenidos exclusivos de la serie.
                      </li>
                      <li>
                        <span className="relative top-0.5">
                          <ion-icon name="ellipse"></ion-icon>
                        </span>{" "}
                        Tendrás acceso a stickers personalizados de la serie.
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <h1 className="text-xl">Membresías</h1>
              <p className="w-full md:mb-0 mb-3">
                Elije tu serie favorita y vuelvete miembro de una de nuestras
                comunidades. ¡Disfruta de contenidos exclusivos!
              </p>
            </>
          )}
        </div>
        <div className="md:w-2/3 md:pl-12 mt-3 md:mt-0">
          <div className="container-member">
            {dataMember.length > 0 && (
              <>
                <h1 className="text-xl md:text-3xl mb-3">Otras Membresías</h1>
                <p className="w-full mb-2">
                  Elije tu serie favorita y vuélvete miembro de una nuestras
                  comunidades. ¡Disfruta de contenidos exclusivos!
                </p>
              </>
            )}
            {dataSeries.length > 0 ? (
              <div className="grid md:grid-cols-2 grid-cols-2 max-w-2xl">
                {dataSeries.map((item, index) => (
                  <div className="px-2 mb-5" key={index}>
                    <Link to={"/series/" + item.serieSlug}>
                      <ThumbSerie
                        background={item.serieThumbsCuadrada}
                        logo={item.serieLogo}
                        title={item.serieTitle}
                        label={false}
                      />
                    </Link>
                    <div className="slide-title md:text-base text-xs justify-between lg:flex-row flex-col">
                      <div className="lg:mb-0 mb-2 w-full lg:text-left text-center">
                        <div className="lg:text-xs">{item.serieTitle}</div>
                      </div>
                      <div className="w-full flex justify-end">
                        <button
                          className="w-full lg:w-auto bg-blue-600 hover:bg-sky-400 uppercase font-bold text-sm text-white py-2 md:py-2 px-2 rounded duration-500"
                          onClick={() => clicked({ index })}
                        >
                          Ver Membresías
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>Ya tiene todas las membresias.</div>
            )}
          </div>
        </div>
        <div></div>
      </div>
      {modalOn && (
        <ModalMembership setModalOn={setModalOn} serie={dataSeries[pos]} />
      )}
    </>
  );
}
