import {
    FETCH_USER_PHOTO_EDIT_REQUEST,
    FETCH_USER_PHOTO_EDIT_FAILURE,
    FETCH_USER_PHOTO_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchUserPhotoEditRequest,
    FetchUserPhotoEditSuccess,
    FetchUserPhotoEditSuccessPayload,
    FetchUserPhotoEditFailure,
    FetchUserPhotoEditFailurePayload,
  } from "./types";
  
  export const fetchUserPhotoEditRequest = (
    query: any
  ): FetchUserPhotoEditRequest => ({
    type: FETCH_USER_PHOTO_EDIT_REQUEST,
    query,
  });
  
  export const fetchUserPhotoEditSuccess = (
    payload: FetchUserPhotoEditSuccessPayload
  ): FetchUserPhotoEditSuccess => ({
    type: FETCH_USER_PHOTO_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchUserPhotoEditFailure = (
    payload: FetchUserPhotoEditFailurePayload
  ): FetchUserPhotoEditFailure => ({
    type: FETCH_USER_PHOTO_EDIT_FAILURE,
    payload,
  });