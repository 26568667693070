import { useState, useEffect } from "react";
import { getResultUserLoginSelector } from "../../_store/userLogin/selectors";
import { getResultUserInfoSelector } from "../../_store/userInfo/selectors";
import { useDispatch, useSelector } from "react-redux";

import {
  getLoadingUserInfoEditSelector,
  getResultUserInfoEditSelector,
  getErrorUserInfoEditSelector,
} from "../../_store/userInfoEdit/selectors";
import { fetchUserInfoEditRequest } from "../../_store/userInfoEdit/actions";

import SpinningLoading from "../Spinning/SpinningLoading";
import { toast } from "react-toastify";

const NotifyButton = ({ itemId, elem }) => {

    const dispatch = useDispatch();
    const [active, setActive] = useState(false);
    const [send, setSend] = useState(false);
  
    const user = useSelector(getResultUserLoginSelector);
    const userInfo = useSelector(getResultUserInfoSelector);
    const loading = useSelector(getLoadingUserInfoEditSelector);
    const error = useSelector(getErrorUserInfoEditSelector);
    const result = useSelector(getResultUserInfoEditSelector);
  
    useEffect(() => {
      user?.login && findActive();
    }, [userInfo]);
  
    function findActive() {
        userInfo.map((item, i) => {
            if ( item.chapter == itemId) {
                setActive(item.notify);
            }
        });
    }
  
    function handleClick() {
        if (!user?.login) {
            toast.warning("Debe iniciar sesión para interactuar");
            return false;
        }
        dispatch(
            fetchUserInfoEditRequest({
            id: itemId,
            type: elem,
            field: {"notify": !active}
            })
        );
        setSend(true);
    }
  
    useEffect(() => {
        setSend(false);
    }, [result, error]);

  return (
    <button
        className="flex flex-col items-center"
        onClick={() => handleClick()}
        >
        { loading && send ? <SpinningLoading /> 
            : 
            <>
                {active ? <ion-icon name="notifications"></ion-icon> : <ion-icon name="notifications-outline"></ion-icon>}
                <span>Avísame</span>
            </>
        }
    </button>
  );
};

export default NotifyButton;
