import { Link } from "react-router-dom";

const NextButtons = ({ link, title, action = ()=>{} }) => {
  return (
    <Link to={link} onClick={action}>
      <button className="flex p-auto font-medium">
        {title === "Anterior" ?
          <>
            <span className="mr-2">Ant.</span>
            <ion-icon name="play-skip-back-outline" style={{margin:"auto"}}></ion-icon>    
          </>
          :
          <>
            <ion-icon name="play-skip-forward-outline" style={{margin:"auto"}}></ion-icon>
            <span className="ml-2">Sig.</span>
          </>
        }
      </button>
    </Link>
  );
};

export default NextButtons;
