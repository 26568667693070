import db from '../_root/apiBridge';
import { timestamp } from '../_root/apiBridge';

import { getAuth, createUserWithEmailAndPassword, EmailAuthProvider, linkWithCredential } from "firebase/auth";

import { FieldValue, auth } from '../firebase';

const createUser = (uid, email, name, phone) => db.collection('users').doc(uid).set({
  email,
  name,
  phone: phone??'',
  audit_created: timestamp.fromDate(new Date()),
  deviceToken: '',
})

export async function getUserEditInsert(query:any): Promise<any> {
  const {
    email,
    name,
    phone,
  } = query;

  return new Promise((resolve, reject) => {
   

    try {
      let user = auth.currentUser;

      if (user.isAnonymous) {
        const credentials = EmailAuthProvider.credential(query.email, query.password);

        linkWithCredential(user, credentials)
          .then((currentUser) => {
            createUser(currentUser.user.uid, email, name, phone)
              .then(() => {
                resolve({
                  uid: currentUser.user.uid,
                  email, 
                })
              })
              .catch(error => {
                reject(error);
              });
          })
      } else {
        createUserWithEmailAndPassword(getAuth(), query.email, query.password)
        //auth().createUserWithEmailAndPassword(query.email, query.password) // create
          .then((currentUser) => {
            createUser(currentUser.user.uid, email, name, phone).then(()=>{
                resolve({
                  uid: currentUser.user.uid,
                  email,        
                });
              });
  
  
          }).catch(error => {
            
            reject(error);
  
          });
      }
    } catch (error) {
      reject(error);
    }


  });

}
interface UpdateUserQuery {
  name?: string;
  phone?: string;
  photoToken?: string;
  email?: string;
}

export async function getUserEditUpdate(
  uid: string,
  query: UpdateUserQuery,
): Promise<{uid: string}> {
  const {name, phone, photoToken, email} = query;

  let objField: any = {
    audit_updated: FieldValue.serverTimestamp()
  };

  if (name !== undefined || phone !== undefined) {
    objField.name = name ?? FieldValue.delete();
    objField.phone = phone ?? FieldValue.delete();
  }

  if (photoToken === '') {
    objField.photo_token = '';
  }

  if (email) {
    const user = auth.currentUser;
    if (user) {
      await user.updateEmail(email);
    }

    objField.email = email;
  }

  await db.collection('users').doc(uid).update(objField);

  return {uid};
}

export async function getUserEditRs(query:any): Promise<any> {


  const {
    uid,
    email,
    name,
    provider
  } = query;

  return new Promise((resolve, reject) => {
   

    try {

      db.collection('users')
        .doc(uid)
        .get()
        .then((documentSnapshot) => {
          if (documentSnapshot.exists) {
              
              resolve({
                uid,
                email        
              });

          } else {

            db.collection('users').doc(uid)
            .set({
              email,
              name,
              phone: '',
              provider,
              deviceToken: '',
              audit_created: timestamp.fromDate(new Date()),              
            }).then(()=>{
              resolve({
                uid,
                email          
              });
            });

          }
        })


    } catch (error) {
      reject(error);
    }


  });

}