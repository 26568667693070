import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";

interface SliderProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}
export function Slider(props: SliderProps) {
  return (
    <input
      type="range"
      className={
        "w-full disabled:cursor-not-allowed accent-palenque-red-contrast"
      }
      {...props}
    />
  );
}
