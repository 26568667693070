import db from '../_root/apiBridge';
import { timestamp } from '../_root/apiBridge';

export async function getUserInfoEdit(uid: string, query: any): Promise<any> {


  return new Promise((resolve, reject) => {

    //console.log('field userInfoEdit', JSON.stringify(query.field, null, 8));





    db
      .collection('user_info')
      .where(query.type, '==', query.id)
      .where('user', '==', uid)
      .limit(1)
      .get()
      .then(snapshot => {

        let objField: any = {
          audit_updated: timestamp.fromDate(new Date()),
        }



        if (snapshot.size === 0) {

          objField = {
            ...objField,
            user: uid,
            like: false,
            favorite: false,
            notify: false,
            tracking_full: false,
            tracking_time: 0,
            //tracking_time_full: "",
            audit_created: timestamp.fromDate(new Date()),
          }



          if (query.type == 'chapter') {
            objField = { ...objField, chapter: query.id };
          }
          if (query.type == 'serie') {
            objField = { ...objField, serie: query.id };
          }

          if (query.field) {
            objField = { ...objField, ...query.field };
          }


          if (query.field.super_like && query.field.super_like != '') {
            objField = { ...objField, super_like: getValueSuperLike(query.field.super_like) };
          }


          db.collection('user_info')
            .add(objField)
            .then(function (refDoc) {
              console.log('insert --->', refDoc.id);
              resolve({ id: refDoc.id });
            })


        }


        snapshot.forEach(function (doc) {

          if (query.field) {
            objField = { ...objField, ...query.field };
          }

          if (query.field.super_like && query.field.super_like != '') {
            objField = { ...objField, super_like: getValueSuperLike(query.field.super_like, (doc.data().super_like && doc.data().super_like != '') ? doc.data().super_like : '') };
          }
          //console.log('doc.super_like', doc.data().super_like, doc.id);
          console.log('doc id user_info', doc.id);

          doc.ref.update(objField).then(() => {
            console.log('update --->', doc.id);
            resolve({ id: doc.id });
          })
        });



      }).catch((error) => {
        console.log(error);
        reject(error);
      });






  });
}


function getValueSuperLike(newValue: string, oldValue: string = '') {



  if (oldValue != '') {
    var obj = JSON.parse(oldValue);
    let exist: boolean = false;

    obj.map((item, i) => {
      if (item.value == newValue) {
        exist = true;
      }
    });

    if (!exist) {
      obj.push({
        value: newValue,
        time: new Date().getTime()
      });

    }

    obj.sort((a, b) => b.value - a.value);

    return (JSON.stringify(obj));

  } else {
    let r: any = [];
    r.push({
      value: newValue,
      time: new Date().getTime()
    });
    return (JSON.stringify(r));
  }




}



export async function setLikeChapter(chapterId: string, increment: boolean): Promise<any> {


  return new Promise((resolve, reject) => {
/*
    let ref = db.collection('chapters').doc(chapterId);

    ref.update({
      population: FieldValue.increment(1)
  });
  */


    db
      .collection('chapters')
      .doc(chapterId)
      .get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {

          let data = documentSnapshot.data();
          console.log('chapter----------->', data.total_likes, documentSnapshot.id );
          let total_like = data.total_likes;
          if (increment) {
            ++total_like;
          } else {
            --total_like;
          }


          
          db.collection('chapters').doc(documentSnapshot.id)
          .update({
            total_likes: total_like,
          })
          .then(function () {
            resolve([])
          })


        } 

      }).catch((error) => {
        reject(error);
      });




  });
}