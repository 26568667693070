import { all, call, put, takeLatest } from "redux-saga/effects";

import { fetchCoinValueFailure, fetchCoinValueSuccess } from "./actions";
import { FETCH_COIN_VALUE_REQUEST } from "./actionTypes";

import { getCoinValue } from "./api";

export function* fetchCoinValueSaga(): any {
  try {
    let response: any;

    response = yield call(getCoinValue);
    console.log(JSON.stringify(response));

    yield put(
      fetchCoinValueSuccess({
        result: response,
      })
    );
  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    } else {
      errorMessage = error.toString();
    }
    yield put(fetchCoinValueFailure({ error: error }));
  }
}

function* CoinValueSaga() {
  yield all([takeLatest(FETCH_COIN_VALUE_REQUEST, fetchCoinValueSaga)]);
}

export default CoinValueSaga;
