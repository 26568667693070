const PhotoUser = (props) => {
  const { photoToken = "", uid = "", size = "", className="" } = props;
  const url_img = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_PROJECTID}-user/o/${uid}%2Fuser-photo?alt=media&token=${photoToken}`;

  return (
    <div
      style={{ width: size, height: size }}
      className={`flex justify-center items-center ${className}`}
    >
      {photoToken ? (
        <>
          <img
            style={{ padding: "5%" }}
            className="w-full h-full rounded-full object-cover"
            src={url_img}
            alt="user-avatar"
          />
        </>
      ) : (
        <div style={{ fontSize: `${size / 16}rem` }} className="flex">
          <ion-icon name="person-circle-outline" />
        </div>
      )}
    </div>
  );
};

export default PhotoUser;
