import { useState } from "react";
import UnirseButtons from "./Buttons/UnirseButtons";

const MembershipInfo = (props) => {
  const { SelectedSerie, className, membership } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="absolute flex items-center top-0 right-0 mb-16 max-w-lg">
      {membership !== "vip" && (
        <>
          {!open ? (
            <button
              onClick={() => setOpen(!open)}
              className="h-10 mt-4 mr-5 hidden lg:block bg-blue-600 uppercase font-bold text-white py-2 px-5 rounded hover:bg-sky-400 duration-500"
            >
              Suscribirse
            </button>
          ) : (
            <button
              onClick={() => setOpen(!open)}
              className="py-4 my-auto text-white hidden rounded-l-lg lg:flex bg-black/[0.9]"
            >
              <ion-icon name="chevron-back-outline" size="large"></ion-icon>
            </button>
          )}
        </>
      )}
      {open && (
        <div
          className={`py-3 px-4 lg:pr-5 bg-black/[0.9] rounded-bl-lg ${className}`}
        >
          <div className="mx-auto">
            <div className="border-white border-b-2 py-2">
              <h1 className="text-xl uppercase font-bold">Membresías</h1>
            </div>
            {membership !== "simple" && membership !== "vip" && (
              <div className="border-white border-b-2 py-2">
                <h1 className="uppercase font-bold">
                  {SelectedSerie?.membership_title}
                </h1>
                <h1 className="font-bold mb-2">$4.99 USD</h1>
                <UnirseButtons
                  text={`Unirse a ${SelectedSerie?.membership_title}`}
                  SerieId={SelectedSerie.id}
                />
                <ul className="my-2 ml-5 list-disc">
                  <li>Mira todos los videos sin publicidad.</li>
                  <li>Tendrás acceso a contenidos exclusivos de la serie.</li>
                  <li>Tendrás acceso a stickers personalizados de la serie.</li>
                </ul>
              </div>
            )}
            <div className="border-white border-b-2 py-2">
              <h1 className="uppercase font-bold">
                {SelectedSerie?.membership_title} VIP
              </h1>
              <h1 className="font-bold mb-2">{membership === "simple" ? (<>$5.00</>):(<>$9.99</>)} USD</h1>
              {membership !== "vip" && (
                <UnirseButtons
                  text={`Unirse a ${SelectedSerie?.membership_title}`}
                  MembershipType="vip"
                  SerieId={SelectedSerie.id}
                />
              )}
              <ul className="my-2 ml-5 list-disc">
                <li>Mira todos los videos sin publicidad.</li>
                <li>
                  Tendrás acceso a un grupo privado con el elenco de la serie.
                </li>
                <li>Tendrás acceso a contenidos exclusivos de la serie.</li>
                <li>Tendrás acceso a stickers personalizados de la serie.</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MembershipInfo;
