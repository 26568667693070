import MuxPlayer from "../components/MuxPlayer";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import ContactForm from "../components/ContactForm";

const SwiperDefualt = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1366: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
      modules={[Navigation]}
      className=""
    >
      <SwiperSlide>
        <div>
          <img src="/images/sponsors/logo-efigie.png" alt="logo-efigie" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div>
          <img src="/images/sponsors/logo-palenque.png" alt="logo-palenque" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div>
          <img src="/images/sponsors/logo-toritos.png" alt="logo-toritos" />
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export function SponsorsPage() {
  const videoSponsors = {
    autoPlay: false,
    controls: true,
    source:
      "https://stream.mux.com/4bilR4agisCQ02CuHQMEoI2T5Jc00Bh1ZuuGYtzmRRwq00.m3u8",
  };

  const videoBackground = {
    autoPlay: true,
    controls: false,
    loop: true,
    muted: true,
    source:
      "https://stream.mux.com/GAexNgGCGvjRMOY8WaCWQsu5XjOwvyTmEBaNyvnKtEs.m3u8",
  };

  return (
    <div className="container-sponsors">
      <div className="video-wrapper relative">
        <MuxPlayer className="w-full" {...videoBackground} />
        {/* sustituido className > video-background */}
        <div className="wrap-container">
          <div className="container default-page">
            <div className="wrap-form-sponsors">
              <h1 className="text-xl md:text-3xl">
                ¿QUIERES QUE
                <br /> MILES DE PERSONAS
                <br /> CONOZCAN TU MARCA?
              </h1>
              <p>¡PAUTA CON NOSOTROS Y RÓBATE EL SHOW!</p>
              <ContactForm submitText="Contactar"></ContactForm>
            </div>
          </div>
        </div>
      </div>
      <div className="how-do">
        <div className="container default-page flex flex-col lg:flex-row py-8 lg:py-10">
          <div className="video">
            <div className="w-video">
              <MuxPlayer className="w-full mx-auto" {...videoSponsors} />
            </div>
          </div>
          <div className="how-do-section flex items-center flex-col justify-center lg:px-5 px-3">
            <h3 className="text-xl md:text-3xl font-bold">¿CÓMO LO HACEMOS?</h3>
            <div className="how-do-text">
              <div>
                <ion-icon name="play"></ion-icon>
                <span>GRABAMOS CONTENIDOS ENTRETENIDOS Y DE CALIDAD</span>
              </div>
              <div>
                <ion-icon name="play"></ion-icon> HACEMOS QUE TU MARCA APAREZCA
                EN LA HISTORIA
              </div>
              <div>
                <ion-icon name="play"></ion-icon> LOS PUBLICAMOS EN NUESTRA
                PLATAFORMA PARA QUE TODOS LO VEAN
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-two">
        <div className="container default-page flex items-center justify-center h-full md:px-5 px-3">
          <div className="box">
            <div className="text-xl md:text-3xl font-bold">
              ¿QUIÉN VERÁ TU MARCA?
            </div>
            <div className="banner-two-text">
              LA AUDIENCIA DE PALENQUETV ES <br />
              <span>100% LATINA Y CUBANA</span>
              <br />
              ASÍ QUE PODRÁS LLEGAR A ELLOS CON
              <br />
              CONTENIDOS ENTRETENIDOS DONDE
              <br />
              <strong>TU MARCA SEA EL CENTRO DE ATENCIÓN.</strong>
            </div>
            <div className="flex justify-center">
              <button className="btn-default-b-red" onClick={goToTop}>
                <span>CONTÁCTANOS AHORA</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="sponsors-social-media bg-black">
        <div className="container default-page md:py-10 py-8 md:px-5 px-3">
          <h2 className="text-xl md:text-3xl font-bold md:pb-10 pb-5">
            ¿CÓMO PROMOCIONAREMOS LOS CONTENIDOS PARA QUE NADIE SE LOS PIERDA?
          </h2>
          <div className="grid lg:md:grid-cols-4 md:grid-cols-2 grid-cols-1 md:pb-10 pb-5">
            <div className="media-element mb-4">
              <img
                src="/images/seguidores-cubacute.png"
                style={{ paddingTop: 30 }}
                alt="seguidores"
              />
              <div>PLATAFORMA CUBA CUTE</div>
              <div className="fallow">
                +155K <span>SEGUIDORES</span>
              </div>
            </div>
            <div className="media-element mb-4">
              <img src="/images/seguidores-fundichely.png" alt="seguidores" />
              <div>ORLANDO FUNDICHELY</div>
              <div className="fallow">
                326K <span>SEGUIDORES</span>
              </div>
            </div>
            <div className="media-element mb-4">
              <img src="/images/seguidores-albertico.png" alt="seguidores" />
              <div>ALBERTICO PUJOL</div>
              <div className="fallow">
                263K <span>SEGUIDORES</span>
              </div>
            </div>
            <div className="media-element mb-4">
              <img src="/images/seguidores-baby-lores.png" alt="seguidores" />
              <div>BABY LORES</div>
              <div className="fallow">
                200K <span>SEGUIDORES</span>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button className="btn-default-b-red" onClick={goToTop}>
              <span>CONTÁCTANOS AHORA</span>
            </button>
          </div>
        </div>
      </div>
      <div className="sponsors-clients">
        <div className="container default-page md:py-10 py-8">
          <h2 className="text-xl md:text-3xl font-bold md:pb-10 pb-5 text-center">
            NUESTROS SPONSORS
          </h2>
          <div className="md:mb-10 mb-5">
            <SwiperDefualt />
          </div>
          <div className="flex justify-center">
            <button className="btn-default-b-red" onClick={goToTop}>
              <span>CONVIÉRTETE EN SPONSOR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
