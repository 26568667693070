import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { getResultUserLoginSelector } from "../_store/userLogin/selectors";
import { fetchUserLogout } from "../_store/userLogin/actions";

import { getAuth, signOut } from "firebase/auth";

export function LogoutPage() {
  const dispatch = useDispatch();
  const user = useSelector(getResultUserLoginSelector);
  let navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        dispatch(fetchUserLogout());
        toast.info("Ha cerrado sesión correctamente.");
        navigate("/");
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  }, []);

  return (
    <div className="default-page container md:py-10 py-8 md:px-5 px-3">
      <p className="text-center">Sesión cerrada correctamente </p>
    </div>
  );
}
