import { all, fork } from "redux-saga/effects";

import serieChapterSaga from "../serieChapter/sagas";
import userLoginSaga from "../userLogin/sagas";
import userEditSaga from "../userEdit/sagas";
import userInfoSaga from "../userInfo/sagas";
import contactSaga from "../contact/sagas";
import userInfoEditSaga from "../userInfoEdit/sagas";
import userPhotoEditSaga from "../userPhotoEdit/sagas"
import slideSaga from "../slide/sagas";
import coinValueSaga from "../coinValue/sagas";
import commentSaga from "../comment/sagas";
import commentEditSaga from "../commentEdit/sagas";
import emojiSaga from "../emoji/sagas";
import socialSaga from "../social/sagas";
import muxSaga from "../muxPlayer/sagas"

export function* rootSaga() {
  yield all([
    fork(serieChapterSaga),
    fork(userLoginSaga),
    fork(userEditSaga),
    fork(userInfoSaga),
    fork(contactSaga),
    fork(userInfoEditSaga),
    fork(userPhotoEditSaga),
    fork(slideSaga),
    fork(coinValueSaga),
    fork(commentSaga),
    fork(commentEditSaga),
    fork(emojiSaga),
    fork(socialSaga),
    fork(muxSaga)
  ]);
}
