import { useState } from "react";
import ModalShare from "../Modals/ModalShare";

const urlSite = "https://palenquetv.com";

const ButtonShare = ({ shareLink, shareTitle }) => {
  const [modalOn, setModalOn] = useState(false);

  const clicked = () => {
    setModalOn(true);
  };

  return (
    <>
      <button
        className="text-xs font-medium uppercase rounded-lg md:border md:flex md:py-1 md:px-3 md:text-base md:font-bold h-min"
        onClick={clicked}
      >
        <ion-icon
          name="share-social-outline"
          style={{ margin: "auto", fontSize: "24px" }}
        ></ion-icon>
        <div className="md:ml-2 font-sm-buttons">Compartir</div>
      </button>
      {modalOn && (
        <ModalShare
          setModalOn={setModalOn}
          shareUrl={urlSite + "/" + shareLink}
          shareTitle={shareTitle}
        />
      )}
    </>
  );
};

export default ButtonShare;
