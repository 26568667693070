import { createSelector } from "reselect";

import { AppState } from "../_root/rootReducer";

const getLoading = (state: AppState) => state.comment.loading;

const getResult = (state: AppState) => state.comment.result;

const getError = (state: AppState) => state.comment.error;

export const getResultCommentSelector = createSelector(getResult, (result) => result);

export const getLoadingCommentSelector = createSelector(
  getLoading,
  (loading) => loading
);

export const getErrorCommentSelector = createSelector(getError, (error) => error);


