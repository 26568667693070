import {
    FETCH_CONTACT_REQUEST,
    FETCH_CONTACT_FAILURE,
    FETCH_CONTACT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchContactRequest,
    FetchContactSuccess,
    FetchContactSuccessPayload,
    FetchContactFailure,
    FetchContactFailurePayload,
  } from "./types";
  
  export const fetchContactRequest = (
    query: any
  ): FetchContactRequest => ({
    type: FETCH_CONTACT_REQUEST,
    query,
  });
  
  export const fetchContactSuccess = (
    payload: FetchContactSuccessPayload
  ): FetchContactSuccess => ({
    type: FETCH_CONTACT_SUCCESS,
    payload,
  });
  
  export const fetchContactFailure = (
    payload: FetchContactFailurePayload
  ): FetchContactFailure => ({
    type: FETCH_CONTACT_FAILURE,
    payload,
  });