import { useEffect, useRef, useState } from "react";
import { getResultUserLoginSelector } from "../../_store/userLogin/selectors";
import { useDispatch, useSelector } from "react-redux";

import {
  getLoadingUserEditSelector,
  getResultUserEditSelector,
  getErrorUserEditSelector,
} from "../../_store/userEdit/selectors";
import { fetchUserEditInitial, fetchUserEditRequest } from "../../_store/userEdit/actions";

import SpinningLoading from "../../components/Spinning/SpinningLoading";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { toast } from "react-toastify";
import PhotoUser from "../../components/PhotoUser";
import AvatarImagePicker from "../../components/Modals/ModalAvatarImagePicker";
import { isPhone } from "../../util";
import { useNavigate } from "react-router-dom";

export function EditUser() {
  const user = useSelector(getResultUserLoginSelector);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [validate, setValidate] = useState(true);
  const [modalChangeImage, setModalChangeImage] = useState(false);

  const loading = useSelector(getLoadingUserEditSelector);
  const error = useSelector(getErrorUserEditSelector);
  const result = useSelector(getResultUserEditSelector);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setEmailVerified(!!user.email);
      setName(user.name ?? "");
      setPhone(user.phone ?? "");
    }
  }, [user]);

  useEffect(() => {
    if (!loading && result) {
      if (!error) {
        dispatch(fetchUserEditInitial())
        toast.info("Datos actualizados correctamente");
        navigate(-1);
      } else {
        toast.error(error);
      }
    }
  }, [loading]);

  const send = (event) => {
    event.preventDefault();

    const isValidPhoneNumber = isPhone(phone);


    if (email === "" || name === "") {
      toast.error("Complete los datos del formulario");
      return;
    } 

    if (phone !== "" && !isValidPhoneNumber) {
      toast.error("El número de teléfono es incorrecto");
      return;
    } 

    setValidate(true);
    dispatch(
      fetchUserEditRequest({
        type: "update",
        email: email,
        name: name,
        phone: phone,
      })
    );    

  };

  return (
    <div className="default-page container md:py-10 py-8 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl text-center">Editar Usuario</h1>
      <div className="container-login w-full max-w-lg m-auto px-4 sm:px-6">
        {error && <div className="mv-4">{error}</div>}

        {loading ? (
          <div className="h-15 m-5">
            <SpinningLoading />
          </div>
        ) : (
          <div className="sm:flex items-center justify-center mx-auto">
            <div className="sm:mr-8 relative max-w-xs mx-auto">
              <PhotoUser
                size={128}
                photoToken={user?.photoToken}
                uid={user?.uid}
                className="mx-auto"
              />
              <button
                onClick={() => setModalChangeImage(true)}
                className="flex rounded-full items-center bottom-0 sm:right-0 right-[35%] p-2 absolute bg-neutral-700 hover:bg-neutral-500 !text-xl"
              >
                <ion-icon name="camera-outline"></ion-icon>
              </button>
              {modalChangeImage && (
                <AvatarImagePicker
                  setModalOn={setModalChangeImage}
                  photoToken={user?.photoToken}
                  uid={user?.uid}
                />
              )}
            </div>
            <div className="sm:w-2/3 max-w-xs mx-auto">
              <form className="pt-6 pb-6" onSubmit={send}>
                <div className="mb-4">
                  <input
                    type="text"
                    name="name"
                    className="bg-black rounded w-full py-2 px-3"
                    placeholder="Nombre"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    name="email"
                    className="bg-black rounded w-full py-2 px-3"
                    placeholder="Correo electrónico"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    readOnly={emailVerified}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    name="phone"
                    className="bg-black rounded w-full py-2 px-3"
                    placeholder="Teléfono"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <button
                    className="border uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
                    type="submit"
                  >
                    Guardar Cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <p className="my-4 text-sm flex justify-between text-center flex-col md:flex-row">
          <span>¿Desea cambiar su contraseña?</span>

          <button
            onClick={() => {
              const auth = getAuth();
              sendPasswordResetEmail(auth, user.email)
                .then(() => {
                  toast.info(
                    "Se le enviará un email con el siguiente paso a seguir para modificiar su contraseña"
                  );
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            }}
            className="text-blue-700 hover:text-blue-900"
          >
            Cambiar Contraseña
          </button>
        </p>
      </div>
    </div>
  );
}
