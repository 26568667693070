/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import Hls from "hls.js";
import {fetchUserInfoEditRequest} from "../_store/userInfoEdit/actions";
import {getResultUserLoginSelector} from "../_store/userLogin/selectors";
import {useSelector} from "react-redux";

const convertTimeToMillis = (timeString) => {
  const [minutes, seconds] = timeString.split(':');
  return (parseInt(minutes, 10) * 60 + parseInt(seconds, 10)) * 1000;
};

const MuxPlayer = props => {
	const {
		autoPlay = false,
		controls = false,
		loop = false,
		muted = false,
		source = null,
		className = "",
		poster = null,
		chapterId = "",
		time = 0,
		hasAds = false,
		ads = [],
	} = props;

	const videoRef = useRef(null);
	const containerRef = useRef(null);
	const src = source;
	const dispatch = useDispatch();
	const user = useSelector(getResultUserLoginSelector);
	
  const [showAds, setShowAds] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);

	useEffect(
		() => () => {
			const items = JSON.parse(localStorage.getItem("timeTrack"));
			if (items && user?.login) {
				//guardar en la base de datos
				dispatch(
					fetchUserInfoEditRequest({
						id: chapterId,
						type: "chapter",
						field: {tracking_time: items.lastTimeTrack},
					}),
				);
				var timeTrack = {chapterTrack: "", lastTimeTrack: 0};
				localStorage.setItem("timeTrack", JSON.stringify(timeTrack));
			}
		},
		[user, chapterId],
	);

	useEffect(() => {
		if (source !== null) {
			let hls;
			if (videoRef.current) {
				const video = videoRef.current;

				if (video.canPlayType("application/vnd.apple.mpegurl")) {
					video.src = src;
				} else if (Hls.isSupported()) {
					hls = new Hls();
					hls.loadSource(src);
					hls.attachMedia(video);
				}
			}

			return () => {
				if (hls) {
					hls.destroy();
				}
			};
		}
	}, [source, src]);

	useEffect(() => {
		const video = videoRef.current;
	
		const handleFullscreenChange = () => {
			setIsFullscreen(document.fullscreenElement === video);
		};
	
		video.addEventListener("webkitfullscreenchange", handleFullscreenChange);
	
		return () => {
			video.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
		};
	}, []);

	const handleProgress = () => {		
    const videoElement = videoRef.current;
		var timeTrack = {chapterTrack: chapterId, lastTimeTrack: videoElement.currentTime};
		localStorage.setItem("timeTrack", JSON.stringify(timeTrack));

		if (hasAds && ads?.length) {
			const currentTimeMillis = timeTrack.lastTimeTrack * 1000;

			for (let i = 0; i < ads.length; i++) {
				const interval = ads[i];
				const startTimeMillis = convertTimeToMillis(interval.startTime);
				const endTimeMillis = convertTimeToMillis(interval.endTime);
				if (
					currentTimeMillis >= startTimeMillis &&
					currentTimeMillis <= endTimeMillis
				) {
					setShowAds(true);
					videoElement.play();
					return;
				} else {
					setShowAds(false);
				}
			}
		}
	};

  const toggleFullScreen = async () => {
    const container = containerRef.current;
    if (!document.fullscreenElement) {
      await container.requestFullscreen();
    } else {
      await document.exitFullscreen();
    }
  };

	const showControls = controls && !showAds;
	return (
		<div className={className} ref={containerRef} id="video-container">
			<video
				controls={showControls}
				autoPlay={autoPlay}
				loop={loop}
				muted={muted}
				ref={videoRef}
				className={`${className} ${isFullscreen && showAds ? 'fullscreen-video' : ''} video-no-fullscreen`}
				poster={poster}
				controlsList="nodownload nofullscreen"
				onTimeUpdate={() => {
					chapterId !== "" && handleProgress();
				}}
				onLoadStart={() => (videoRef.current.currentTime = time)}
			/>
			<button onClick={toggleFullScreen} className="absolute top-4 right-4 fullscreen-button">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
					<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
				</svg>	
			</button>

			{showAds ? (
				<span className="absolute top-4 left-4 text-white text-sm font-bold bg-[#3333335b] p-2 rounded ad" >Publicidad</span>
			) : null}
		</div>
	);
};

export default MuxPlayer;
