export async function getComment(query: any): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      fetch(process.env.REACT_APP_FIREBASE_FUNCTIONS + "/commentList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chapter: query.chapterId,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          //console.log("RESP:", response);
          if (response.data && response.data.chapter) {
            resolve({
              result: response.data.result,
              total: response.data.total,
              chapter: response.data.chapter,
            });
          } else {
            reject(response.data.error ?? "Failed to do something exceptional");
          }
        })
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
}
