export async function sendContact(query: any): Promise<any> {
  const { email, name, phone, message, type } = query;

  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "X-Api-Key",
      process.env.REACT_APP_CONTACT_URL_KEY.toString()
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      name: name,
      phone: phone,
      message: message,
      type: type,
      isSend: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(process.env.REACT_APP_API_URL + "api/contacts", requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
