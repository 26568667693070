import {
    FETCH_COMMENT_EDIT_REQUEST,
    FETCH_COMMENT_EDIT_FAILURE,
    FETCH_COMMENT_EDIT_SUCCESS,
  } from "./actionTypes";
  import {
    FetchCommentEditRequest,
    FetchCommentEditSuccess,
    FetchCommentEditSuccessPayload,
    FetchCommentEditFailure,
    FetchCommentEditFailurePayload,
    Query
  } from "./types";
  
  export const fetchCommentEditRequest = (
    query: Query
  ): FetchCommentEditRequest => ({
    type: FETCH_COMMENT_EDIT_REQUEST,
    query,
  });
  
  export const fetchCommentEditSuccess = (
    payload: FetchCommentEditSuccessPayload
  ): FetchCommentEditSuccess => ({
    type: FETCH_COMMENT_EDIT_SUCCESS,
    payload,
  });
  
  export const fetchCommentEditFailure = (
    payload: FetchCommentEditFailurePayload
  ): FetchCommentEditFailure => ({
    type: FETCH_COMMENT_EDIT_FAILURE,
    payload,
  });