import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  getLoadingUserLoginSelector,
  getResultUserLoginSelector,
  getErrorUserLoginSelector,
} from "../_store/userLogin/selectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLoginRequest } from "../_store/userLogin/actions";
import { signInWithGoogle, signInWithFacebook } from "../_store/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import db from "../_store/firebase";

import SpinningLoading from "../components/Spinning/SpinningLoading";

import { toast } from "react-toastify";
import { isEmail } from "../util";

export const LoginPage = () => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const loading = useSelector(getLoadingUserLoginSelector);
  const user = useSelector(getResultUserLoginSelector);
  const error = useSelector(getErrorUserLoginSelector);

  const isDev = process.env.NODE_ENV === "development";

  const [email, setEmail] = useState(isDev ? "webtest@test.com" : "");
  const [password, setPassword] = useState(isDev ? "webtest123" : "");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userAuth, setUserAuth] = useState();

  const [validate, setValidate] = useState(false);

  useEffect(() => {
    setValidate(false);
  }, []);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (
        user &&
        user.providerData &&
        user.providerData[0] &&
        user.providerData[0].providerId &&
        user.providerData[0].providerId != "" &&
        (user.providerData[0].providerId.includes("google") ||
          user.providerData[0].providerId.includes("facebook"))
      ) {
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((documentSnapshot) => {
            if (documentSnapshot.exists) {
              let data = documentSnapshot.data();

              dispatch(
                fetchUserLoginRequest({ email: data.email, uid: user.uid })
              );
            } else {
              //console.log(JSON.stringify(user, null, 8));

              if (
                user &&
                user.providerData &&
                user.providerData[0] &&
                user.providerData[0].email
              ) {
                let email = user.providerData[0].email;
                let name = user.displayName;
                let providerId = user.providerData[0].providerId;

                // insert
                db.collection("users")
                  .doc(user.uid)
                  .set({
                    email,
                    name,
                    phone: "",
                    createdAt: db.Timestamp.fromDate(new Date()),
                    deviceToken: "",
                    provider: providerId,
                  })
                  .then(function () {
                    dispatch(
                      fetchUserLoginRequest({ email: email, uid: user.uid })
                    );
                  })
                  .catch((error) => {
                    console.log(
                      "Something went wrong with added user to firestore: ",
                      error
                    );
                    //setError2(error);
                  });
              }
            }
          })
          .catch((error) => {
            console.log(
              "Something went wrong with added user to firestore: ",
              error
            );
          });
      }
    } else {
      // User is signed out
      // ...
    }
  });

  useEffect(() => {
    if (!loading) {
      if (user) {
        toast.info("Bienvenido " + user.name);
      }
      if (error) {
        //toast.error(error);
      }
    }
  }, [loading]);

  const send = (event) => {
    event.preventDefault();

    const isValidEmail = isEmail(email);
     
    if (!isValidEmail) {
      toast.error("La dirección de correo electrónico es incorrecta");
      return;
    }

    if (email === "" || password === "") {
      toast.error("Complete los datos del formulario");
    } else {
      setValidate(true);
      dispatch(fetchUserLoginRequest({ email: email, password: password }));
    }
  };

  useEffect(() => {
    if (user?.login) navigate("/");
  }, [user]);

  return (
    <div className="default-page container md:py-10 py-8 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl text-center">Iniciar Sesión</h1>
      <div className="container-login w-full max-w-md m-auto px-4 sm:px-6">
        {loading ? (
          <div className="h-15 m-5">
            <SpinningLoading />
          </div>
        ) : (
          <div>
            {error && validate && (
              <div className="label-error text-center text-red-500 mt-4">
                {error}
              </div>
            )}
            <form className="pt-6 pb-8" onSubmit={send}>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  className="bg-black rounded w-full py-2 px-3"
                  placeholder="Correo electrónico"
                  onChange={(e) => setEmail(e.target.value.replace(/\s/g, "").toLowerCase())}
                  value={email}
                  onKeyDown={(e) => {
                    if (e.code === 'Space') e.preventDefault()
                  }}
                />
              </div>

              {/* <div className="mb-4">
                <input
                  type="password"
                  name="password"
                  className="bg-black rounded w-full py-2 px-3"
                  placeholder="Contraseña"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div> */}

              <div className="mb-4">
                <div className="relative flex items-center text-white">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    className="w-full rounded bg-black py-2 px-3 text-white"
                    placeholder="Contraseña"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required={true}
                  />
                  <button
                    type="button"
                    className="absolute right-0 h-full rounded px-2 hover:bg-neutral-700 flex items-center"
                    onClick={() => {
                      setPasswordVisible((prev) => !prev);
                    }}
                  >
                    {!passwordVisible ? (
                      <ion-icon name="eye-off-outline"></ion-icon>
                    ) : (
                      <ion-icon name="eye-outline"></ion-icon>
                    )}
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <button
                  className="border uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
                  type="submit"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="relative pb-8 items-center">
          <Link
            to="/recuperar-contrasenya"
            className="inline-block align-baseline text-sm text-blue-500 hover:text-blue-800"
          >
            ¿Perdiste tu contraseña?
          </Link>
        </div>

        <div className="relative pb-8">
          <hr></hr>
          <span className="absolute text-other-social">o bien</span>
        </div>

        <button
          className="facebook flex items-center justify-center border border-blue-700 uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full mb-4"
          onClick={signInWithFacebook}
        >
          <ion-icon name="logo-facebook"></ion-icon>
          Ingresar con Facebook
        </button>

        <button
          className="google flex items-center justify-center border border-blue-700 uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
          onClick={signInWithGoogle}
        >
          <ion-icon name="logo-google"></ion-icon>
          Ingresar con Google
        </button>

        <p className="my-4 text-sm flex justify-between px-3">
          ¿No tienes una cuenta?
          <Link to="/registrar" className="text-blue-700 hover:text-blue-900">
            Registrate
          </Link>
        </p>
      </div>
    </div>
  );
};
