import { createSelector } from "reselect";

import { AppState } from "../_root/rootReducer";

const getLoading = (state: AppState) => state.emoji.loading;

const getResult = (state: AppState) => state.emoji.result;

const getError = (state: AppState) => state.emoji.error;



export const getResultEmojiSelector = createSelector(getResult, (result) => result);

export const getLoadingEmojiSelector = createSelector(
  getLoading,
  (loading) => loading
);

export const getErrorEmojiSelector = createSelector(getError, (error) => error);


