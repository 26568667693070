import {
    FETCH_SERIE_CHAPTER_REQUEST,
    FETCH_SERIE_CHAPTER_FAILURE,
    FETCH_SERIE_CHAPTER_SUCCESS,
  } from "./actionTypes";
  import {
    FetchSerieChapterRequest,
    FetchSerieChapterSuccess,
    FetchSerieChapterSuccessPayload,
    FetchSerieChapterFailure,
    FetchSerieChapterFailurePayload,
  } from "./types";
  
  export const fetchSerieChapterRequest = (): FetchSerieChapterRequest => ({
    type: FETCH_SERIE_CHAPTER_REQUEST,
  });
  
  export const fetchSerieChapterSuccess = (
    payload: FetchSerieChapterSuccessPayload
  ): FetchSerieChapterSuccess => ({
    type: FETCH_SERIE_CHAPTER_SUCCESS,
    payload,
  });
  
  export const fetchSerieChapterFailure = (
    payload: FetchSerieChapterFailurePayload
  ): FetchSerieChapterFailure => ({
    type: FETCH_SERIE_CHAPTER_FAILURE,
    payload,
  });