import { all, call, put, takeLatest, select } from "redux-saga/effects";

import {
  fetchUserPhotoEditFailure,
  fetchUserPhotoEditSuccess,
} from "./actions";
import { FETCH_USER_PHOTO_EDIT_REQUEST } from "./actionTypes";

import { FetchUserPhotoEditRequest } from "./types";

import { getUserPhotoEditInsert } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchUserLoginSaga } from "../userLogin/sagas";

export function* fetchUserPhotoEditSaga(
  action: FetchUserPhotoEditRequest
): any {
  try {
    let response: any;
    let user: any;

    //Alert.alert('',  JSON.stringify(action.query) );

    user = yield select(getResultUserLoginSelector);

    response = yield call(getUserPhotoEditInsert, user.uid, action.query);

    if (response && response.data && response.data.msj == "ok") {
      yield call(fetchUserLoginSaga, {
        query: { uid: user.uid, email: user.email },
      });

      yield put(
        fetchUserPhotoEditSuccess({
          result: response.data,
        })
      );
    } else {
      if (response && response.error && response.error != "")
        yield put(fetchUserPhotoEditFailure({ error: response.error }));
    }
  } catch (error) {
    let errorMessage: any = "";
    errorMessage = error;
    yield put(fetchUserPhotoEditFailure({ error: errorMessage }));
  }
}

function* UserPhotoEditSaga() {
  yield all([
    takeLatest(FETCH_USER_PHOTO_EDIT_REQUEST, fetchUserPhotoEditSaga),
  ]);
}

export default UserPhotoEditSaga;
