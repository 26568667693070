/* eslint-disable import/no-anonymous-default-export */
import { FETCH_SOCIAL_NETWORK_FAILURE, FETCH_SOCIAL_NETWORK_REQUEST, FETCH_SOCIAL_NETWORK_SUCCESS } from "./actionTypes";
import { SocialActions, SocialState } from "./types";

const initialState: SocialState = {
  error: null,
  loading: false,
}

export default (state = initialState, action: SocialActions) => {  
  switch (action.type) {
    case FETCH_SOCIAL_NETWORK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case FETCH_SOCIAL_NETWORK_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case FETCH_SOCIAL_NETWORK_REQUEST: 
      return {
        ...state,
        error: null,
        loading: true,
      }
    default: 
      return {
        ...state,
      }
  }
}