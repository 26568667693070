import db from "../_root/apiBridge";
import { getAuth } from "firebase/auth";

export async function getSerieChapter(): Promise<any> {
  let levelAccess: any = {};
  const user = getAuth().currentUser;

  if (user) {
    const querySnapshot = await db
      .collection("user_info")
      .where("user", "==", user.uid)
      .get();

    querySnapshot.forEach((snapshot) => {
      const data = snapshot.data();

      if (data?.serie) {
        levelAccess[data.serie] = data.membership;
      }
    });
  }

  return new Promise((resolve, reject) => {
    db.collection("series")
      .where("promote", "==", true)
      .orderBy("audit_created", "desc") //  by order
      //.limit(1)
      .get()
      .then((snapshot) => {
        if (snapshot.size === 0) {
          resolve([]);
        }

        let result: any = [];

        snapshot.forEach((doc) => {
          const serie = doc.data();

          db.collection("chapters")
            .where("serie_id", "==", doc.id)
            //.where('premiere', '==', false)
            //.orderBy('free', 'asc')
            .orderBy("chapter", "asc")
            .get()
            .then((snapshot2) => {
              let result2: any = [];
              snapshot2.forEach((doc2) => {
                const membership = doc2.data()?.membership;

                result2.push({
                  ...doc2.data(),
                  id: doc2.id,
                  serieId: doc.id,
                  serieTitle: serie.title,
                  serieLogo: serie.img_logo,
                  canSee:
                    levelAccess[doc.id] === "vip" ||
                    levelAccess[doc.id] === membership,
                });
              });

              if (result2.length === snapshot2.size) {
                let published = result2.filter((ch) => Boolean(ch?.published));
                published.sort((a, b) => parseInt(a.chapter) - parseInt(b.chapter)); 
                let chapterFree: any = [];
                let chapterExclusive: any = [];
                published.map((item) => {
                  if (item.type && item.type == "chapter") {
                    chapterFree.push(item);
                  } else {
                    chapterExclusive.push(item);
                  }
                });
                result.push({
                  ...doc.data(),
                  id: doc.id,
                  chapter: published,
                  chapterFree,
                  chapterExclusive,
                });
              }
              if (result.length === snapshot.size) {
                result.sort((a, b) => parseInt(a.order) - parseInt(b.order)); // order by serie order number
                resolve(result);
              }
            });
        });
      })
      .catch((error) => {
        console.log(error);

        reject(error);
      });
  });
}

export async function getSerieChapter2() {
  return (
    db
      .collection("series")
      //.where('serie_id', '==', doc.id)
      //.where('premiere', '==', false)
      //.orderBy('chapter', 'asc')
      .get()
      .then((snapshot) => {
        let result: any = [];
        snapshot.forEach((doc) => {
          result.push({ ...doc.data(), id: doc.id });
        });

        if (result.length === snapshot.size) {
          return result;
        }
      })
  );
}
