import {
    FETCH_COMMENT_REQUEST,
    FETCH_COMMENT_FAILURE,
    FETCH_COMMENT_SUCCESS,
    FETCH_COMMENT_INITIAL,
  } from "./actionTypes";
  import {
    FetchCommentRequest,
    FetchCommentSuccess,
    FetchCommentSuccessPayload,
    FetchCommentFailure,
    FetchCommentFailurePayload,
    FetchCommentInitial,
    Query,
  } from "./types";
  
  export const fetchCommentRequest = (
    query: Query
  ): FetchCommentRequest => ({
    type: FETCH_COMMENT_REQUEST,
    query,
  });
  
  export const fetchCommentSuccess = (
    payload: FetchCommentSuccessPayload
  ): FetchCommentSuccess => ({
    type: FETCH_COMMENT_SUCCESS,
    payload,
  });
  
  export const fetchCommentFailure = (
    payload: FetchCommentFailurePayload
  ): FetchCommentFailure => ({
    type: FETCH_COMMENT_FAILURE,
    payload,
  });

  export const fetchCommentInitial = (): FetchCommentInitial => ({
    type: FETCH_COMMENT_INITIAL,
  });