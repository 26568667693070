import { useEffect, useState } from "react";
import ContactForm from "../components/ContactForm";
import { dataWebsite } from "../util";

export function ContactPage() {
  
  const [webData, setWebData] = useState();
  useEffect(() => {
    setWebData(dataWebsite);
  }, []); 

  return (
    <div className="default-page container md:py-10 py-8 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl text-center">Contacto</h1>
      <div className="container-contact">
        <p className="pt-4 md:pt-5">
          Si deseas información adicional quieres contactar con nosotros, hazlo
          por una de estas vías
        </p>
        <div className="flex button-actions text-sm pt-4 md:pt-5">
          <a href={"tel:"+webData?.phone} className="btn-default-b-white md:mb-0 mb-3">
            <ion-icon name="call-outline"></ion-icon>
            <span>Llamar</span>
          </a>
          <a href={"https://wa.me/"+webData?.phone} className="btn-default-b-white whatsapp">
            <ion-icon name="logo-whatsapp"></ion-icon>
            <span>Whatsapp</span>
          </a>
        </div>
        <p className="pt-4 md:pt-5">
          O déjanos un mensaje y pronto nos pondremos en contacto contigo
        </p>
        <div className="container default-page pt-4 md:pt-6">
          <div className="wrap-form-sponsors">
            <ContactForm submitText="Enviar Mensaje"/>
          </div>
        </div>
      </div>
    </div>
  );
}