import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchContactFailure, fetchContactSuccess } from "./actions";
import { FETCH_CONTACT_REQUEST } from "./actionTypes";

import { FetchContactRequest } from "./types";

import { sendContact } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchUserLoginSaga } from "../userLogin/sagas";

export function* fetchContactSaga(action: FetchContactRequest): any {
  try {
    let response: any;
    let user: any;

    user = yield select(getResultUserLoginSelector);


    response = yield call(sendContact, action.query);
    console.log(JSON.stringify(response));

    yield put(
      fetchContactSuccess({
        result: response,
      })
    );


   
  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    yield put(fetchContactFailure({ error: errorMessage }));
  }
}

function* ContactSaga() {
  yield all([takeLatest(FETCH_CONTACT_REQUEST, fetchContactSaga)]);
}

export default ContactSaga;
