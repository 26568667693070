
import  db  from '../_root/apiBridge';
import  { timestamp }  from '../_root/apiBridge';


import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
//import auth from '@react-native-firebase/auth';


export async function getUserLogin(query: any): Promise<any> {

 
  return new Promise((resolve, reject) => {

    if (
      query.uid &&
      query.uid !== '' 
    ) {
      resolve({user: {uid: query.uid , email: query.email}});
    }



    if (
      query.email &&
      query.email !== '' &&
      query.password &&
      query.password !== ""

    ) {


      signInWithEmailAndPassword(getAuth(), query.email, query.password)
      //auth().signInWithEmailAndPassword(query.email, query.password)

        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });

    }


  
  
  
  });

}




export async function getUserDetail(uid: string): Promise<any> {
  return new Promise((resolve, reject) => {

    db
      .collection('users')
      .doc(uid)
      .get()

      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          resolve(documentSnapshot.data());
        } else {
          resolve([]);
        }

      })
      .catch((error) => {
        reject(error);
      });



 


  });

}