import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUserPhotoEditRequest } from "../../_store/userPhotoEdit/actions";
import AvatarEditor from "react-avatar-editor";
import { FileUpload } from "../FileUpload";
import { Slider } from "../Slider";
import SpinningLoading from "../Spinning/SpinningLoading";

function AvatarImagePicker(props) {
  const { setModalOn, uid, photoToken } = props;
  const editorRef = useRef<AvatarEditor>(null);
  const [image, setImage] = useState<string | File>("");
  const [scale, setScale] = useState(5000);
  const [rotation, setRotation] = useState(0);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (uid && photoToken) {
      const url_img = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_PROJECTID}-user/o/${uid}%2Fuser-photo?alt=media&token=${photoToken}`;
      setImage(url_img);
      setRotation(0);
      setScale(5000);
    }
  },[uid, photoToken]);

  const handleSave = () => {  
    if(typeof image !== 'string'){
      setLoading(true);

      const canvas = editorRef.current.getImage();
      canvas.toBlob((blob) => {
        let file = new File([blob], image.name, { type: "image/jpeg" })

        dispatch(
          fetchUserPhotoEditRequest({
            file,
          })
        );
      }, 'image/jpeg');
    } else {
      setModalOn(false)
    }
  };

  const handleOKClick = () => {
    setModalOn(false);
  };

  return (
    <div
      role="dialog"
      className="fixed top-0 left-0 bottom-0 right-0 z-50 bg-black bg-opacity-80"
      aria-modal={true}
    >
      <div
        role="none"
        className="absolute top-0 right-0 left-0 bottom-0"
        onClick={handleOKClick}
      />
      <div className="relative top-1/2 mx-auto my-auto flex h-full -translate-y-1/2 flex-col rounded bg-neutral-900 px-6 sm:h-fit sm:max-h-[80%] sm:w-fit sm:max-w-[600px] sm:p-6 sm:ring-2 sm:ring-white">
        <div className="header-modal">
          <div
            className="modal-close cursor-pointer z-50"
            onClick={handleOKClick}
          >
            <svg
              className="fill-current absolute top-2 right-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
        </div>

        <div className="flex md:text-xl text-lg font-bold uppercase md:mb-5 mb-4 justify-center border-b-2">
          SELECCIONAR IMAGEN
        </div>
        <div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
          <div className="flex flex-col gap-6 sm:flex-row">
            <div className="mx-auto h-fit w-fit flex-shrink-0 flex-grow-0 overflow-hidden rounded">
              <AvatarEditor
                ref={editorRef}
                image={image}
                border={25}
                color={[255, 255, 255, 0.2]} // RGBA
                scale={scale / 5000}
                rotate={rotation}
                width={200}
                height={200}
              />
            </div>
            <div className="flex flex-grow-0 flex-col overflow-hidden">
              <div className="mt-4">
                <div className="mb-2 w-full overflow-hidden">
                  <label htmlFor="photo" className="block">
                    Foto
                  </label>
                  <FileUpload
                    name="photo"
                    onChange={(e) => {
                      if (e.target.files?.[0]) {
                        setImage(e.target.files[0]);
                      }
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
                <label htmlFor="rotation">Rotación</label>
                <div className="flex items-center">
                  <button
                    className="mr-2 flex h-6 w-8 cursor-pointer select-none items-center justify-center rounded font-black leading-none hover:bg-neutral-700 hover:text-neutral-200 disabled:cursor-not-allowed"
                    disabled={rotation <= -360 || !image || loading}
                    onClick={() => {
                      setRotation((prev) => prev - 1);
                    }}
                  >
                    -
                  </button>
                  <Slider
                    name="rotation"
                    value={rotation}
                    min={-360}
                    disabled={!image || loading}
                    max={360}
                    onChange={(e) => {
                      setRotation(Number(e.target.value));
                    }}
                  />
                  <button
                    className="ml-2 flex h-6 w-8 cursor-pointer select-none items-center justify-center rounded font-black leading-none hover:bg-neutral-700 hover:text-neutral-200 disabled:cursor-not-allowed"
                    disabled={rotation >= 360 || !image || loading }
                    onClick={() => {
                      setRotation((prev) => prev + 1);
                    }}
                  >
                    +
                  </button>
                  <div className="w-[6ch] text-end">{rotation}&deg;</div>
                </div>
                <label htmlFor="scale">Zoom</label>
                <div className="flex items-center">
                  <button
                    className="mr-2 flex h-6 w-8 cursor-pointer select-none items-center justify-center rounded font-black leading-none hover:bg-neutral-700 hover:text-neutral-200 disabled:cursor-not-allowed"
                    disabled={scale <= 0 || !image || loading }
                    onClick={() => {
                      setScale((prev) => prev - 100);
                    }}
                  >
                    -
                  </button>
                  <Slider
                    name="scale"
                    value={scale}
                    min={5000}
                    max={20000}
                    disabled={!image || loading }
                    onChange={(e) => {
                      setScale(Number(e.target.value));
                    }}
                  />
                  <button
                    className="ml-2 flex h-6 w-8 cursor-pointer select-none items-center justify-center rounded font-black leading-none hover:bg-neutral-700 hover:text-neutral-200 disabled:cursor-not-allowed"
                    disabled={scale >= 20000 || !image || loading }
                    onClick={() => {
                      setScale((prev) => prev + 100);
                    }}
                  >
                    +
                  </button>
                  <div className="w-[6ch] text-end">
                    {(scale / 5000).toFixed(2)}x
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          className="text-md  mt-4 flex w-full items-center justify-center rounded-xl border-2 bg-white bg-opacity-10 py-3 px-4 font-bold uppercase tracking-wider text-white transition-colors duration-300 hover:bg-white hover:text-black disabled:cursor-not-allowed disabled:border-neutral-800 disabled:bg-neutral-700 disabled:text-neutral-300"
          type="submit"
          disabled={!image || loading}
          onClick={handleSave}
        >
          {loading ? <SpinningLoading className="w-5 h-5 !mr-0" /> : "Aceptar"}
        </button>
      </div>
    </div>
  );
}

export default AvatarImagePicker;
