import {
  FETCH_COMMENT_REQUEST,
  FETCH_COMMENT_SUCCESS,
  FETCH_COMMENT_FAILURE,
  FETCH_COMMENT_INITIAL,
} from "./actionTypes";

import { CommentActions, CommentState } from "./types";

const initialState: CommentState = {
  loading: false,
  result: null,
  error: null,
};

export default (state = initialState, action: CommentActions) => {
  switch (action.type) {
    case FETCH_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        error: null,
      };
    case FETCH_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload.error,
      };
    case FETCH_COMMENT_INITIAL:
      return {
        ...state,
        loading: false,
        result: null,
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
};