import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import SpinningLoading from "../components/Spinning/SpinningLoading";
import { isPassword } from "../util";
import { toast } from "react-toastify";

const updatePassword = (params, credentials) => {
  const url = `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${params.get(
    "apiKey"
  )}`;
  const data = {
    oobCode: params.get("oobCode"),
    tenantId: params.get("tenantId"),
    newPassword: credentials.password,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const EmailPasswordReset = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordReplyVisible, setPasswordReplyVisible] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className="flex flex-col items-center py-8 px-3 md:py-10 md:px-5">
      {!success && (
        <h1 className="text-start text-xl font-bold uppercase text-white md:text-3xl">
          Restablece tu contraseña
        </h1>
      )}
      <div className="mx-auto w-full max-w-md px-4 sm:px-6">
        {!success && (
          <p className="py-6 text-center">
            Rellena los campos mostrados a continuación para restablecer tu
            contraseña.
          </p>
        )}
        {!!error && (
          <div className="mb-4 rounded-md text-red-500 px-4 py-2">{error}</div>
        )}
        {success && (
          <div className="py-6 text-center">
            <div className="text-xl font-bold uppercase text-white">
              Contraseña cambiada con éxito
            </div>
            {/* <div className="pt-6 text-center">Ahora puede iniciar sesión</div> */}
            <div className="flex items-center justify-between pt-6">
              <Link
                className="border uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
                to="/login"
              >
                Iniciar Sesión
              </Link>
            </div>
          </div>
        )}
        {!success && (
          <form
            onSubmit={(event) => {
              setLoading(true);
              event.preventDefault();

              if (!isPassword(credentials.password)) {
                toast.error("La contraseña debe tener al menos 6 caracteres");
                return;
              }

              updatePassword(params, credentials).then((response) => {
                if (response.status !== 200) {
                  response.json().then((value) => {
                    if (value.error) {
                      setError("Enlace expirado");
                    }
                    setLoading(false);
                  });
                } else {
                  response.json().then((value) => {
                    //console.log(value.email);
                    setSuccess(true);
                  });
                  setLoading(false);
                }
              });
            }}
          >
            {/* <div className="my-4">
              <input
                type="password"
                name="password"
                className="mt-1 w-full rounded bg-black py-2 px-3"
                placeholder="Insertar contraseña..."
                value={credentials.password}
                onChange={(e) => {
                  setCredentials((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }));
                }}
                autoComplete="new-password"
              />
            </div> */}

            <div className="my-4">
              <div className="relative flex items-center text-white">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  className="w-full rounded bg-black py-2 px-3 text-white"
                  placeholder="Contraseña *"
                  onChange={(e) => {
                    setCredentials((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  value={credentials.password}
                  required={true}
                />
                <button
                  type="button"
                  className="absolute right-0 h-full rounded px-2 hover:bg-neutral-700 flex items-center"
                  onClick={() => {
                    setPasswordVisible((prev) => !prev);
                  }}
                >
                  {passwordVisible ? (
                    <ion-icon name="eye-off-outline"></ion-icon>
                  ) : (
                    <ion-icon name="eye-outline"></ion-icon>
                  )}
                </button>
              </div>
            </div>

            {/* <div className="my-4">
              <input
                type="password"
                name="confirmPassword"
                className="mt-1 w-full rounded bg-black py-2 px-3"
                placeholder="Confirmar contraseña..."
                value={credentials.confirmPassword}
                onChange={(e) => {
                  setCredentials((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }));
                }}
                autoComplete="new-password"
              />
            </div> */}

            <div className="my-4">
              <div className="relative flex items-center text-white">
                <input
                  type={passwordReplyVisible ? "text" : "password"}
                  name="confirmPassword"
                  className="w-full rounded bg-black py-2 px-3 text-white"
                  placeholder="Confirmar Contraseña *"
                  onChange={(e) => {
                    setCredentials((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  value={credentials.confirmPassword}
                  required={true}
                />
                <button
                  type="button"
                  className="absolute right-0 h-full rounded px-2 hover:bg-neutral-700 flex items-center"
                  onClick={() => {
                    setPasswordReplyVisible((prev) => !prev);
                  }}
                >
                  {passwordReplyVisible ? (
                    <ion-icon name="eye-off-outline"></ion-icon>
                  ) : (
                    <ion-icon name="eye-outline"></ion-icon>
                  )}
                </button>
              </div>
            </div>

            <button
              type="submit"
              className="flex w-full items-center justify-center rounded-xl border-2 bg-white bg-opacity-10 py-2 px-4 text-sm font-semibold uppercase text-white transition-colors duration-300 hover:bg-white hover:text-black disabled:border-neutral-800 disabled:bg-neutral-700 disabled:text-neutral-300"
              disabled={
                loading ||
                !credentials.password ||
                !(credentials.confirmPassword === credentials.password)
              }
            >
              {loading ? (
                <SpinningLoading className="h-7" />
              ) : (
                "Establecer Nueva Contraseña"
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};
