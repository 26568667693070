import { useEffect } from "react";

const ModalReport = ({ setModalOn, sendReport }) => {
  
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const handleOKClick = () => {
    setModalOn(false);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 transition-opacity z-50"
      onClick={handleOKClick}
    >
      <div className="flex h-screen justify-center items-center ">
        <div
          className="relative flex-col justify-center bg-black py-12 md:px-12 px-6 md:mx-0 mx-3 border-1 border-white rounded-xl w-full max-w-lg"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="modal-close cursor-pointer z-50"
            onClick={handleOKClick}
          >
            <svg
              className="fill-current absolute top-2 right-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
          <div className="flex text-sm md:text-md font-bold uppercase mb-6">
            Nos tomamos las denuncias muy seriamente. ¿Desea continuar con su denuncia?
          </div>
        
          <div className="flex flex-row gap-5">
            <button
              className="border uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
              type="button"
              onClick={handleOKClick}
            >
              Cancelar
            </button>
            <button
              className="border uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
              type="button"
              onClick={sendReport}
            >
              Reportar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReport;
