import { createSelector } from "reselect";

import { AppState } from "../_root/rootReducer";

const getLoading = (state: AppState) => state.userEdit.loading;

const getResult = (state: AppState) => state.userEdit.result;

const getError = (state: AppState) => state.userEdit.error;



export const getResultUserEditSelector = createSelector(getResult, (result) => result);

export const getLoadingUserEditSelector = createSelector(
  getLoading,
  (loading) => loading
);

export const getErrorUserEditSelector = createSelector(getError, (error) => error);


