import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {getResultUserLoginSelector} from "../../_store/userLogin/selectors";
import {getResultUserInfoSelector} from "../../_store/userInfo/selectors";
import {fetchUserInfoEditRequest} from "../../_store/userInfoEdit/actions";
import SpinningLoading from "../Spinning/SpinningLoading";

const baseURL = process.env.REACT_APP_API_URL + "api/payments/stripe/";
const urlPayKey = process.env.REACT_APP_STRIPE_URL_KEY;

const UnirseButtons = props => {
	const {text, MembershipType = "simple", PaymentContents = "membership", SerieId, active = true, className} = props;

	const user = useSelector(getResultUserLoginSelector);
	const userInfo = useSelector(getResultUserInfoSelector);
	const [loadingPayment, setLoadingPayment] = useState(false);
	const dispatch = useDispatch();
	var serieExist = false;

	function createPayment() {
		if (!user?.login) {
			toast.warning("Debe iniciar sesión para interactuar");
			return false;
		}
		userInfo.map((item, i) => {
			if (item.serie === SerieId) {
				serieExist = true;
			}
		});
		if (!serieExist) {
			dispatch(
				fetchUserInfoEditRequest({
					id: SerieId,
					type: "serie",
					field: {like: false},
				}),
			);
		}

		setLoadingPayment(true);
    
		var myHeaders = new Headers();
		myHeaders.append("X-Api-Key-Pay", "" + urlPayKey);
		myHeaders.append("Content-Type", "application/json");

		const requestsOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				UserId: user.uid,
				SerieId,
				MembershipType,
				PaymentContents,
				UrlReturn: window.location.protocol + "//" + window.location.host + window.location.pathname,
			}),
		};
		fetch(baseURL, requestsOptions)
			.then(response => response.json())
			.then(data => window.location.replace(data.url))
			.catch(error => {
				console.error("There was an error!", error);
			});
	}

	return (
		<>
			{active && (
				<button
					onClick={createPayment}
					className={`rounded bg-blue-600 py-2 px-5 font-bold uppercase text-white duration-500 hover:bg-sky-400 ${className}`}
				>
					{loadingPayment ? <SpinningLoading className="!mr-0 h-5" /> : text}
				</button>
			)}
		</>
	);
};

export default UnirseButtons;
