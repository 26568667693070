import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoadingCommentSelector,
  getResultCommentSelector,
} from "../_store/comment/selectors";
import {
  getLoadingCommentEditSelector,
  getErrorCommentEditSelector,
} from "../_store/commentEdit/selectors";
import { getResultUserLoginSelector } from "../_store/userLogin/selectors";
import { fetchCommentRequest } from "../_store/comment/actions";
import { fetchCommentEditRequest } from "../_store/commentEdit/actions";
import SpinningLoading from "./Spinning/SpinningLoading";
import { toast } from "react-toastify";
import ModalSticker from "./Modals/ModalSticker";
import PhotoUser from "./PhotoUser";
import ModalReport from "./Modals/ModalReport";
import db from "../_store/firebase";

const Comments = (props) => {
  const { chapterId, serieId, setModalCoinsOn } = props;
  const user = useSelector(getResultUserLoginSelector);

  const [textComment, setTextComment] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();
  const isLoadingComment = useSelector(getLoadingCommentSelector);
  const response = useSelector(getResultCommentSelector);
  //const error = useSelector(getErrorCommentSelector);

  const isLoadingEdit = useSelector(getLoadingCommentEditSelector);
  //const responseEdit = useSelector(getResultCommentEditSelector);
  const errorEdit = useSelector(getErrorCommentEditSelector);

  const [modalStickerOn, setModalStickerOn] = useState(false);
  const [modalReportOn, setModalReportOn] = useState(false);
  const [updateSticker, setUpdateSticker] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [reporting, setReporting] = useState(false);
  const [listComment, setListComment] = useState([]);
  const [currentComment, setCurrentComment] = useState(null);
  const [reported, setReported] = useState([]);

  useEffect(() => {
    if (!response || (response.chapter && response.chapter != chapterId)) {
      dispatch(
        fetchCommentRequest({
          chapterId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      response &&
      response.result &&
      !response.loading &&
      response.result.length >= 0
    ) {
      let copyComments = [...response.result];
      copyComments.sort(
        (a, b) => b.audit_created._seconds - a.audit_created._seconds
      );
      setListComment(copyComments);
    }
  }, [response, response?.loading]);

  useEffect(() => {
    if (updateSticker) {
      setUpdateSticker(false);
      dispatch(
        fetchCommentRequest({
          chapterId,
        })
      );
    }
  }, [modalStickerOn]);

  useEffect(() => {
    if (isLoadingEdit == false && submitted) {
      if (errorEdit == null) {
        toast.success("Comentario agregado correctamente.");
        setSubmitted(false);
      } else {
        toast.error("Ha ocurrido un error. Inténtelo mas tarde.");
        setSubmitted(false);
      }
    }
  }, [isLoadingEdit]);

  async function sendReport() {
    try {
      setReporting(true);

      setReported(prevState => [...new Set([...prevState, currentComment])]);

      const comment = db.collection('comments').doc(currentComment);
      await comment.update({reported: true});

      setReporting(false);
      setModalReportOn(false);
      
      toast.success("Su reporte ha sido recibido correctamente. Gracias por avisarnos.");
    } catch (err) {
      console.log({err});
      setReporting(false);
      toast.warning("No se ha podido realizar la denuncia en estos momentos, pruebe más tarde.");
    }
  }

  function handleReport(commentId) {
    if (reporting) {
      return;
    }

    setCurrentComment(commentId);
    setModalReportOn(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (textComment === "") {
      toast.warn("Escriba un comentario.");
    } else {
      dispatch(
        fetchCommentEditRequest({
          type: "text",
          chapterId,
          textComment,
        })
      );
      setSubmitted(true);
    }
  };

  const handleSticker = () => {
    setModalStickerOn(true);
  };

  const handleComment = () => {
    if (user) setShowComment(!showComment);
    else toast.warning("Debe iniciar sesión para interactuar");
  };

  return (
    <div className="mt-6">
      <>
        {isLoadingComment ? (
          <div className="flex justify-center items-center">
            <span className="mr-1">Cargando Comentarios</span>
            <SpinningLoading className="h-6" />
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between">
              <h3 className="flex text-l md:text-xl w-full max-w-xs md-w-1/2 py-2 uppercase font-bold">
                <span className="mr-2">Comentarios</span>
                <button className="flex items-center" onClick={handleComment}>
                  {!showComment ? (
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  ) : (
                    <ion-icon name="chevron-up-outline"></ion-icon>
                  )}
                </button>
              </h3>
              <div className="md:w-1/3 text-right">
                Se han publicado <strong>{response?.total}</strong> comentarios
              </div>
            </div>
            {showComment && user && (
              <>
                <div
                  id="comments-list"
                  className="comments-list mt-2 mb-3 overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 max-h-96"
                >
                  {listComment.map((item, index) => 
                    item.blocked ||
                    item.delete ||
                    reported.includes(item?.id) ? null : (
                      <div
                        key={index}
                        className="comment-element p-5 mb-3 last:mb-0 rounded bg-neutral-500 md:mr-2 flex flex-row gap-2"
                      >
                        <div className="flex-1">
                          <div className="comment-title flex items-center mb-2">
                            <PhotoUser
                              photoToken={item?.photoToken}
                              uid={item?.uid}
                              size={48}
                            />
                            <div className="ml-1">
                              <div>
                                <strong className="mr-1">{item.userName}</strong>{" "}
                                dijo:
                              </div>
                              <div>
                                {new Date(
                                  item.audit_created._seconds * 1000
                                ).toLocaleString()}
                              </div>
                            </div>
                          </div>
                          {item.type === "text" && <p>{item.text}</p>}
                          {item.type === "emoji" && (
                            <>
                              <img className="max-h-48" src={item.emojiImg} />
                            </>
                          )}
                        </div>

                        <div className="bg-white text-red-600 flex-row flex self-start p-2 rounded-sm gap-2 cursor-pointer" onClick={() => handleReport(item.id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
                          </svg>

                          <span className="text-[10px] hidden md:block">
                            Reportar
                          </span>                          
                        </div>

                      </div>
                    )
                  )}
                </div>
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <textarea
                        className="w-full bg-neutral-700  p-3"
                        placeholder="Haga un comentario..."
                        onChange={(e) => setTextComment(e.target.value)}
                        value={textComment}
                      ></textarea>
                    </div>
                    <div className="flex">
                      {isLoadingEdit ? (
                        <>
                          <button
                            disabled
                            className="font-medium uppercase rounded-lg border flex py-1 px-3 text-base font-bold w-1/2 md:w-52 hover:bg-white hover:text-black mr-2 justify-center items-center disabled"
                          >
                            <SpinningLoading className="!mr-0 h-6" />
                          </button>
                        </>
                      ) : (
                        <button className="font-medium uppercase rounded-lg border flex py-1 px-3 text-base font-bold w-1/2 md:w-52 hover:bg-white hover:text-black mr-2 justify-center items-center">
                          Enviar comentario
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={handleSticker}
                        className="font-medium uppercase rounded-lg border flex py-1 px-3 text-base font-bold w-1/2 md:w-52 hover:bg-white hover:text-black justify-center items-center"
                      >
                        Enviar stickers
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </>
        )}
      </>
      {modalStickerOn && (
        <ModalSticker
          chapterId={chapterId}
          serieId={serieId}
          setModalStickerOn={setModalStickerOn}
          setUpdateSticker={setUpdateSticker}
          setModalCoinsOn={setModalCoinsOn}
        />
      )}
      {modalReportOn && (
        <ModalReport
          setModalOn={setModalReportOn}
          sendReport={sendReport}
        />
      )}
    </div>
  );
};

export default Comments;
