export const saveTokenMux = (token: string, uid: string, chapterId: string) => {
  try {
    if (!token) return false;
    localStorage.setItem(`tkmux/${uid}/${chapterId}`, token);
    return true;
  } catch (error) {
    console.log(error);    
    return false;
  }
}

export const restoreTokenMux = (uid: string, chapterId: string) => {
  try {
    if (!uid || !chapterId) return undefined; 
    const token = localStorage.getItem(`tkmux/${uid}/${chapterId}`);
    return token;
  } catch (error) {
    console.log(error);     
    return;
  }
}

export const isValidToken = async (token: string, playbackId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_MUX_STREAM_BASE_URL}/${playbackId}.m3u8?token=${token}`, {method: 'GET'});
    return response.status === 200;
  } catch (error) {
    return false;
  }
}

export const generateToken = async (chapterId: string, requestedUid: string, authToken: string) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({chapterId, requestedUid})
    };
    
    const response = await fetch(`https://${process.env.REACT_APP_FIREBASE_FUNCTIONS}/createMuxToken`, options)
    const json = await response.json();    
    
    if (response.status !== 200) {
      throw new Error("No es posible generar un token en estos momentos");
    }
    
    const {token, withAds} = json.data;
    return token;  
  } catch (error) { 
    throw error;      
  }
}