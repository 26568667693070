import React from "react";

const OnlyFansIcon = ({className="", color="white"}) => {
    return(
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 150 150"
            >
            <path 
                fill={color}
                d="M125.82,28.81c-1.47-.68-2.54-2.04-2.85-3.63l-3.64-18.65-12.33,14.46c-1.05,1.23-2.65,1.89-4.27,1.76l-19.37-1.56,10.65,15.75c1.01,1.49,1.15,3.42,.37,5.04l-7.68,15.99,17.76-4.05c.37-.08,.75-.13,1.13-.13,1.25,0,2.47,.46,3.41,1.32l13.48,12.25,1.16-17.71c.12-1.79,1.19-3.4,2.8-4.2l17.01-8.49-17.64-8.15h0Z"
                />
            <path 
                fill={color}
                d="M26.35,49.65l1.16,17.71,13.48-12.25c1.23-1.11,2.92-1.56,4.54-1.19l17.76,4.05-7.68-16c-.78-1.62-.64-3.55,.37-5.04l10.65-15.75-19.37,1.56c-1.62,.13-3.21-.53-4.27-1.77L30.67,6.52l-3.64,18.65c-.31,1.59-1.38,2.95-2.85,3.63L6.54,36.96l17.01,8.49c1.61,.8,2.68,2.42,2.8,4.21h0Z"
                />
            <path 
                fill={color}
                d="M46.89,16.15c8.52-4.09,18.04-6.39,28.11-6.39s19.59,2.31,28.11,6.4l6.51-7.64C99.25,3.09,87.49,0,75,0s-24.25,3.09-34.62,8.51l6.51,7.64Z"
                />
            <path 
                fill={color}
                d="M143.2,43.9l-8.72,4.35c3.69,8.17,5.77,17.21,5.77,26.74,0,35.98-29.27,65.24-65.24,65.24S9.76,110.98,9.76,75c0-9.53,2.08-18.58,5.77-26.74l-8.72-4.35C2.46,53.39,0,63.9,0,75c0,41.36,33.64,75,75,75s75-33.64,75-75c0-11.1-2.46-21.61-6.8-31.1Z"
                />
            <path 
                fill={color}
                d="M113.51,87.53c-4.98,16.45-20.2,28.49-38.29,28.62-18.09,.14-33.49-11.67-38.72-28.04l77.01-.58Z"
                />
        </svg>
    )
}

export default OnlyFansIcon