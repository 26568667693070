import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchContactRequest } from "../_store/contact/actions";
import {
  getLoadingContactSelector,
  getResultContactSelector,
  getErrorContactSelector,
} from "../_store/contact/selectors";
import SpinningLoading from "./Spinning/SpinningLoading";

const ContactForm = ({ submitText }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const loading = useSelector(getLoadingContactSelector);
  const result = useSelector(getResultContactSelector);
  const error = useSelector(getErrorContactSelector);

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    type: "Web Contact",
  });
  const [validName, setValidName] = useState();
  const [validEmail, setValidEmail] = useState();
  const [validPhone, setValidPhone] = useState();
  const [validMessage, setValidMessage] = useState();

  const lettersText = /^[A-Za-z ]+$/;
  const emailText = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneText = /^\d{10}$/;

  useEffect(() => {
    //!loading && submitted && (result?.error === undefined && error === null
    if (!loading && submitted) {
      if (result?.error === undefined && error === null) {
        setValues({
          name: "",
          email: "",
          phone: "",
          message: "",
          type: "Web Contact",
        });
        toast.info("Su mensaje se ha enviado correctamente");
      } else {
        toast.error("Error al enviar! Inténtelo en unos minutos.");
      }
    }
  }, [loading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchContactRequest(values));
    setSubmitted(true);
  };
  const handleNameChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      name: event.target.value,
    }));
    setValidName(event.target.value.match(lettersText) != null);
  };
  const handlePhoneChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      phone: event.target.value,
    }));
    setValidPhone(event.target.value.match(phoneText) != null);
  };
  const handleEmailChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value.replace(/\s/g, "").toLowerCase(),
    }));
    setValidEmail(event.target.value.match(emailText) != null);
  };
  const handleMessageChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      message: event.target.value,
    }));
    setValidMessage(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      {validName === false && values.name !== "" && (
        <div className="relative -mb-4" style={{ color: "red" }}>
          Ingresa un nombre válido
        </div>
      )}
      <div className="mb-3 mt-6 md:mb-4">
        <input
          type="text"
          name="name"
          className="bg-black rounded border lg:border-0 w-full py-2 px-3"
          placeholder="Nombre"
          value={values.name}
          onChange={handleNameChange}
        />
      </div>
      {validEmail === false && values.email !== "" && (
        <div className="relative -mb-4 -mt-2" style={{ color: "red" }}>
          Ingresa un E-mail válido
        </div>
      )}
      <div className="mb-3 mt-6 md:mb-4">
        <input
          type="email"
          name="email"
          className="bg-black rounded border lg:border-0 w-full py-2 px-3"
          placeholder="Correo electrónico"
          onKeyDown={(e) => {
            if (e.code === 'Space') e.preventDefault()
          }}
          value={values.email}
          onChange={handleEmailChange}
        />
      </div>
      {validPhone === false && values.phone !== "" && (
        <div className="relative -mb-4 -mt-2" style={{ color: "red" }}>
          Ingresa un número de 10 dígitos
        </div>
      )}
      <div className="mb-3 mt-6 md:mb-4">
        <input
          type="text"
          name="phone"
          className="bg-black rounded border lg:border-0 w-full py-2 px-3"
          placeholder="Teléfono"
          value={values.phone}
          onChange={handlePhoneChange}
        />
      </div>
      {validMessage === false &&
        (values.message === "" ? (
          <div className="relative -mb-4 -mt-2" style={{ color: "red" }}>
            Ingrese su mensaje
          </div>
        ) : (
          <div style={{ color: "red" }}>Ingrese un mensaje válido</div>
        ))}
      <div className="mb-3 mt-6 md:mb-4">
        <textarea
          className="bg-black rounded border lg:border-0 w-full py-2 px-3"
          rows={3}
          placeholder="Mensaje"
          value={values.message}
          onChange={handleMessageChange}
        ></textarea>
      </div>
      <div className="flex items-center justify-between mb-6">
        {validEmail && validName && validMessage && values.message !== "" ? (
          <button
            className="border uppercase bg-black text-white hover:bg-white hover:text-black py-2 px-4 rounded mx-auto"
            type="submit"
          >
            {loading ? (
              <SpinningLoading className="w-6 h-6 !mr-0" />
            ) : (
              submitText
            )}
          </button>
        ) : (
          <div className="border uppercase bg-black py-2 px-4 mx-auto rounded opacity-50">
            {submitText}
          </div>
        )}
      </div>
      {!loading &&
        submitted &&
        (result?.error === undefined && error === null ? (
          <div>Enviado! Gracias por contactarnos.</div>
        ) : (
          <div>Error al enviar! Inténtelo en unos minutos.</div>
        ))}
    </form>
  );
};

export default ContactForm;
