import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getResultUserLoginSelector } from "../../_store/userLogin/selectors";
import OnlyFansIcon from "../Icons/OnlyFansIcon";
import SpinningLoading from "../Spinning/SpinningLoading";

const baseURL = process.env.REACT_APP_API_URL + "api/coins/use-coins/";
const urlPayKey = process.env.REACT_APP_STRIPE_URL_KEY;

const OnlyFansButton = (props) => {
  const {
    chapterId,
    paymentContents = "superlike",
    serieId,
    setModalCoinsOn,
  } = props;

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [showRec, setShowRec] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const [activeButton, setActiveButton] = useState(0);

  const user = useSelector(getResultUserLoginSelector);

  const OpenModalCoins = () => {
    setModalCoinsOn(true);
  };

  useEffect(() => {
    if (user) {
      if (user.coin < activeButton) {
        setShowRec(true);
      } else {
        setShowRec(false);
      }
    }
  }, [activeButton]);

  function createPayment() {
    if (!user?.login) {
      toast.warning("Debe iniciar sesión para interactuar");
      return false;
    }
    setLoadingPayment(true);
    const requestsOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key-Pay": urlPayKey,
      },
      body: JSON.stringify({
        UserId: user.uid,
        serieId,
        chapterId,
        coins: activeButton,
        paymentContents,
      }),
    };
    fetch(baseURL, requestsOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoadingPayment(false);
        if (data.result === "success") {
          toast.info("La donación se ha completado correctamente.");
          user.coin = user.coin - activeButton;
        } else
          toast.error(
            "Su donación no ha podido ser procesada en estos momentos."
          );
        handleCancel();
      })
      .catch((error) => {
        setLoadingPayment(false);
        handleCancel();
        toast.error("Ha ocurrido un error con el proceso de donación.");
        console.error("There was an error!", error);
      });
  }

  function handleClick() {
    if (!user?.login) {
      toast.warning("Debe iniciar sesión para interactuar.");
      return false;
    }
    setOpenDrop(!openDrop);
  }

  function handleCancel() {
    setOpenDrop(false);
    setActiveButton(0);
  }

  function donate() {
    console.log(user.coin);
    if (user.coin < activeButton) {
      setShowRec(true);
    } else createPayment();
  }

  function DropdownOnlyFans() {
    return (
      <div className="absolute flex justify-center md:justify-start w-full md:top-[-215px] top-[50px] left-0 md:-left-2">
        <div className="dropdown z-10">
          <div
            className="modal-close cursor-pointer z-50"
            onClick={handleCancel}
          >
            <svg
              className="fill-current absolute top-2 right-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
          <p className="mb-3 mt-2">
            Muestra tu apoyo a este capítulo de la serie y ayúdanos a crear
            nuevos contenidos
          </p>
          <div className="buttons-select-donation flex flex-wrap md:flex-nowrap justify-around mb-3">
            <button
              className={`${activeButton === 100 ? "active" : null} mb-2`}
              onClick={() => setActiveButton(100)}
            >
              <img
                className="mr-1 w-5 md:w-7"
                src="/images/icons/coin.svg"
                alt="coins"
              />
              100
            </button>
            <button
              className={`${activeButton === 200 ? "active" : null} mb-2`}
              onClick={() => setActiveButton(200)}
            >
              <img
                className="mr-1 w-5 md:w-7"
                src="/images/icons/coin.svg"
                alt="coins"
              />
              200
            </button>
            <button
              className={`${activeButton === 500 ? "active" : null} mb-2`}
              onClick={() => setActiveButton(500)}
            >
              <img
                className="mr-1 w-5 md:w-7"
                src="/images/icons/coin.svg"
                alt="coins"
              />
              500
            </button>
            <button
              className={`${activeButton === 900 ? "active" : null} mb-2`}
              onClick={() => setActiveButton(900)}
            >
              <img
                className="mr-1 w-5 md:w-7"
                src="/images/icons/coin.svg"
                alt="coins"
              />
              900
            </button>
          </div>
          {showRec && (
            <div className="mb-4">
              <div className="flex w-full justify-between items-center mb-2">
                <div className="flex items-center text-sm">
                  TIENES{" "}
                  <img
                    className="mx-1 w-5"
                    src="/images/icons/coin-bag.svg"
                    alt="coins"
                  />{" "}
                  {user.coin} COINS
                </div>
                <div>
                  <button
                    onClick={OpenModalCoins}
                    className="bg-blue-600 uppercase font-bold text-white py-1 px-4 rounded hover:bg-sky-400 duration-500"
                  >
                    Recargar
                  </button>
                </div>
              </div>
              <div className="flex items-center text-sm italic">
                Necesitas{" "}
                <img
                  className="mx-1 w-5"
                  src="/images/icons/coin.svg"
                  alt="coins"
                />{" "}
                {activeButton} para enviar tu ayuda.
              </div>
            </div>
          )}
          <div className="button-donation">
            <button
              disabled={activeButton === 0 || showRec}
              className="bg-blue-600 hover:bg-sky-400 uppercase font-bold text-white py-2 md:py-2 px-5 rounded duration-500"
              onClick={() =>
                activeButton !== 0
                  ? donate()
                  : toast.warning("Debe selecionar una cantidad.")
              }
            >
              {loadingPayment ? (
                <SpinningLoading className="h-6 !mr-0" />
              ) : (
                "Donar Ahora"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <button
        className="flex flex-col md:flex-row items-center text-xs font-medium uppercase rounded-lg md:border-2 md:border-[#ff0000] md:flex md:py-1 md:px-3 md:text-base md:font-bold md:hover:bg-[#ff0000]"
        onClick={() => handleClick()}
      >
        <OnlyFansIcon className="w-5" />
        <div className="md:ml-2 md:mt-0 mt-2">Soy Fan</div>
      </button>
      {openDrop && DropdownOnlyFans()}
    </>
  );
};

export default OnlyFansButton;
