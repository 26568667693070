import { useEffect } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ModalShare = ({ setModalOn, shareUrl, shareTitle }) => {
  
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const handleOKClick = () => {
    setModalOn(false);
  };

  /* const handleCancelClick = () => {
    setModalOn(false);
  }; */

  /* justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none */
  /* bg-zinc-200 opacity-80 fixed inset-0 z-50 */
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 transition-opacity z-50"
      onClick={handleOKClick}
    >
      <div className="flex h-screen justify-center items-center ">
        <div
          className="relative flex-col justify-center bg-black py-12 md:px-12 px-6 md:mx-0 mx-3 border-4 border-sky-500 rounded-xl w-full max-w-md"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="modal-close cursor-pointer z-50"
            onClick={handleOKClick}
          >
            <svg
              className="fill-current absolute top-2 right-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
          <div className="flex text-xl md:text-2xl font-bold uppercase mb-6">
            Compartir Contenidos
          </div>
          <div className="flex justify-evenly">
            <div className="Demo__some-network">
              <FacebookShareButton
                url={shareUrl}
                quote={shareTitle}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>
            <div className="Demo__some-network">
              <FacebookMessengerShareButton
                url={shareUrl}
                appId="521270401588372"
                className="Demo__some-network__share-button"
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
            </div>
            <div className="Demo__some-network">
              <TwitterShareButton
                url={shareUrl}
                title={shareTitle}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
            <div className="Demo__some-network">
              <TelegramShareButton
                url={shareUrl}
                title={shareTitle}
                className="Demo__some-network__share-button"
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
            <div className="Demo__some-network">
              <WhatsappShareButton
                url={shareUrl}
                title={shareTitle}
                separator=":: "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <div className="Demo__some-network">
              <EmailShareButton
                url={shareUrl}
                subject={shareTitle}
                body="body"
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalShare;
