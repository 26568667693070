import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchCommentEditFailure, fetchCommentEditSuccess } from "./actions";
import { FETCH_COMMENT_EDIT_REQUEST } from "./actionTypes";

import { FetchCommentEditRequest } from "./types";

import { getCommentEdit } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";
import { getResultUserInfoSelector } from "../userInfo/selectors";

import { fetchCommentSaga } from "../comment/sagas";

import { fetchUserLoginSaga } from "../userLogin/sagas";

export function* fetchCommentEditSaga(action: FetchCommentEditRequest): any {
  try {
    let response: any;
    let user: any;

    user = yield select(getResultUserLoginSelector);

    response = yield call(getCommentEdit, user.uid, action.query);

    if (response) {
      yield call(fetchCommentSaga, {
        query: { chapterId: action.query.chapterId },
      });

      if ((action.query.type = "emoji")) {
        yield call(fetchUserLoginSaga, {
          query: { uid: user.uid, email: user.email },
        });
      }

      yield put(
        fetchCommentEditSuccess({
          result: response,
        })
      );
    }
  } catch (error) {
    console.log(error);
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    } else {
      errorMessage = error.toString();
    }
    yield put(fetchCommentEditFailure({ error: errorMessage }));
  }
}

function* CommentEditSaga() {
  yield all([takeLatest(FETCH_COMMENT_EDIT_REQUEST, fetchCommentEditSaga)]);
}

export default CommentEditSaga;
