import { Query } from "./types";
import { auth } from "../firebase";

export async function getCommentEdit(uid: string, query: Query): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      auth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then(function (idToken) {
            const { chapterId, type, textComment, emojiId } = query;

            //console.log("QUERY>>", query);

            let objField: any = {
              uid,
              chapterId,
              type,
            };

            if (textComment) {
              objField = {
                ...objField,
                text: textComment,
              };
            }

            if (emojiId) {
              objField = {
                ...objField,
                emojiId,
              };
            }

            //console.log("", "idToken " + idToken + " uid " + uid);
            //console.log("objField", objField);

            fetch(process.env.REACT_APP_FIREBASE_FUNCTIONS + "/commentInsert", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
              body: JSON.stringify(objField),
            })
              .then((response) => response.json())
              .then((response) => {
                //console.log("RESP:", response);
                if (
                  response.data &&
                  response.data.insert &&
                  response.data.insert == "ok"
                ) {
                  resolve(true);
                } else {
                  reject(
                    response.data.error ?? "Failed to do something exceptional"
                  );
                }
              })
              .catch((error) => reject(error));
          });
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}
