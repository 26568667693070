import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { getResultSerieChapterSelector } from "../_store/serieChapter/selectors";
import { getResultUserLoginSelector } from "../_store/userLogin/selectors";
import { getResultUserInfoSelector } from "../_store/userInfo/selectors";

import SwipperSlides from "../components/SwipperSlides";
import SwipperHome from "../components/SwipperHome";
import PromoSlide from "../components/PromoSlide";

export function HomePage() {
  const [dataPop, setDataPop] = useState();
  const [dataView, setDataView] = useState();
  const [dataPremiere, setDataPremiere] = useState();

  const user = useSelector(getResultUserLoginSelector);
  const userInfo = useSelector(getResultUserInfoSelector);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const serieChapter = useSelector(getResultSerieChapterSelector);

  const findUserMember = (selectedSerie) => {
    const selectedMember = userInfo?.find((e) => e.serie === selectedSerie?.id);
    if (selectedMember) return selectedMember.membership;
    return "none";
  };

  serieChapter.forEach((itemSerie) => {
    itemSerie.memberUserSerie = findUserMember(itemSerie);
  });

  //console.log("SC>>>", serieChapter);

  //Popular
  const fetchDataPop = () => {
    let temp;
    temp = [];

    serieChapter.forEach((itemSerie) => {
      itemSerie.chapter.forEach((itemChapter) => {
        //Populares
        temp.push({
          ...itemChapter,
          logoSerie: itemSerie.img_logo,
          slugSerie: itemSerie.slug,
          titleSerie: itemSerie.title,
          memberUserSerie: itemSerie.memberUserSerie,
        });
      });
    });

    const elemPopulares = {
      sectionTitle: "Populares",
      chapters: temp,
      link: "popular",
    };

    //Ordenar capitulos por mayor cantidad de likes
    elemPopulares.chapters.sort((a, b) => b.total_likes - a.total_likes);
    setDataPop(elemPopulares);
  };

  //Continuar Viendo
  const fetchDataView = () => {
    let temp;
    temp = [];

    userInfo?.forEach((item) => {
      /*COMPROBAR QUE SEA UN CAPITULO VISTO*/
      if (item.tracking_time >= 1) {
        serieChapter?.forEach((itemSerie) => {
          itemSerie.chapter.forEach((itemChapter) => {
            if (itemChapter.id === item.chapter) {
              temp.push({
                ...itemChapter,
                titleSerie: itemSerie.title,
                logoSerie: itemSerie.img_logo,
                slugSerie: itemSerie.slug,
                timeView: item.tracking_time,
                memberUserSerie: itemSerie.memberUserSerie,
              });
            }
          });
        });
      }
    });

    const elemView = {
      sectionTitle: "Continuar Viendo",
      chapters: temp,
      link: "continuar-viendo",
    };
    setDataView(elemView);
  };

  //NUEVOS EPISODIOS
  const fetchDataPremiere = () => {
    let temp;
    temp = [];

    serieChapter.forEach((itemSerie) => {
      const tempChapters = Object.create(itemSerie.chapter);
      //Ordenar capitulos de mayor a menor por numero
      tempChapters.sort((a, b) => b.chapter - a.chapter);

      for (let itemChapter of tempChapters) {
        if (itemChapter.premiere === true) {
          //NUEVOS EPISODIOS
          temp.push({
            ...itemChapter,
            logoSerie: itemSerie.img_logo,
            slugSerie: itemSerie.slug,
            titleSerie: itemSerie.title,
            memberUserSerie: itemSerie.memberUserSerie,
          });
        }
      }
    });

    const elemPremieres = {
      sectionTitle: "Nuevos Episodios",
      chapters: temp,
      link: "",
    };

    setDataPremiere(elemPremieres);
  };

  useEffect(() => {
    fetchDataPop();
    fetchDataPremiere();
  }, []);

  useEffect(() => {
    fetchDataView();
  }, [userInfo]);  

  return (
    <div className="home-page">
      <SwipperHome series={serieChapter} isMobile={isMobile} />
      <PromoSlide />
      <div className="wrap-series md:pb-10 pb-8 md:px-5 px-3">
        {/* POPULARES */}
        {dataPop && (
          <SwipperSlides data={dataPop} link={dataPop.link} type="chapters" />
        )}
        {/* SERIES */}
        {serieChapter.map((item, index) => (
          <SwipperSlides
            key={index}
            data={item}
            link={item.slug}
            type="serie"
          />
        ))}
        {/* CONTINUAR VIENDO */}
        {user && dataView && dataView.chapters.length > 0 && (
          <SwipperSlides
            data={dataView}
            link={dataView.link}
            type="chapters-view"
          />
        )}
        {/* NUEVOS EPISODIOS */}
        {dataPremiere && (
          <SwipperSlides
            data={dataPremiere}
            link={dataPremiere.link}
            type="chapters"
          />
        )}
      </div>
    </div>
  );
}
