import { all, call, put, takeLatest } from "redux-saga/effects";
//import { toast } from "react-toastify";

import { fetchUserLoginFailure, fetchUserLoginSuccess } from "./actions";
import { FETCH_USER_LOGIN_REQUEST } from "./actionTypes";

//import { FetchUserLoginRequest } from "./types";

import { getUserLogin, getUserDetail } from "./api";

import { User } from "../_model/index";

const msjG = "Correo electrónico o contraseña incorrectos";

/*
  Worker Saga: Fired on FETCH_USER_LOGIN_REQUEST action
*/
export function* fetchUserLoginSaga(action: any): any {
  try {
    const response = yield call(getUserLogin, action.query);

    if (
      response &&
      response.user &&
      response.user.uid &&
      response.user.uid !== ""
    ) {
      const responseUser = yield call(getUserDetail, response.user.uid);

      if (
        responseUser &&
        responseUser.email &&
        responseUser.email === response.user.email
      ) {
        let userObj: User = {
          login: true,
          uid: response.user.uid,
          email: responseUser.email,
          name: responseUser.name,
          phone: responseUser.phone,
          photoToken: responseUser.photo_token,
          //deviceToken: responseUser.device_token,
          coin: responseUser.private_coin ?? 0,
        };
        yield put(
          fetchUserLoginSuccess({
            result: userObj,
          })
        );
      } else {
        yield put(fetchUserLoginFailure({ error: msjG }));
      }
    } else {
      yield put(fetchUserLoginFailure({ error: msjG }));
    }
  } catch (e) {
    let errorMessage = "Failed to do something exceptional";
    if (e instanceof Error) {
      errorMessage = e.message;
      if (
        e.message.includes("auth/user-not-found") ||
        e.message.includes("auth/wrong-password")
      ) {
        errorMessage = msjG;
      } else if (e.message.includes("auth/network-request-failed")) {
        errorMessage = "Ha ocurrido un error de conexión";
      }
    }
    //toast.error(errorMessage);
    yield put(fetchUserLoginFailure({ error: errorMessage }));
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_USER_LOGIN_REQUEST` action.
  Allows concurrent increments.
*/
function* UserLoginSaga() {
  yield all([takeLatest(FETCH_USER_LOGIN_REQUEST, fetchUserLoginSaga)]);
}

export default UserLoginSaga;
