import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { membershipAccess } from "../util";

const ThumbVideo = ({
  background,
  logo,
  link,
  userMembership,
  chapterMembership,
  premiere,
  action=()=>{}
}) => {
  const [access, setAccess] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    setAccess(membershipAccess(userMembership, chapterMembership));
  }, [userMembership]);
  
  const handleClick = () => {
    navigate(link);
  };

  return (
    <div className="thumb-video">
      {access && (
        <>
          <Link to={link} onClick={action}>
            <div className="relative cursor-pointer" >
            <img
              className="thumb-background"
              src={background}
              alt="miniatura-video"
            />
            <div className="wrap-label">
              {chapterMembership === "" && (
                <label className="label-video free">Gratis</label>
              )}
              {chapterMembership === "simple" && (
                <label className="label-video member">Miembro</label>
              )}
              {chapterMembership === "vip" && (
                <label className="label-video member">VIP</label>
              )}
              {premiere === true && (
                <label className="label-video premiere">Nuevo</label>
              )}
            </div>
            <div className="slide-logo">
              <img src={logo} alt="logo" />
            </div>
            {access === 1 ? (
              <div className="slide-play md:hidden">
                <ion-icon name="play"></ion-icon>
              </div>
            ) : (
              <div className="slide-block md:hidden">
                {chapterMembership === "simple" && (
                  <img
                    className=""
                    src="/images/icons/icon-reproduccion-miembro.svg"
                    alt="locked"
                  />
                )}
                {chapterMembership === "vip" && (
                  <img
                    className=""
                    src="/images/icons/icon-reproduccion-vip.svg"
                    alt="locked"
                  />
                )}
              </div>
            )}
            </div>
          </Link>
        </>
      )}
    </div>
  );
};

export default ThumbVideo;
