import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getResultCoinValueSelector,
  getLoadingCoinValueSelector,
  getErrorCoinValueSelector,
} from "../../_store/coinValue/selectors";
import { fetchCoinValueRequest } from "../../_store/coinValue/actions";
import { toast } from "react-toastify";
import SpinningLoading from "../Spinning/SpinningLoading";

const ModalCoins = (props) => {
  const { setModalCoinsOn, user } = props;
  const [activeButton, setActiveButton] = useState(0);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingCoinValueSelector);
  const coins = useSelector(getResultCoinValueSelector);
  const error = useSelector(getErrorCoinValueSelector);

  const baseURL = process.env.REACT_APP_API_URL + "api/payments/stripe-coins/";
  const urlPayKey = process.env.REACT_APP_STRIPE_URL_KEY;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  useEffect(() => {
    dispatch(fetchCoinValueRequest());
  }, []);

  const handleOKClick = () => {
    setModalCoinsOn(false);
  };

  function buyCoins() {
    //console.log("PAGAR");
    createPayment();
  }

  function createPayment() {
    if (!user?.login) {
      toast.warning("Debe iniciar sesión para interactuar.");
      return false;
    }
    setLoadingPayment(true);
    const requestsOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key-Pay": urlPayKey,
      },
      body: JSON.stringify({
        UserId: user.uid,
        Coins: activeButton,
        UrlReturn: window.location.protocol + "//" + window.location.host + window.location.pathname,
      }),
    };

    fetch(baseURL, requestsOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not OK");
        }
        return response.json();
      })
      .then((data) => {
        window.location.replace(data.url);
      })
      .catch((error) => {
        setLoadingPayment(false);
        toast.error("Ha ocurrido un error con el proceso de pago.");
        console.error("There was an error!", error);
      });
  }

  return (
    <div
      className="modal-coins fixed inset-0 bg-black bg-opacity-75 transition-opacity z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      onClick={handleOKClick}
    >
      <div className="flex h-screen justify-center items-center ">
        <div
          className="relative flex-col justify-center bg-black md:mx-0 mx-3 border-4 rounded-xl w-full max-w-[460px]"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="header-modal">
            <div
              className="modal-close cursor-pointer z-50"
              onClick={handleOKClick}
            >
              <svg
                className="fill-current absolute top-2 right-2"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>
          <div className="container-modal py-4 md:px-6 px-3 ">
            <div className="flex md:text-xl text-lg font-bold uppercase md:mb-5 mb-4 justify-center border-b-2">
              Recargar Monedas
            </div>
            <div>
              <div className="flex items-center justify-center mb-3 italic">
                <span>Tienes</span>
                <img
                  className="mx-1 w-5"
                  src="/images/icons/coin-bag.svg"
                  alt="coins"
                />
                <span className="font-bold text-base">{user.coin} monedas</span>
              </div>
            </div>
            {isLoading ? (
              <SpinningLoading className="h-6" />
            ) : (
              <>
                <div className="flex flex-wrap justify-evenly coins-select">
                  {coins.map((item, index) => (
                    <div className="sm:basis-1/3 basis-1/2  max-w-[125px] md:px-2 px-1 py-2" key={index}>
                      <button
                        onClick={() => setActiveButton(item.coin)}
                        className={`element py-3 px-4 border-2 rounded w-full ${
                          activeButton === item.coin ? "active" : ""
                        }`}
                      >
                        <div className="flex items-center justify-center mb-2">
                          <img
                            className="w-6 md:mr-2 mr-1"
                            src="/images/icons/coin.svg"
                            alt="coins"
                          />
                          <div className="coin-count font-lg">{item.coin}</div>
                        </div>
                        <div className="coin-valor text-xl text-center font-bold">
                          ${item.value}
                        </div>
                      </button>
                    </div>
                  ))}
                </div>
                <div className="auto mt-2 text-center">
                  <button
                    className="bg-blue-600 uppercase font-bold text-white py-2 px-5 rounded hover:bg-sky-400 duration-500"
                    onClick={() =>
                      activeButton !== 0
                        ? buyCoins()
                        : toast.warning("Debe selecionar una cantidad.")
                    }
                  >
                    {loadingPayment ? (
                      <SpinningLoading className="!mr-0 h-6" />
                    ) : (
                      "Recargar Monedas"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCoins;
