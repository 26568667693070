import { useEffect, useState } from "react";
import { dataWebsite } from "../util";

export function AboutUs() {
    
    const [webData, setWebData] = useState();
    useEffect(() => {
      setWebData(dataWebsite);
    }, []);  

    return (
        <div className="max-w-3xl md:py-10 py-8 md:px-5 px-3 mx-auto">
            <h1 className="text-xl md:text-3xl mb-4 text-center">Sobre Nosotros</h1> 
            <p className="text-center">
                Somos la plataforma de entretenimiento latino más grande de Miami y llegamos para cautivar con talento 
                de primera categoría a la audiencia. Nuestro principal objetivo es crear contenidos de máxima calidad 
                para todos nuestros suscriptores. ¡Acá podrán divertirse con historias realmente interesantes, cargadas 
                de humor, drama y mucho suspenso. ¡Tus actores favoritos están listos para hacerte gritar de emoción! No 
                te pierdas todo lo que tenemos para ti.
            </p>
            <h2 className="text-lg md:text-xl my-4 text-center">Información de Contacto</h2>
            <div className="max-w-lg mx-auto">
                <p><span className="text-sm font-bold">Email: </span><a href={"mailto:"+webData?.email}>{webData?.email}</a></p>
                <p><span className="text-sm font-bold">Teléfono: </span><a href={"tel:"+webData?.phone}>{webData?.phoneFormat}</a></p>
                <p><span className="text-sm font-bold">Dirección: </span>{webData?.address}</p>
            </div>       
        </div>
    );
}
