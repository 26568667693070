import { createSelector } from "reselect";

import { AppState } from "../_root/rootReducer";

const getLoading = (state: AppState) => state.userInfo.loading;

const getResult = (state: AppState) => state.userInfo.result;

const getError = (state: AppState) => state.userInfo.error;

export const getResultUserInfoSelector = createSelector(getResult, (result) => result);

export const getLoadingUserInfoSelector = createSelector(
  getLoading,
  (loading) => loading
);

export const getErrorUserInfoSelector = createSelector(getError, (error) => error);


