import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getResultSerieChapterSelector } from "../_store/serieChapter/selectors";
import NotifyButton from "../components/Buttons/NotifyButton";
import ThumbVideo from "../components/ThumbVideo";

export function PremierePage() {
  const [data, setData] = useState([]);
  const serieChapter = useSelector(getResultSerieChapterSelector);

  //console.log("S>>", serieChapter);

  useEffect(() => {
    const fetchData = () => {
      let elem;
      elem = [];

      serieChapter.forEach((itemSerie) => {
        itemSerie.chapter.forEach((itemChapter) => {
          if (itemChapter.type === "preview") {
            elem.push({
              ...itemChapter,
              serieTitle: itemSerie.title,
              serieLogo: itemSerie.img_logo,
              serieSlug: itemSerie.slug,
            });
          }
        });
      });
      setData(elem);
    };
    fetchData();
  }, [serieChapter]);

  return (
    <div className="default-page container md:py-10 py-5 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl">Próximos Estrenos</h1>
      <div className="container-estrenos">
        {data.map((item, index) => (
          <div
            className="wrap-estrenos flex flex-col md:flex-row pt-4 md:pt-5"
            key={index}
          >
            <div className="wrap-thumb">
              <ThumbVideo
                background={item.img_web}
                logo={item.serieLogo}
                link={`/series/${item.serieSlug}/${item.type}/${item.chapter}`}
                chapterMembership={item.membership}
                userMembership={item.memberUserSerie}
              />
            </div>
            <div className="info-estrenos">
              <div className="first-section flex justify-between items-center mb-3 md:mb-4 mt-3 md:mt-0">
                <div className="date-section">
                  <div>Estreno</div>
                  {/* <div>Miércoles 8 de junio</div> */}
                </div>
                <div className="actions flex">
                  <NotifyButton elem="chapter" itemId={item?.id}></NotifyButton>
                  <Link
                    to={`/series/${item.serieSlug}/${item.type}/${item.chapter}`}
                    className="flex flex-col items-center ml-4"
                  >
                    <ion-icon name="information-circle-outline"></ion-icon>
                    <span>Detalles</span>
                  </Link>
                </div>
              </div>
              <div className="mb-3">
                <h3 className="font-bold">Serie {item.serieTitle}</h3>
                <div className="font-bold">{item.title}</div>
              </div>
              <p>{item.long_description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
