import UnirseButtons from "../Buttons/UnirseButtons";

const ModalMembership = ({setModalOn, serie}) => {
	const handleOKClick = () => {
		setModalOn(false);
	};

	return (
		<div
			className="modal-membership fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-75 outline-none transition-opacity overflow-x-hidden focus:outline-none"
			onClick={handleOKClick}
		>
			<div className="mt-4 mb-4 flex items-center justify-center">
				<div
					className="relative mx-3 w-full max-w-[460px] flex-col justify-center rounded-xl border-4 bg-black md:mx-0"
					onClick={e => e.stopPropagation()}
				>
					<div className="header-modal">
						<div className="modal-close z-50 cursor-pointer" onClick={handleOKClick}>
							<svg
								className="absolute top-2 right-2 fill-current"
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="18"
								viewBox="0 0 18 18"
							>
								<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
							</svg>
						</div>
					</div>
					<div className="container-modal py-4 px-3 md:px-6 ">
						<div className="mb-2 flex justify-center border-b-2 text-lg font-bold uppercase md:text-xl">Membresía {serie.serieTitle}</div>
						<div className="mb-2">
							Para acceder a este contenido debes ser miembro {serie.chapterMembership === "vip" ? "VIP" : ""} de la serie{" "}
							{serie.serieTitle}
						</div>
						<div className="serie-back h-full">
							<img alt="portada" className="h-full max-h-60 w-full object-cover object-top" src={serie.serieThumbs}></img>
						</div>
						<div className="membership-content flex flex-col justify-evenly text-sm">
							{serie.chapterMembership !== "vip" && (
								<>
									<div className="mb-3 mt-3 md:mb-4 md:mt-4">
										<div className="section-title mb-3 flex items-center justify-between">
											<div className="text-lg font-bold uppercase leading-5">
												<div>{serie.serieMemberTitle}</div>
												<div>$4.99 USD</div>
											</div>
											<UnirseButtons text={`Suscribirse`} SerieId={serie.serieId} />
										</div>
										<ul>
											<li>
												<span className="relative top-0.5">
													<ion-icon name="ellipse"></ion-icon>
												</span>{" "}
												Mira todos los videos sin publicidad.
											</li>
											<li>
												<span className="relative top-0.5">
													<ion-icon name="ellipse"></ion-icon>
												</span>{" "}
												Tendrás acceso a contenidos exclusivos de la serie.
											</li>
											<li>
												<span className="relative top-0.5">
													<ion-icon name="ellipse"></ion-icon>
												</span>{" "}
												Tendrás acceso a stickers personalizados de la serie.
											</li>
										</ul>
									</div>
									<hr></hr>
								</>
							)}
							<div className="mt-3 md:mt-4">
								<div className="section-title mb-3 flex items-center justify-between">
									<div className="text-lg font-bold uppercase leading-5">
										<div>{serie.serieMemberTitle} VIP</div>
										<div> {serie.serieMemberUser === "simple" ? <>$5.00</> : <>$9.99</>} USD</div>
									</div>
									{serie.serieMemberUser !== "vip" ? (
										<UnirseButtons text="Suscribirse" MembershipType="vip" SerieId={serie.serieId} />
									) : (
										<UnirseButtons text={`Suscrito`} SerieId={serie.serieId} active={false} />
									)}
								</div>
								<ul>
									<li>
										<span className="relative top-0.5">
											<ion-icon name="ellipse"></ion-icon>
										</span>{" "}
										Mira todos los videos sin publicidad.
									</li>
									<li>
										<span className="relative top-0.5">
											<ion-icon name="ellipse"></ion-icon>
										</span>{" "}
										Tendrás acceso a un grupo privado con el elenco de la serie.
									</li>
									<li>
										<span className="relative top-0.5">
											<ion-icon name="ellipse"></ion-icon>
										</span>{" "}
										Tendrás acceso a contenidos exclusivos de la serie.
									</li>
									<li>
										<span className="relative top-0.5">
											<ion-icon name="ellipse"></ion-icon>
										</span>{" "}
										Tendrás acceso a stickers personalizados de la serie.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalMembership;
