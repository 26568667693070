export default function SectionDownload() {
	return (
		<div className="section-download bg-black">
			<div className="container flex px-3 py-8 text-center md:px-5 md:py-10 md:text-left">
				<div className="section-text">
					<h3 className="mb-2 text-xl font-bold uppercase md:mb-5 md:text-4xl">Descarga la App</h3>
					<p className="md:mb-6">
						Lleva lo MEJOR del entretenimiento en la pantalla de tu teléfono. Descarga ya mismo nuestra App y disfruta de las series más
						divertidas al instante. ¡No esperes más y únete!
					</p>
					<div className="qr-code mb-6 hidden md:flex">
						<img src={"/images/section-descarga/qr-code.svg"} className="w-[220px]" alt="qr-apple" />
						<img src={"/images/section-descarga/qr-code.svg"} className="w-[220px]" alt="qr-android" />
					</div>
					<div className="app-images flex">
						<a href="https://palenque.page.link/mVYD" className="mb-3 md:mb-0 link-apple" title="Descargar desde App Store">
							<img src={"/images/section-descarga/app-store-desktop.png"} alt="img-apple" />
						</a>
						<a href="https://palenque.page.link/mVYD" className="link-google" title="Descargar desde Google Play">
							<img src={"/images/section-descarga/app-google-desktop.png"} alt="img-store" />
						</a>
					</div>
				</div>
				<div className="section-image">
					<img src={"/images/section-descarga/descarga-app-desktop.png"} alt="descarga-app" />
				</div>
			</div>
		</div>
	);
}
