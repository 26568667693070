import { Link } from "react-router-dom";

export default function SectionDonation() {
  return (
    <div className="section-donation">
      <div className="container md:px-5 px-3 md:py-10 py-8 flex md:text-left text-center">
        <p className="text-donation mb-5 md:mb-0">
          ¿Te divertiste y quieres ver más? Ayúdanos a seguir construyendo este
          sueño con una donación. Queremos seguir llevando a tu pantalla lo
          mejor del entretenimiento.
        </p>
        <div className="button-donation">
          <Link
            to="/membresias/"
            className="bg-blue-600 hover:bg-sky-400 uppercase font-bold text-white py-2 md:py-2 px-5 rounded duration-500"
          >
            Membresias
          </Link>
        </div>
      </div>
    </div>
  );
}
