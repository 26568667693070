import { MUX_CREATE_URL_FAILURE, MUX_CREATE_URL_REQUEST, MUX_CREATE_URL_SUCCESS } from "./actionTypes";
import { MuxPlayerActions, MuxPlayerState } from "./types"

const initialState: MuxPlayerState = {
  error: null,
  loading: false,
  url: ''
}

export default (state = initialState, action: MuxPlayerActions) => {
  switch (action.type) {
    case MUX_CREATE_URL_REQUEST:
      return {
        ...state,
        loading: true,
        url: '',
        error: null,
      }
    case MUX_CREATE_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        url: action.url,
      }
    }
    case MUX_CREATE_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    }
    default: 
      return {
        ...state,
      }
  }
}