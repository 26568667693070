const rankingMember = membership => {
	if (membership === "") return 0;
	else if (membership === "simple") return 1;
	else if (membership === "vip") return 2;
};

/*
  1-permiso concedido
  2-necesita actualizar membresia  
  3-usuario sin membresia
  */
export const membershipAccess = (userMembership, contentsMembership) => {
	if (contentsMembership !== "") {
		if (rankingMember(userMembership) >= rankingMember(contentsMembership)) {
			return 1;
		} else if (rankingMember(userMembership) === 1) {
			return 2;
		} else {
			return 3;
		}
	} else {
		return 1;
	}
};

export const isEmail = email => {
	let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

	return Boolean(reg.test(email));
};

export const isPassword = password => {
	return password.length >= 6;
};

export const isPhone = phoneNumber => {
	var regex = /^(?:\D*\d){10}\D*$/;

	if (regex.test(phoneNumber)) {
		return true;
	} else {
		return false;
	}
};

export const dataWebsite = () => {
  return {
    phone: "7867896565",
    phoneFormat: "(786) 789 6565",
    email: "contact@palenquetv.com",
    address: "5760 NW 72ND AVE, Miami Florida"  
  }  
};
