import { createSelector } from "reselect";

import { AppState } from "../_root/rootReducer";

const getLoading = (state: AppState) => state.slide.loading;

const getResult = (state: AppState) => state.slide.result;

const getError = (state: AppState) => state.slide.error;

export const getResultSlideSelector = createSelector(getResult, (result) => result);

export const getLoadingSlideSelector = createSelector(
  getLoading,
  (loading) => loading
);

export const getErrorSlideSelector = createSelector(getError, (error) => error);


