import db from "../_root/apiBridge";

export async function getEmoji(query: any): Promise<any> {
  return new Promise((resolve, reject) => {
    db.collection("emoji")
      .where("serie_id", "==", query.serieId)
      .orderBy("value", "asc")
      .get()
      .then((doc) => {
        if (doc.size == 0) {
          resolve([]);
        }

        let result: any = [];
        doc.forEach((docu) => {
          result.push({ ...docu.data(), id: docu.id });
        });

        if (result.length === doc.size) {
          resolve(result);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
