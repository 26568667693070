import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getResultUserLoginSelector, getSignedInSelector } from '../_store/userLogin/selectors';

const ProtectedRoute = (props) => {
  const user = useSelector(getResultUserLoginSelector)
  const {children} = props

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
