import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchEmojiFailure, fetchEmojiSuccess } from "./actions";
import { FETCH_EMOJI_REQUEST } from "./actionTypes";

import { FetchEmojiRequest } from "./types";

import { getEmoji } from "./api";

import { getResultUserLoginSelector } from "../userLogin/selectors";

import { fetchUserLoginSaga } from "../userLogin/sagas";

export function* fetchEmojiSaga(action: FetchEmojiRequest): any {
  try {
    let response: any;


    response = yield call(getEmoji, action.query);
    console.log(JSON.stringify(response));

    yield put(
      fetchEmojiSuccess({
        result: response,
      })
    );


   
  } catch (error) {
    let errorMessage = "Failed to do something exceptional";
    if (error instanceof Error) {
      errorMessage = error.message;
    } else {
      errorMessage = error.toString();
    }
    yield put(fetchEmojiFailure({ error: error }));
  }
}

function* EmojiSaga() {
  yield all([takeLatest(FETCH_EMOJI_REQUEST, fetchEmojiSaga)]);
}

export default EmojiSaga;
