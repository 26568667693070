import { NavLink, Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import logo from "../images/logo-palenque-tv.png";
import { useSelector } from "react-redux";
import { getResultUserLoginSelector } from "../_store/userLogin/selectors";
import PhotoUser from "./PhotoUser";

export default function Navbar({ setModalCoinsOn }) {
  const user = useSelector(getResultUserLoginSelector);

  // Track events outside scope
  const OpenModalCoins = () => {
    setModalCoinsOn(true);
  };

  function NavItem(props) {
    return (
      <li className="nav-item relative lg:ml-8 my-0">
        <button
          ref={node}
          onClick={() => setOpenDrop(!openDrop)}
          className="icon-button flex text-3xl lg:text-4xl cursor-pointer bg-[#484a4d]"
        >
          <PhotoUser size={48} photoToken={user?.photoToken} uid={user?.uid} />
        </button>
        {openDrop && props.children}
      </li>
    );
  }

  function DropdownMenu() {
    function DropdownItem(props) {
      return (
        <Link onClick={() => CleanMenu()} to={props.link} className="menu-item">
          {props.children}
        </Link>
      );
    }

    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden">
        <div className="dropdown right-12 lg:right-8 2xl:right-18 mt-3" ref={node}>
          {!user ? (
            <>
              <DropdownItem link="/login">Iniciar Sesión</DropdownItem>
              <DropdownItem link="/registrar">Registrarse</DropdownItem>
            </>
          ) : (
            <>
              <div className="menu-item flex items-center justify-between">
                <span>{user.name ? user.name : user.email}</span>
                <Link
                  to="/cuenta/editar-usuario"
                  className="flex text-xl"
                  onClick={() => CleanMenu()}
                >
                  <ion-icon name="create-outline"></ion-icon>
                </Link>
              </div>
              <div className="menu-item flex items-center justify-between">
                <div className="flex items-center w-full justify-between">
                  <div className="flex items-center">
                    <img
                      className="mr-1 w-8"
                      src="/images/icons/coin.svg"
                      alt="coins"
                    />
                    <span className="font-bold text-base">{user.coin}</span>
                  </div>
                  <div>
                    <button
                      onClick={OpenModalCoins}
                      className="bg-blue-600 uppercase font-bold text-white py-1 px-4 rounded hover:bg-sky-400 duration-500"
                    >
                      Recargar
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          <DropdownItem link="/terminos-condiciones">
            Términos y Condiciones
          </DropdownItem>
          <DropdownItem link="/contacto">Contacto</DropdownItem>
          {user && (
            <DropdownItem link="/cerrar-sesion">Cerrar Sesión</DropdownItem>
          )}
        </div>
      </div>
    );
  }

  function CleanMenu() {
    setOpenDrop(false);
    setOpen(false);
  }
  function SetOpenMenu(open) {
    setOpenDrop(false);
    setOpen(open);
  }

  const links = [
    { name: "Estrenos", link: "/estrenos" },
    { name: "Popular", link: "/popular" },
    { name: "Favoritos", link: "/favoritos" },
    { name: "Sponsors", link: "/sponsors" },
    { name: "Continuar Viendo", link: "/continuar-viendo" },
  ];

  const [open, setOpen] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const node = useRef();

  // Track events outside scope
  const clickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpenDrop(false);
  };

  // Do something after component renders
  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);

    // clean up function before running new effect
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [openDrop]);

  return (
    <header className="shadow-md w-full fixed top-0 left-0">
      <div className="container flex items-center bg-black justify-between py-4 lg:px-6 px-3">
        <div className="menu-logo">
          <Link to="/">
            <img src={logo} alt="Palenque TV" height="40px" />
          </Link>
        </div>

        <div
          onClick={() => SetOpenMenu(!open)}
          className="flex text-3xl absolute right-3 cursor-pointer lg:hidden"
        >
          <ion-icon name={open ? "close" : "menu"}></ion-icon>
        </div>
        <div className="flex">
          <ul
            className={`menu-links lg:flex lg:items-center lg:pb-0 pb-12 absolute lg:static lg:z-auto z-[-1] left-0 w-full lg:w-auto transition-all duration-500 ease-in ${
              open ? "top-[72px] " : "top-[-490px]"
            }`}
          >
            {links.map((link) => (
              <li key={link.name} className="lg:ml-8 lg:my-0 my-5">
                <NavLink
                  onClick={() => CleanMenu()}
                  className={({ isActive }) =>
                    isActive ? "active" : "hover:text-[#ff0000] duration-500"
                  }
                  to={link.link}
                >
                  {link.name}
                </NavLink>
              </li>
            ))}
            <li key="donacion" className="lg:ml-8 lg:my-0 my-5">
              <Link
                onClick={() => CleanMenu()}
                className="bg-[#ff0000] text-white py-2 px-6 rounded hover:bg-red-400 duration-500"
                to="/membresias"
              >
                Membresias
              </Link>
            </li>
          </ul>
          <ul className="flex items-center menu-icons pr-9 lg:pr-0">
            <NavItem icon="person-circle-outline">
              {/*DropDowon goes here*/}
              <DropdownMenu />
            </NavItem>
          </ul>
        </div>
      </div>
    </header>
  );
}
