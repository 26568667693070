import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getLoadingUserLoginSelector,
  getResultUserLoginSelector,
  getErrorUserLoginSelector,
} from "../_store/userLogin/selectors";
import { useSelector } from "react-redux";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import SpinningLoading from "../components/Spinning/SpinningLoading";

import { toast } from "react-toastify";
import { isEmail } from "../util";

export const RecoveryPassword = () => {

  let navigate = useNavigate();

  const loading = useSelector(getLoadingUserLoginSelector);
  const user = useSelector(getResultUserLoginSelector);
  const error = useSelector(getErrorUserLoginSelector);

  const isDev = process.env.NODE_ENV === "development";

  const [email, setEmail] = useState(isDev ? "example100@gmail.com" : "");

  const send = (event) => {
    event.preventDefault();

    const isValidEmail = isEmail(email);
     
    if (!isValidEmail) {
      toast.error("La dirección de correo electrónico es incorrecta");
      return;
    } 

    if (email === "") {
      toast.error("Ingrese el email");
    } else {
      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setEmail("");
          toast.info(
            "Se le enviará un email con el siguiente paso a seguir para modificiar su contraseña"
          );
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }

  };

  useEffect(() => {
    if (user?.login) navigate("/");
  }, [user, navigate]);

  return (
    <div className="default-page container md:py-10 py-8 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl text-center">Recuperar contraseña</h1>
      <div className="container-login w-full max-w-md m-auto px-4 sm:px-6">
        <p className="text-center pt-6">
          Por favor, introduzca su dirección de correo electrónico. Recibirá un
          mensaje de correo electrónico con instrucciones sobre cómo restablecer
          su contraseña.
        </p>
        {loading ? (
          <div className="h-15 m-5">
            <SpinningLoading />
          </div>
        ) : (
          <div>
            {error && <div>{error}</div>}

            <form className="pt-6 pb-8" onSubmit={send}>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  className="bg-black rounded w-full py-2 px-3"
                  placeholder="Correo electrónico"
                  onChange={(e) => setEmail(e.target.value.replace(/\s/g, "").toLowerCase())}
                  value={email}
                  onKeyDown={(e) => {
                    if (e.code === 'Space') e.preventDefault()
                  }}
                />
              </div>

              <div className="flex items-center justify-between">
                <button
                  className="border uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
                  type="submit"
                >
                  Recuperar contraseña
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
