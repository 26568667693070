import { Link } from "react-router-dom";

const PlayNowButton = ({ link }) => {
  return (
    <Link to={link}>
      <button className="text-md max-w-md mx-auto font-medium uppercase rounded-lg border-2 flex py-2 mb-3 w-full md:my-auto md:py-1 md:px-3 md:text-base md:font-bold h-min border-red-600 bg-black">
        <ion-icon name="play" style={{margin:"auto",fontSize:"20px"}}></ion-icon>
        <div className="md:ml-2 w-11/12 md:w-auto text-center uppercase">Ver gratis ahora</div>
      </button>
    </Link>
  );
};

export default PlayNowButton;