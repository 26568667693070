import { useState, useEffect } from "react";
import { getResultUserLoginSelector } from "../../_store/userLogin/selectors";
import { getResultUserInfoSelector } from "../../_store/userInfo/selectors";
import { useDispatch, useSelector } from "react-redux";

import {
  getLoadingUserInfoEditSelector,
  getResultUserInfoEditSelector,
  getErrorUserInfoEditSelector,
} from "../../_store/userInfoEdit/selectors";
import { fetchUserInfoEditRequest } from "../../_store/userInfoEdit/actions";

import SpinningLoading from "../Spinning/SpinningLoading";
import { toast } from "react-toastify";

const FavButton = ({ itemId, elem, large = false }) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);

  const [send, setSend] = useState(false);

  const user = useSelector(getResultUserLoginSelector);
  const userInfo = useSelector(getResultUserInfoSelector);
  let itemSerie = false;

  const loading = useSelector(getLoadingUserInfoEditSelector);
  const error = useSelector(getErrorUserInfoEditSelector);
  const result = useSelector(getResultUserInfoEditSelector);

  useEffect(() => {
    function findActive() {
      userInfo.map((item, i) => {
        if (item.serie === itemId) {
          itemSerie = true;
          item.favorite ? setActive(true) : setActive(false);
        } else if (!itemSerie && item.chapter === itemId) {
          item.favorite ? setActive(true) : setActive(false);
        }
      });
    }
    user?.login && findActive();
  }, [userInfo, user]);

  function handleClick() {
    if (!user?.login) {
      toast.warning("Debe iniciar sesión para interactuar");
      return false;
    }

    dispatch(
      fetchUserInfoEditRequest({
        id: itemId,
        type: elem,
        field: {"favorite":!active}
      })
    );

    setSend(true);
  }

  useEffect(() => {
    setSend(false);
  }, [result, error]);

  return (
    <>
      {loading && send ? (
        <button className="btn-default-b-white action-video-button loading-button cursor-auto">
          <SpinningLoading className="h-4"/>
        </button>
      ) : (
        <button
          className="text-xs font-medium uppercase rounded-lg md:border md:flex md:py-1 md:px-3 md:text-base md:font-bold"
          onClick={() => handleClick()}
        >
          {active ? (
            <ion-icon name="heart" style={{margin:"auto",fontSize:"24px"}}></ion-icon>
          ) : (
            <ion-icon name="heart-outline" style={{margin:"auto",fontSize:"24px"}}></ion-icon>
          )}
          <div className="md:ml-2 font-sm-buttons">
            {large && <span className="hidden md:inline">Añadir a </span>}
            Favoritos
          </div>
        </button>
      )}
    </>
  );
};

export default FavButton;
