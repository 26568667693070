import { createSelector } from "reselect";

import { AppState } from "../_root/rootReducer";

const getLoading = (state: AppState) => state.userInfoEdit.loading;

const getResult = (state: AppState) => state.userInfoEdit.result;

const getError = (state: AppState) => state.userInfoEdit.error;



export const getResultUserInfoEditSelector = createSelector(getResult, (result) => result);

export const getLoadingUserInfoEditSelector = createSelector(
  getLoading,
  (loading) => loading
);

export const getErrorUserInfoEditSelector = createSelector(getError, (error) => error);


