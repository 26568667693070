import {
  FETCH_COIN_VALUE_REQUEST,
  FETCH_COIN_VALUE_FAILURE,
  FETCH_COIN_VALUE_SUCCESS,
} from "./actionTypes";
import {
  FetchCoinValueRequest,
  FetchCoinValueSuccess,
  FetchCoinValueSuccessPayload,
  FetchCoinValueFailure,
  FetchCoinValueFailurePayload,
} from "./types";

export const fetchCoinValueRequest = (): FetchCoinValueRequest => ({
  type: FETCH_COIN_VALUE_REQUEST,
});

export const fetchCoinValueSuccess = (
  payload: FetchCoinValueSuccessPayload
): FetchCoinValueSuccess => ({
  type: FETCH_COIN_VALUE_SUCCESS,
  payload,
});

export const fetchCoinValueFailure = (
  payload: FetchCoinValueFailurePayload
): FetchCoinValueFailure => ({
  type: FETCH_COIN_VALUE_FAILURE,
  payload,
});
