import db, {timestamp} from '../_root/apiBridge';
import {FetchSocialNetworkRequestQuery} from './types';

export async function createUser({
  uid,
  email,
  name,
  providerId,
}: FetchSocialNetworkRequestQuery) {
  try {

    await db
      .collection('users')
      .doc(uid)
      .set({
        email: email,
        name: name,
        phone: '',
        code: '',
        providerId,
        audit_created: timestamp.fromDate(new Date()),
      });

  } catch (error) {    
    throw error;
  }
}
