import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { fetchSlideFailure, fetchSlideSuccess } from "./actions";
import { FETCH_SLIDE_REQUEST } from "./actionTypes";


import  { getSlide }  from "./api";


  

/*
  Worker Saga: Fired on FETCH_SLIDE_REQUEST action
*/
export function* fetchSlideSaga() : any { 
  try {

    const response = yield call(getSlide);

    yield put(
      fetchSlideSuccess({
        result: response,
      })
    );
  



  } catch (e) {
    let errorMessage = "Failed to do something exceptional";
    if (e instanceof Error) {
      errorMessage = e.message;
    }
    yield put( fetchSlideFailure({ error: errorMessage }));

  }
}

/*
  Starts worker saga on latest dispatched `FETCH_SLIDE_REQUEST` action.
  Allows concurrent increments.
*/
function* SlideSaga() {
  yield all([takeLatest(FETCH_SLIDE_REQUEST, fetchSlideSaga)]);
}

export default SlideSaga;
