import React, { ChangeEvent, useRef, useState } from "react";

interface FileUploadProps {
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
}
export function FileUpload({ name, accept, onChange }: FileUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setFileName(e.target.files[0].name);
    onChange?.(e);
  };

  return (
    <div className="flex items-center overflow-hidden whitespace-nowrap">
      <input
        type="file"
        name={name}
        className="h-0 w-0 overflow-hidden opacity-0"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={accept}
      />
      <button
        className="rounded border-2 py-1 px-2 text-sm font-bold transition-all duration-300 hover:bg-white hover:text-black"
        onClick={() => {
          if (!fileInputRef.current) return;
          fileInputRef.current.click();
        }}
      >
        Seleccionar Foto
      </button>
      <span className="ml-5 overflow-hidden text-ellipsis whitespace-nowrap">
        {!fileName ? "Archivo no seleccionado" : fileName}
      </span>
    </div>
  );
}
