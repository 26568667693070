import { useEffect } from "react";
import { Link, useNavigate  } from "react-router-dom";
import { useSelector } from "react-redux";

import { getResultUserLoginSelector } from "../../_store/userLogin/selectors";

export function EditPassword() {
  const user = useSelector(getResultUserLoginSelector);
  const navigate = useNavigate();

  return (
    <div className="default-page container md:py-10 py-8 md:px-5 px-3">
      <h1 className="text-xl md:text-3xl text-center">Editar Usuario</h1>
      <div className="container-login w-full max-w-md m-auto px-4 sm:px-6">
        {user && (
          <>
            <form className="pt-6 pb-8">
              <div className="mb-4">
                <input
                  type="password"
                  name="password"
                  className="bg-black rounded w-full py-2 px-3"
                  placeholder="Contraseña Anterior"
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  name="password"
                  className="bg-black rounded w-full py-2 px-3"
                  placeholder="Nueva Contraseña"
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  name="password"
                  className="bg-black rounded w-full py-2 px-3"
                  placeholder="Confirmar Contraseña"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="border uppercase hover:bg-white hover:text-black py-2 px-4 rounded w-full"
                  type="submit"
                >
                  Guardar Cambios
                </button>
              </div>
            </form>
            <p className="my-4 text-sm flex justify-between text-center flex-col md:flex-row">
              <span>¿Desea cambiar sus datos?</span>
              <Link
                className="text-blue-700 hover:text-blue-900"
                to="/cuenta/editar-usuario"
              >
                Cambiar Datos
              </Link>
            </p>
          </>
        )}
      </div>
    </div>
  );
}
