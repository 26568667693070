import { Link } from "react-router-dom";

const ChaptersButtons = ({link}) => {

    return (
        <Link to={`/series/${link}`}>
            <button className="flex">
                <ion-icon name="folder-open-outline" style={{margin:"auto"}}></ion-icon>
                <span className="ml-2 font-medium">
                    Capítulos
                </span>
            </button>
        </Link>
    );
  };
  
export default ChaptersButtons;