import { all, call, put, takeLatest } from "redux-saga/effects";
import { MUX_CREATE_URL_REQUEST } from "./actionTypes";
import { generateUrl } from "./api";
import { MuxCreateUrlRequest } from "./types";
import { createMuxUrlSuccess, createMuxUrlError } from "./actions";

function* createMuxUrl(action: MuxCreateUrlRequest) : any { 
  try {
    const url = yield call(generateUrl, action.item);

    if (url) {
      yield put(
        createMuxUrlSuccess(url)
      )
      return;
    }

    yield put(
      createMuxUrlError('No se puede acceder al video')
    );
  } catch (e: any) { 
    yield put(
      createMuxUrlError('No se puede acceder al video ')
    );
  }
}

function* SerieChapterSaga() {
  yield all([takeLatest(MUX_CREATE_URL_REQUEST, createMuxUrl)]);
}

export default SerieChapterSaga;